import { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useAuth } from '@/providers/Auth'
import { Input, Typography } from '@/ui/atoms'
import { Button } from '@/ui/atoms/shadcn'
import { AuthTemplate } from '@/ui/templates'

const GoogleLoginPage = () => {
  const router = useRouter()
  const { googleLoginAction } = useAuth()
  const [requested, setRequested] = useState(false)

  const getStringQueryParam = useCallback(
    (param: any) => (Array.isArray(param) ? param[0] : param),
    []
  )

  useEffect(() => {
    if (!requested && router.isReady) {
      const code = getStringQueryParam(router.query.code)
      if (code) {
        googleLoginAction(code)
      }
      if (!code) {
        if (router.query.error_description?.includes('AADB2C99002')) {
          router.push('/register?clickGoogleRegister=true')
        }
      }
      setRequested(true)
    }
  }, [router, requested, getStringQueryParam, setRequested, googleLoginAction])

  return (
    <AuthTemplate>
      <div className="flex flex-col items-start justify-start gap-6">
        <div className="flex flex-col items-start justify-start gap-1">
          <Typography
            type="h1"
            variant="title-2xl-regular"
            className="text-gray-700 dark:text-gray-700"
            showSkeleton={true}
          ></Typography>
          <Typography
            variant="text-sm-regular"
            className="text-gray-700 dark:text-gray-700"
            showSkeleton={true}
          ></Typography>
        </div>
        <Button
          variant="neutral"
          className="flex items-center justify-center w-full gap-2 py-2 pl-3 pr-3 border border-gray-300 rounded-md shadow bg-accent-100"
          fullWidth
          showSkeleton={true}
        ></Button>
        <div className="inline-flex items-center self-stretch justify-center gap-2">
          <div className="grow shrink basis-0 h-[1px] bg-gray-300" />
          <Typography
            type="p"
            variant="text-sm-regular"
            className="text-gray-500"
            showSkeleton={true}
          ></Typography>
          <div className="grow shrink basis-0 h-[1px] bg-gray-300" />
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <>
          <div className="flex flex-col gap-6">
            <Input
              name="email"
              id="email"
              autoComplete="email"
              type="email"
              showSkeleton={true}
            />
            <Input
              type="password"
              name="password"
              id="password"
              autoComplete="current-password"
              showSkeleton={true}
            />
            <div className="flex items-center justify-between">
              <div className="w-32 h-5 skeleton"></div>
              <div className="text-sm leading-6">
                <Typography
                  variant="text-sm-semibold"
                  className="cursor-pointer text-secondary-700"
                  showSkeleton={true}
                ></Typography>
              </div>
            </div>
          </div>
          <Button type="submit" fullWidth showSkeleton={true}></Button>
        </>
        <div className="inline-flex items-center justify-center gap-1 sm:gap-2">
          <Typography
            variant="text-sm-regular"
            className="text-gray-700 dark:text-gray-700"
            showSkeleton={true}
          ></Typography>
          <Typography
            variant="text-sm-semibold"
            className="cursor-pointer text-secondary-700"
            showSkeleton={true}
          ></Typography>
        </div>
      </div>
    </AuthTemplate>
  )
}

export default GoogleLoginPage
