import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import {
  ProfileResponse,
  UpdateProfileAvatarRequest,
  UpdateProfileRequest
} from './useGeneralProfileApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'

const useGeneralProfileApi = () => {
  const { handleApi, queryConfig } = useApi()
  const queryClient = useQueryClient()

  const useGetProfile = () => {
    return useQuery({
      queryKey: [QueryKeys.ProfileGeneral],
      queryFn: () =>
        handleApi<void, ProfileResponse>(`/administration/profile`, 'GET'),
      ...queryConfig
    })
  }

  const useUpdateProfile = () =>
    useMutation({
      mutationFn: (data: UpdateProfileRequest) =>
        handleApi<UpdateProfileRequest, void>(
          `/administration/profile`,
          'PATCH',
          data
        ),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ProfileGeneral]
        })
      }
    })

  const useDeleteProfileAvatar = () =>
    useMutation({
      mutationFn: () =>
        handleApi<void, void>(`/administration/profile/avatar`, 'DELETE'),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ProfileGeneralAvatar]
        })
      }
    })

  const useUpdateProfileAvatar = () =>
    useMutation({
      mutationFn: (data: UpdateProfileAvatarRequest) =>
        handleApi<UpdateProfileAvatarRequest, void>(
          `/administration/profile/avatar`,
          'PUT',
          data
        ),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ProfileGeneralAvatar]
        })
      }
    })

  return {
    useGetProfile,
    useUpdateProfile,
    useDeleteProfileAvatar,
    useUpdateProfileAvatar
  }
}

export default useGeneralProfileApi
