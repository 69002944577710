import React from 'react'
import Link from 'next/link'
import useTranslation from '@/hooks/useTranslation'
import { DataTableTemplate } from '@/templates/index'
import { Table } from '@/organisms/index'
import {
  EmptyStateTable,
  FilterDropdown,
  ModalConfirm,
  ModalRenameTemplate
} from '@/molecules/index'
import { Typography } from '@/atoms/index'
import { Template } from '@/hooks/api/ecm/useTemplatesApi/useTemplatesApi.types'
import useTemplatesData from '@/hooks/useTemplatesData/useTemplatesData'
import { FiltersKeyTemplates } from '@/hooks/useTemplatesData/useTemplatesData.types'
import { useAuth } from '@/providers'
import { Button } from '@/ui/atoms/shadcn'
import ModalSearchVault from '@/ui/molecules/ModalSearchVault'

const TemplatesPage: React.FC = () => {
  const { t, isReady } = useTranslation('templates')
  const { userContext } = useAuth()

  const {
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    currentData,
    columns,
    isOpenModal,
    setIsOpenModal,
    dataModalActionTemplate,
    handleDeleteTemplate,
    optionsUser,
    handleSearchVaultCallback,
    filters,
    preparedFilters,
    setPreparedFilters,
    handleApplyFilters,
    loadingSubmit
  } = useTemplatesData({
    type: 'default'
  })
  const { isAdmin } = useAuth()

  const breadcrumbs = [
    { name: t?.breadcrumbs?.manage, href: null, current: false },
    { name: t?.breadcrumbs?.templates, href: '/templates', current: true }
  ]

  return (
    <DataTableTemplate
      header={
        <div className="flex flex-col gap-4 sm:flex-row sm:gap-2 sm:justify-between sm:items-center">
          <div className="flex flex-col flex-1 w-full gap-1">
            <Typography
              variant="title-2xl-medium"
              className="text-gray-700 dark:text-gray-700"
              type="h1"
              showSkeleton={!isReady}
            >
              {t?.title}
            </Typography>
            <Typography
              variant="text-sm-regular"
              className="text-gray-700"
              type="p"
              showSkeleton={!isReady}
            >
              {t?.description}
            </Typography>
          </div>
          <div className="flex flex-row items-end justify-end gap-2">
            <FilterDropdown
              filtersSchema={[
                {
                  id: FiltersKeyTemplates.USER,
                  type: 'select',
                  label: t?.selectLabelUser,
                  initialValue: userContext?.userAccountInformation?.id,
                  options: optionsUser,
                  showSkeleton: !isReady,
                  hidden: !isAdmin,
                  isActive: Boolean(
                    isAdmin &&
                      !!filters?.user &&
                      filters?.user !== userContext?.userAccountInformation?.id
                  )
                },
                {
                  id: FiltersKeyTemplates.QUERY,
                  type: 'inputSearch',
                  label: t?.filterQuery?.label,
                  placeholder: t?.filterQuery?.placeholder,
                  initialValue: '',
                  isActive: Boolean(filters?.query),
                  showSkeleton: !isReady
                },
                {
                  id: FiltersKeyTemplates.FILTER_VAULTS,
                  type: 'filterVaults',
                  label: t?.filterVaultsLabel,
                  isActive: !!filters?.filterVaults?.split(',')?.length
                }
              ]}
              values={{
                user:
                  (preparedFilters?.user as string) ||
                  userContext?.userAccountInformation?.id,
                query: preparedFilters?.query || '',
                filterVaults: preparedFilters?.filterVaults || null
              }}
              setPreparedValues={setPreparedFilters}
              onFilter={(filters) => {
                setPageNumber(0)
                const user =
                  (!!filters?.user &&
                    filters?.user ===
                      userContext?.userAccountInformation?.id) ||
                  !filters?.user
                    ? null
                    : filters?.user
                const query = filters?.query ? filters?.query : null
                const filterVaults = filters?.filterVaults
                  ? filters?.filterVaults
                  : null

                handleApplyFilters({
                  user,
                  query,
                  filterVaults
                })
              }}
              showSkeleton={!isReady}
            />
            <Link href="/template">
              <a className="flex justify-end w-full md:w-auto">
                <Button
                  variant="secondary"
                  type="button"
                  className="w-full h-10 px-6 md:w-auto lg:px-6"
                  showSkeleton={!isReady}
                  timeoutSkeleton={500}
                >
                  {t?.buttonCreate}
                </Button>
              </a>
            </Link>
          </div>
        </div>
      }
      breadcrumbs={breadcrumbs}
      showSkeleton={!isReady}
    >
      {currentData?.isLoading ||
      (currentData?.isSuccess &&
        currentData?.data &&
        currentData?.data?.items?.length > 0) ? (
        <div className="flex items-center justify-center">
          <Table<Template>
            columns={columns}
            data={currentData?.data?.items || []}
            fetchData={setPageNumber}
            canPreviousPage={pageNumber > 0}
            canNextPage={
              (currentData?.data?.pagination &&
                currentData?.data?.pagination?.totalItems /
                  currentData?.data?.pagination?.pageSize >
                  pageNumber + 1) ||
              false
            }
            pagination={currentData?.data?.pagination}
            isLoading={currentData?.isLoading}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageSize={setPageSize}
            setPageNumber={setPageNumber}
          />
        </div>
      ) : (
        <EmptyStateTable type="templates" />
      )}
      {dataModalActionTemplate.action === 'delete' && isOpenModal && (
        <ModalConfirm
          isOpen={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          title={t?.modalConfirmDelete?.title}
          description={t?.modalConfirmDelete?.description}
          confirmButtonText={t?.modalConfirmDelete?.buttonConfirm}
          cancelButtonText={t?.modalConfirmDelete?.buttonCancel}
          onConfirm={handleDeleteTemplate}
          loadingSubmit={loadingSubmit}
        />
      )}
      {dataModalActionTemplate.action === 'move' && isOpenModal && (
        <ModalSearchVault
          isOpen={dataModalActionTemplate.action === 'move' && isOpenModal}
          onClose={() => setIsOpenModal(false)}
          onSearchVaultCallback={(vault) =>
            handleSearchVaultCallback(
              vault,
              dataModalActionTemplate.id as string
            )
          }
          actionType="moveTemplate"
          id={dataModalActionTemplate.id}
        />
      )}
      {dataModalActionTemplate.action === 'rename' &&
        isOpenModal &&
        dataModalActionTemplate.id && (
          <ModalRenameTemplate
            isOpen={dataModalActionTemplate.action === 'rename' && isOpenModal}
            onClose={() => setIsOpenModal(false)}
            id={dataModalActionTemplate.id}
          />
        )}
    </DataTableTemplate>
  )
}

export default TemplatesPage
