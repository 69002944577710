import { useQuery } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import { useAuth } from '@/providers/Auth'
import {
  HistorySignerActionAccounts,
  HistorySignerActionsOwners,
  HistorySignerActionUserAccounts,
  SignerActionAccountItem
} from '@/hooks/api/ecm/useHistoryApi/useHistoryApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'

const useHistoryApi = () => {
  const { handleApi, queryConfig } = useApi()
  const { authMetadata } = useAuth()

  const useHistorySignerActionsOwners = (enabled = true) => {
    return useQuery({
      queryKey: [QueryKeys.HistorySignerActionsOwners],
      queryFn: () =>
        handleApi<void, HistorySignerActionsOwners>(
          '/ecm/history/signer-actions/owners',
          'GET'
        ),
      ...queryConfig,
      enabled: enabled && !!authMetadata?.accessToken
    })
  }

  const useHistorySignerActionAccounts = (
    enabled = true,
    pageNumber = 0,
    pageSize = 10
  ) => {
    return useQuery({
      queryKey: [QueryKeys.HistorySignerActionAccounts, pageNumber, pageSize],
      queryFn: () =>
        handleApi<void, HistorySignerActionAccounts>(
          `/ecm/history/signer-actions/accounts?pageNumber=${
            pageNumber + 1
          }&pageSize=${pageSize}`,
          'GET'
        ),
      ...queryConfig,
      enabled: enabled && !!authMetadata?.accessToken
    })
  }

  const useHistorySignerActionUserAccounts = (
    id: string | null,
    enabled = true,
    pageNumber = 0,
    pageSize = 10
  ) => {
    const url = id
      ? `/ecm/history/signer-actions/user-accounts/${id}`
      : `/ecm/history/signer-actions/user-accounts`

    return useQuery({
      queryKey: [
        QueryKeys.HistorySignerActionAccounts,
        id,
        pageNumber,
        pageSize
      ],
      queryFn: () =>
        handleApi<void, HistorySignerActionUserAccounts>(
          url + `?pageNumber=${pageNumber + 1}&pageSize=${pageSize}`,
          'GET'
        ),
      ...queryConfig,
      enabled: enabled && !!authMetadata?.accessToken
    })
  }

  const useHistorySignerActionDetails = (id: string | null) =>
    useQuery({
      queryKey: [QueryKeys.HistorySignerActionDetails, id],
      queryFn: () =>
        handleApi<void, SignerActionAccountItem>(
          `/ecm/history/signer-actions/${id}`,
          'GET'
        ),
      ...queryConfig,
      enabled: !!authMetadata?.accessToken && !!id
    })

  return {
    useHistorySignerActionsOwners,
    useHistorySignerActionAccounts,
    useHistorySignerActionUserAccounts,
    useHistorySignerActionDetails
  }
}

export default useHistoryApi
