import { useEffect, useMemo } from 'react'
import { formatData, formatTimeDistance } from '@/utils/date'
import { useLocale } from '@/providers/Locale'
import { CustomColumn } from '@/types/react-table-config'
import { AvatarGroup } from '@/molecules/index'
import { Avatar, Typography } from '@/atoms/index'
import { IUseInboxDataResponse } from './useInboxData.types'
import { useTable } from '../useTable'
import useTranslation from '../useTranslation'
import { useViewport } from '../useViewport'
import { IBreakpoint } from '../useViewport/useViewport'
import useInboxApi from '@/hooks/api/ecm/useInboxApi/useInboxApi'
import { IInboxItem } from '@/hooks/api/ecm/useInboxApi/useInboxApi.types'
import { Button } from '@/ui/atoms/shadcn'

const useInboxData = (): IUseInboxDataResponse => {
  const { t } = useTranslation('inboxPage')
  const { t: tDate } = useTranslation('date')
  const { pageNumber, setPageNumber, pageSize, setPageSize } = useTable()
  const { lang } = useLocale()
  const { breakpoint } = useViewport()
  const { useInboxData, useInboxSignatureLink } = useInboxApi()
  const {
    data: dataInbox,
    isSuccess: isSuccessInbox,
    isLoading: isLoadingInbox
  } = useInboxData()
  const { data, isSuccess, setSelectedId, setIsEnabled } =
    useInboxSignatureLink('', false)

  useEffect(() => {
    if (isSuccess) {
      window.open(
        `${process.env.NEXT_PUBLIC_APP_URL}/review/${data?.id}`,
        '_blank'
      )
    }
  }, [isSuccess, data])

  const generateDynamicColumnWidths = (breakpoint: IBreakpoint) => {
    const baseConfig = {
      count: { width: '25%', minWidth: '150px' },
      sender: { width: '20%', minWidth: '120px' },
      envelope: { width: '20%', minWidth: '120px' },
      availableSince: { width: '20%', minWidth: '120px' },
      signers: { width: '10%', minWidth: '60px' },
      action: { width: '5%', minWidth: '20px' }
    }

    const scaleFactor = {
      '3xl': 2,
      '2xl': 1.2,
      xl: 1.2,
      lg: 1.4,
      md: 1.2,
      sm: 1.4,
      xs: 1.4
    }

    const scale = scaleFactor[breakpoint] || 1

    const scaledConfig = Object.fromEntries(
      Object.entries(baseConfig).map(([key, value]) => [
        key,
        {
          width: value.width,
          minWidth: `${parseInt(value.minWidth, 10) * scale}px`
        }
      ])
    )

    return scaledConfig
  }

  const tableColumnWidth = useMemo(() => {
    return generateDynamicColumnWidths(breakpoint)
  }, [breakpoint])

  const columns: CustomColumn<IInboxItem>[] = useMemo(() => {
    return [
      {
        Header: t?.tableInformation?.count || '',
        accessor: 'accountId',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          return (
            <div className="flex items-center gap-2">
              <div>
                <Avatar name={valuesOriginal.accountName} />
              </div>
              <div className="flex-1">
                <Typography
                  variant="text-sm-medium"
                  className="whitespace-pre-wrap "
                >
                  {valuesOriginal.accountName}
                </Typography>
                <Typography
                  variant="text-xs-regular"
                  className="whitespace-pre-wrap"
                >
                  {valuesOriginal.accountEmail}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidth.count
      },
      {
        Header: t?.tableInformation?.sender || '',
        accessor: 'userAccountOwnerId',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original
          return (
            <div className="flex items-center gap-2">
              <div>
                <Avatar name={valuesOriginal.userAccountOwnerName} />
              </div>
              <div className="flex-1">
                <Typography
                  variant="text-sm-medium"
                  className="whitespace-pre-wrap "
                >
                  {valuesOriginal.userAccountOwnerName}
                </Typography>
                <Typography
                  variant="text-xs-regular"
                  className="whitespace-pre-wrap"
                >
                  {valuesOriginal.userAccountOwnerEmail}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidth.sender
      },
      {
        Header: t?.tableInformation?.envelope || '',
        accessor: 'name',
        Cell: ({ getValue }) => (
          <div className="w-full whitespace-pre-wrap">
            <Typography variant="text-sm-medium">{getValue('name')}</Typography>
          </div>
        ),
        ...tableColumnWidth.envelope
      },
      {
        Header: t?.tableInformation?.availableSince || '',
        accessor: 'publishedToBeReviewedAtUtc',
        Cell: ({ getValue }) => {
          const value = getValue('publishedToBeReviewedAtUtc')
          return (
            <div className="flex flex-col flex-1">
              <Typography variant="text-sm-medium">
                {formatData(value, lang)}
              </Typography>
              <Typography variant="text-xs-regular" className="text-gray-600">
                {formatTimeDistance(value, tDate as any)}
              </Typography>
            </div>
          )
        },
        ...tableColumnWidth.availableSince
      },
      {
        Header: t?.tableInformation?.signers || '',
        accessor: 'envelopeOwnerName',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original
          const signers = valuesOriginal?.signers?.map((signer: any) => ({
            name: signer.name,
            email: signer.email
          }))

          return (
            <div className="flex items-center gap-1 lg:gap-2">
              <AvatarGroup users={signers} />
            </div>
          )
        },
        width: '15%',
        minWidth: '115px'
      },
      {
        Header: t?.tableInformation?.action || '',
        accessor: 'id',
        Cell: ({ getValue }) => (
          <div>
            <Button
              variant="outlineSecondary"
              onClick={() => console.log('id', getValue('id'))}
            >
              {t?.tableInformation?.open}
            </Button>
          </div>
        ),
        noInternalPadding: true,
        ...tableColumnWidth.action
      }
    ]
  }, [
    lang,
    t?.tableInformation?.action,
    t?.tableInformation?.availableSince,
    t?.tableInformation?.count,
    t?.tableInformation?.envelope,
    t?.tableInformation?.open,
    t?.tableInformation?.sender,
    t?.tableInformation?.signers,
    tDate,
    tableColumnWidth.action,
    tableColumnWidth.availableSince,
    tableColumnWidth.count,
    tableColumnWidth.envelope,
    tableColumnWidth.sender
  ])

  const currentDataTable = useMemo(() => {
    return {
      data: dataInbox,
      isLoading: isLoadingInbox,
      isSuccess: isSuccessInbox
    }
  }, [dataInbox, isLoadingInbox, isSuccessInbox])

  return {
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    currentDataTable,
    columns
  }
}

export default useInboxData
