import { useState, useEffect, useMemo } from 'react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { formatData, formatTimeDistance } from '@/utils/date'
import { useAuth } from '@/providers/Auth'
import { useLocale } from '@/providers/Locale'
import { CustomColumn } from '@/types/react-table-config'
import { Avatar, Typography } from '@/atoms/index'
import {
  UseHistoryDataResponse,
  MappingStatusType,
  StatusType,
  DataModalType,
  FiltersKeyHistory,
  FiltersHistoryType
} from './useHistoryData.types'
import { useCreateOptionsUser } from '../useCreateOptionsUser'
import { useTable } from '../useTable'
import useTableFilters from '../useTableFilters'
import useTranslation from '../useTranslation'
import { useViewport } from '../useViewport'
import { IBreakpoint } from '../useViewport/useViewport'
import useHistoryApi from '@/hooks/api/ecm/useHistoryApi/useHistoryApi'
import { SignerActionAccountItem } from '@/hooks/api/ecm/useHistoryApi/useHistoryApi.types'
import { Badge, Button } from '@/ui/atoms/shadcn'

const useHistoryData = (): UseHistoryDataResponse => {
  const { t } = useTranslation('history')
  const { t: tDate } = useTranslation('date')
  const [dataModal, setDataModal] = useState<DataModalType>({
    isOpen: false,
    id: null
  })
  const { isAdmin, userContext } = useAuth()
  const { pageNumber, setPageNumber, pageSize, setPageSize } = useTable()
  const { lang } = useLocale()
  const { breakpoint } = useViewport()
  const {
    filters,
    preparedFilters,
    setPreparedFilters,
    handleApplyFilters,
    isReady
  } = useTableFilters<FiltersHistoryType>({})

  const fetchAll = useMemo(
    () => isAdmin && filters?.user === 'all',
    [isAdmin, filters?.user]
  )
  const fetchMe = useMemo(
    () =>
      filters?.user === userContext?.userAccountInformation?.id ||
      !filters?.user,
    [filters?.user, userContext]
  )

  const {
    useHistorySignerActionsOwners,
    useHistorySignerActionAccounts,
    useHistorySignerActionUserAccounts,
    useHistorySignerActionDetails
  } = useHistoryApi()

  const { data: dataSignerActionsOwners } =
    useHistorySignerActionsOwners(!!isAdmin)
  const {
    data: dataSignerActionAccounts,
    isSuccess: isSuccessSignerActionAccounts,
    isLoading: isLoadingSignerActionAccounts,
    isFetching: isFetchingSignerActionAccounts
  } = useHistorySignerActionAccounts(
    (fetchAll && isReady) ?? false,
    pageNumber,
    pageSize
  )
  const {
    data: dataHistorySignerActionUserAccounts,
    isLoading: isLoadingHistorySignerActionUserAccounts,
    isSuccess: isSuccessHistorySignerActionUserAccounts,
    isFetching: isFetchingHistorySignerActionUserAccounts
  } = useHistorySignerActionUserAccounts(
    fetchMe ? null : (filters?.user as string),
    !fetchAll && isReady,
    pageNumber,
    pageSize
  )
  const {
    data: dataSignerActionDetails,
    isLoading: isLoadingSignerActionDetails
  } = useHistorySignerActionDetails(
    dataModal.isOpen && dataModal.id ? dataModal.id : null
  )

  const currentDataTable = useMemo(() => {
    if (filters?.user === 'all') {
      return {
        data: dataSignerActionAccounts,
        isLoading:
          isLoadingSignerActionAccounts || isFetchingSignerActionAccounts,
        isSuccess: isSuccessSignerActionAccounts
      }
    }
    return {
      data: dataHistorySignerActionUserAccounts,
      isLoading:
        isLoadingHistorySignerActionUserAccounts ||
        isFetchingHistorySignerActionUserAccounts,
      isSuccess: isSuccessHistorySignerActionUserAccounts
    }
  }, [
    dataHistorySignerActionUserAccounts,
    dataSignerActionAccounts,
    isFetchingHistorySignerActionUserAccounts,
    isFetchingSignerActionAccounts,
    isLoadingHistorySignerActionUserAccounts,
    isLoadingSignerActionAccounts,
    isSuccessHistorySignerActionUserAccounts,
    isSuccessSignerActionAccounts,
    filters?.user
  ])

  const optionsUsers = useCreateOptionsUser({
    users: dataSignerActionsOwners?.items as any[]
  })

  const mappingStatus: Record<StatusType, MappingStatusType> = t?.mappingStatus

  const generateDynamicColumnWidths = (breakpoint: IBreakpoint) => {
    const baseConfig = {
      dateAndHour: { width: '15%', minWidth: '120px' },
      signer: { width: '20%', minWidth: '80px' },
      actionType: { width: '15%', minWidth: '100px' },
      envelope: { width: '22.5%', minWidth: '150px' },
      owner: { width: '22.5%', minWidth: '130px' },
      action: { width: '5%', minWidth: '50px' }
    }

    const scaleFactor = {
      '3xl': 1,
      '2xl': 1,
      xl: 1.2,
      lg: 1.2,
      md: 1.3,
      sm: 1.3,
      xs: 1.3
    }

    const scale = scaleFactor[breakpoint] || 1

    const scaledConfig = Object.fromEntries(
      Object.entries(baseConfig).map(([key, value]) => [
        key,
        {
          width: value.width,
          minWidth: `${parseInt(value.minWidth, 10) * scale}px`
        }
      ])
    )

    return scaledConfig
  }

  const tableColumnWidth = useMemo(() => {
    return generateDynamicColumnWidths(breakpoint)
  }, [breakpoint])

  const columns: CustomColumn<SignerActionAccountItem>[] = useMemo(() => {
    return [
      {
        Header: t?.tableInformation?.dateAndHour || '',
        accessor: 'createdAtUtc',
        Cell: ({ getValue }) => {
          const value = getValue('createdAtUtc') as string
          return (
            <div className="flex flex-col">
              <Typography variant="text-sm-medium">
                {formatData(value, lang)}
              </Typography>
              <Typography variant="text-xs-regular" className="text-gray-600">
                {formatTimeDistance(value, tDate as any)}
              </Typography>
            </div>
          )
        },
        ...tableColumnWidth.dateAndHour
      },
      {
        Header: t?.tableInformation?.signer || '',
        accessor: 'signerName',
        Cell: ({ row }) => (
          <div className="flex items-center gap-2">
            <div>
              <Avatar name={row.original.signerName} />
            </div>
            <div className="flex-1">
              <Typography
                variant="text-sm-medium"
                className="whitespace-pre-wrap "
              >
                {row.original.signerName}
              </Typography>
              <Typography
                variant="text-xs-regular"
                className="whitespace-pre-wrap"
              >
                {row.original.signerEmail}
              </Typography>
            </div>
          </div>
        ),
        ...tableColumnWidth.signer
      },
      {
        Header: t?.tableInformation?.actionType || '',
        accessor: 'type',
        Cell: ({ getValue }) => {
          const value = getValue('type') as StatusType

          const item = mappingStatus?.[value] || {
            label: '',
            color: ''
          }

          return (
            <Badge
              size="sm"
              icon={InformationCircleIcon}
              className={item?.color}
              tooltip={item?.label}
            >
              {item?.label}
            </Badge>
          )
        },
        ...tableColumnWidth.actionType
      },
      {
        Header: t?.tableInformation?.envelope || '',
        accessor: 'envelopeName',
        Cell: ({ getValue }) => (
          <div className="w-full whitespace-pre-wrap">
            <Typography variant="text-sm-medium">
              {getValue('envelopeName')}
            </Typography>
          </div>
        ),
        ...tableColumnWidth.envelope
      },
      {
        Header: t?.tableInformation?.owner || '',
        accessor: 'envelopeOwnerName',
        Cell: ({ row }) => (
          <div className="flex items-center gap-1 lg:gap-2">
            <div>
              <Avatar
                name={row?.original?.envelopeOwnerName}
                imgSrc={row?.original?.envelopeOwnerAvatar}
              />
            </div>
            <div className="flex-1">
              <Typography variant="text-sm-medium">
                {row?.original?.envelopeOwnerName}
              </Typography>
            </div>
          </div>
        ),
        ...tableColumnWidth.owner
      },
      {
        Header: t?.tableInformation?.action || '',
        accessor: 'id',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          return (
            <div>
              <Button
                variant="outlineSecondary"
                onClick={() => {
                  setDataModal({
                    isOpen: true,
                    id: valuesOriginal?.id
                  })
                }}
              >
                {t?.tableInformation?.view}
              </Button>
            </div>
          )
        },
        noInternalPadding: true,
        ...tableColumnWidth.action
      }
    ]
  }, [
    lang,
    mappingStatus,
    t?.tableInformation?.action,
    t?.tableInformation?.actionType,
    t?.tableInformation?.dateAndHour,
    t?.tableInformation?.envelope,
    t?.tableInformation?.owner,
    t?.tableInformation?.signer,
    t?.tableInformation?.view,
    tDate,
    tableColumnWidth.action,
    tableColumnWidth.actionType,
    tableColumnWidth.dateAndHour,
    tableColumnWidth.envelope,
    tableColumnWidth.owner,
    tableColumnWidth.signer
  ])

  const cardsDetails = useMemo(() => {
    if (!dataSignerActionDetails) return null

    return [
      {
        title: t?.tableInformation?.signer,
        avatar: {
          imgSrc: null,
          name: dataSignerActionDetails?.signerName
        },
        name: dataSignerActionDetails?.signerName,
        email: dataSignerActionDetails?.signerEmail
      },
      {
        title: t?.tableInformation?.owner,
        avatar: {
          imgSrc: dataSignerActionDetails?.envelopeOwnerAvatar,
          name: dataSignerActionDetails?.envelopeOwnerName
        },
        name: dataSignerActionDetails?.envelopeOwnerName
      }
    ]
  }, [
    dataSignerActionDetails,
    t?.tableInformation?.owner,
    t?.tableInformation?.signer
  ])

  const listDetails = useMemo(() => {
    if (!dataSignerActionDetails) return null

    return [
      {
        title: t?.tableInformation?.envelope,
        value: dataSignerActionDetails?.envelopeName
      },
      {
        title: t?.tableInformation?.date,
        value: formatData(dataSignerActionDetails?.createdAtUtc, lang)
      },
      {
        title: t?.tableInformation?.actionType,
        value: t?.mappingStatus?.[dataSignerActionDetails?.type]?.label
      }
    ]
  }, [
    dataSignerActionDetails,
    lang,
    t?.mappingStatus,
    t?.tableInformation?.actionType,
    t?.tableInformation?.date,
    t?.tableInformation?.envelope
  ])

  return {
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    currentDataTable,
    columns,
    optionsUsers,
    dataModal,
    setDataModal,
    isLoadingSignerActionDetails,
    cardsDetails,
    listDetails,
    filters,
    preparedFilters,
    setPreparedFilters,
    handleApplyFilters
  }
}

export default useHistoryData
