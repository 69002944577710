import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { UserAccountRole } from '@/types/global'
import { useAuth } from '@/providers'

const withRole = (
  WrappedComponent: React.ComponentType,
  requiredRole: UserAccountRole
) => {
  const WithRoleComponent = (props: any) => {
    const { loading: isAuthLoading, isAdmin } = useAuth()
    const router = useRouter()

    useEffect(() => {
      if (!isAuthLoading) {
        if (requiredRole === 'Administrator' && !isAdmin) {
          router.push('/unauthorized')
        }
      }
    }, [isAuthLoading, isAdmin, router])

    if (isAuthLoading) {
      return null
    }

    if (requiredRole === 'Administrator' && isAdmin) {
      return <WrappedComponent {...props} />
    }

    return null
  }

  WithRoleComponent.displayName = `WithRole(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`

  return WithRoleComponent
}

export default withRole
