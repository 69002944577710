import React from 'react'
import { usePublicEnvelopeViewData } from '@/hooks/usePublicEnvelopeViewData'
import useTranslation from '@/hooks/useTranslation'
import { useViewport } from '@/hooks/useViewport'
import { PublicEnvelopeTemplate } from '@/templates/index'
import { Select } from '@/ui/atoms'
import { Button } from '@/ui/atoms/shadcn'
import {
  DrawerViewDocument,
  CardEnvelope,
  SectionViewDocument,
  Timeline,
  ModalProcessingActionsEnvelope
} from '@/ui/molecules'

const PublicViewEnvelopePage: React.FC = () => {
  const { t } = useTranslation(['envelopes', 'viewEnvelope'], true)
  const { breakpoint } = useViewport()
  const {
    sectionEnvelopeInfo,
    sectionEnvelopeTimeline,
    handleSelectDocument,
    valuesSelectDocuments,
    valueSelectedDocument,
    sectionEnvelopeSigners,
    setIsDrawerOpen,
    isDrawerOpen,
    sectionEnvelopeViewDocuments,
    isLoadingSkeleton,
    openModalProcessingActions,
    setOpenModalProcessingActions,
    setLoadingPdf,
    loadingPdf,
    currentDocumentFile,
    isSandboxEnvelope,
    handleDownloadDocument,
    isLoadingDownloadDocument
  } = usePublicEnvelopeViewData()

  return (
    <PublicEnvelopeTemplate mode="view" isSandboxEnvelope={isSandboxEnvelope}>
      {openModalProcessingActions && (
        <div className="fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full min-h-screen backdrop-blur-sm"></div>
      )}
      <div className="relative flex justify-center w-full h-full gap-5 px-4 py-8 mx-auto md:max-w-screen-xl">
        <div className="relative flex-1 hidden basis-2/4 xl:basis-3/5 md:flex">
          <SectionViewDocument
            loadingPdf={loadingPdf}
            setLoadingPdf={setLoadingPdf}
            currentDocumentFile={currentDocumentFile}
          />
        </div>
        <div className="flex flex-col flex-1 gap-5 basis-2/4 xl:basis-2/5">
          <CardEnvelope
            className="flex flex-col items-end w-full gap-2 p-4 mx-auto border-t-0 sm:flex-row"
            showSkeleton={isLoadingSkeleton}
            classNameSkeleton="h-20"
          >
            <Select
              id="select-document"
              label={
                breakpoint === 'xs' || breakpoint === 'sm'
                  ? t?.sectionSelectDocument?.selectLabelMobile
                  : t?.sectionSelectDocument?.selectLabel
              }
              options={valuesSelectDocuments || []}
              value={valueSelectedDocument}
              onChange={handleSelectDocument}
              className="w-full max-w-none md:w-full"
            />
            <Button
              onClick={() => setIsDrawerOpen(true)}
              className="w-full sm:w-fit md:hidden"
            >
              {t?.sectionSelectDocument?.buttonViewDocument}
            </Button>
          </CardEnvelope>
          <CardEnvelope
            title={sectionEnvelopeInfo?.title}
            items={sectionEnvelopeInfo?.items}
            showSkeleton={isLoadingSkeleton}
            classNameSkeleton="h-80"
          />
          <CardEnvelope
            title={sectionEnvelopeViewDocuments?.title}
            items={sectionEnvelopeViewDocuments?.items}
            emptyState={sectionEnvelopeViewDocuments?.emptyState}
            emptyStateTitle={sectionEnvelopeViewDocuments?.emptyStateTitle}
            emptyStateMessage={sectionEnvelopeViewDocuments?.emptyStateMessage}
            showSkeleton={isLoadingSkeleton}
            classNameSkeleton="h-40"
          />
          <CardEnvelope
            title={sectionEnvelopeSigners?.title}
            items={sectionEnvelopeSigners?.items}
            emptyState={sectionEnvelopeSigners?.emptyState}
            emptyStateTitle={sectionEnvelopeSigners?.emptyStateTitle}
            emptyStateMessage={sectionEnvelopeSigners?.emptyStateMessage}
            showSkeleton={isLoadingSkeleton}
            classNameRow="flex-col w-full flex-1 items-start justify-start sm:flex-row sm:items-center sm:justify-between"
            classNameSkeleton="h-40"
          />
          <CardEnvelope
            title={sectionEnvelopeTimeline?.title}
            emptyState={sectionEnvelopeTimeline?.emptyState}
            emptyStateTitle={sectionEnvelopeTimeline?.emptyStateTitle}
            emptyStateMessage={sectionEnvelopeTimeline?.emptyStateMessage}
            showSkeleton={isLoadingSkeleton}
            classNameSkeleton="h-80"
          >
            <Timeline items={sectionEnvelopeTimeline?.items} />
          </CardEnvelope>
        </div>
      </div>
      <DrawerViewDocument
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        loadingPdf={loadingPdf}
        setLoadingPdf={setLoadingPdf}
        currentDocumentFile={currentDocumentFile}
        handleDownloadDocument={handleDownloadDocument}
        isLoadingDownloadDocument={isLoadingDownloadDocument}
      />
      <ModalProcessingActionsEnvelope
        isOpen={openModalProcessingActions}
        onClose={() => setOpenModalProcessingActions(false)}
      />
    </PublicEnvelopeTemplate>
  )
}

export default PublicViewEnvelopePage
