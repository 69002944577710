import { useState, useMemo, useEffect, useCallback } from 'react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { formatData } from '@/utils/date'
import { useAuth } from '@/providers/Auth'
import { useLocale } from '@/providers/Locale'
import { CustomColumn } from '@/types/react-table-config'
import { Avatar, Dropdown, SelectOption, Typography } from '@/atoms/index'
import {
  UseUsersResponse,
  ModalUpdateRoleData,
  CurrentDataTableType,
  FiltersUsersType,
  FiltersKeyUsers,
  ModalInviteUserType,
  ModalNoLicensedUsersType,
  ModalConfirmActionType
} from './useUsersData.types'
import useBillingApi from '../api/billing/useBillingApi/useBillingApi'
import { useTable } from '../useTable'
import useTableFilters from '../useTableFilters'
import useTranslation from '../useTranslation'
import useViewport, { IBreakpoint } from '../useViewport/useViewport'
import { MenuItem } from '@/atoms/Dropdown/Dropdown'
import useInviteApi from '@/hooks/api/administration/useInviteApi/useInviteApi'
import { ItemUserInvite } from '@/hooks/api/administration/useInviteApi/useInviteApi.types'
import useUserAccountApi from '@/hooks/api/administration/useUserAccountApi/useUserAccountApi'
import { ItemUserAccount } from '@/hooks/api/administration/useUserAccountApi/useUserAccountApi.types'
import { showToast } from '@/ui/atoms/index'
import { Badge, Button } from '@/ui/atoms/shadcn'
import { ConfirmPop } from '@/ui/molecules'

const useUsersData = (): UseUsersResponse => {
  const { t } = useTranslation('usersPage')
  const [modalUpdateRoleData, setModalUpdateRoleData] =
    useState<ModalUpdateRoleData>({
      id: null,
      role: null,
      isOpen: false
    })
  const [modalInviteUser, setModalInviteUser] = useState<ModalInviteUserType>({
    isOpen: false,
    data: null
  })
  const [modalNoLicensedUsers, setModalNoLicensedUsers] =
    useState<ModalNoLicensedUsersType>({
      isOpen: false,
      action: null
    })
  const [modalPendingInvoices, setModalPendingInvoices] =
    useState<boolean>(false)
  const [modalConfirmAction, setModalConfirmAction] =
    useState<ModalConfirmActionType>({
      isOpen: false,
      action: null,
      id: null
    })
  const [isLoadingModalConfirmAction, setIsLoadingModalConfirmAction] =
    useState<boolean>(false)
  const [drawerPurchaseUserLicenses, setDrawerPurchaseUserLicenses] =
    useState<boolean>(false)
  const [isInsertedInitialFilters, setIsInsertedInitialFilters] =
    useState<boolean>(false)
  const {
    filters,
    preparedFilters,
    setPreparedFilters,
    setFilter,
    handleApplyFilters,
    isReady
  } = useTableFilters<FiltersUsersType>({
    typeView: 'active'
  })
  const { lang } = useLocale()
  const { breakpoint } = useViewport()
  const { userContext, refreshProfile } = useAuth()
  const { pageNumber, setPageNumber, pageSize, setPageSize, resetPagination } =
    useTable()

  const { useBillingGetCurrentSubscription } = useBillingApi()
  const {
    useUserAccountsActive,
    useUserAccountsInactive,
    useUserAccountsActionUpdate
  } = useUserAccountApi()
  const { useUsersInvite, useDeleteUserInvite } = useInviteApi()
  const {
    data: dataUserAccountsActive,
    isLoading: isLoadingUserAccountsActive,
    isSuccess: isSuccessUserAccountsActive
  } = useUserAccountsActive(
    pageNumber,
    pageSize,
    filters?.typeView as FiltersUsersType['typeView']
  )
  const {
    data: dataUserAccountsInactive,
    isLoading: isLoadingUserAccountsInactive,
    isSuccess: isSuccessUserAccountsInactive
  } = useUserAccountsInactive(
    pageNumber,
    pageSize,
    filters?.typeView as FiltersUsersType['typeView']
  )
  const {
    data: dataUsersInvite,
    isLoading: isLoadingUsersInvite,
    isSuccess: isSuccessUsersInvite
  } = useUsersInvite(
    pageNumber,
    pageSize,
    filters?.typeView as FiltersUsersType['typeView']
  )
  const { mutateAsync: mutateAsyncDeleteUserInvite } = useDeleteUserInvite()
  const {
    mutateAsync: mutateAsyncUserAccountsActionUpdate,
    isLoading: isLoadingUserAccountsActionUpdate
  } = useUserAccountsActionUpdate()
  const { data: dataCurrentSubscription, refetch: refetchCurrentSubscription } =
    useBillingGetCurrentSubscription(true)

  useEffect(() => {
    const applyInitialFilters = async () => {
      if (!isReady || isInsertedInitialFilters) return

      const typeView = filters?.typeView || 'active'

      await setFilter(FiltersKeyUsers.TYPE_VIEW, typeView)

      setIsInsertedInitialFilters(true)
    }

    applyInitialFilters()
  }, [
    isReady,
    userContext.userAccountInformation.id,
    isInsertedInitialFilters,
    filters?.typeView,
    setFilter
  ])

  const pendingInvoiceId = useMemo(
    () => dataCurrentSubscription?.pendingInvoiceId as string,
    [dataCurrentSubscription?.pendingInvoiceId]
  )

  const currentDataTable: CurrentDataTableType = useMemo(() => {
    return {
      active: {
        data: dataUserAccountsActive,
        isLoading: isLoadingUserAccountsActive,
        isSuccess: isSuccessUserAccountsActive,
        type: 'users'
      },
      inactive: {
        data: dataUserAccountsInactive,
        isLoading: isLoadingUserAccountsInactive,
        isSuccess: isSuccessUserAccountsInactive,
        type: 'users'
      },
      invite: {
        data: dataUsersInvite,
        isLoading: isLoadingUsersInvite,
        isSuccess: isSuccessUsersInvite,
        type: 'invite'
      }
    }[filters?.typeView as FiltersUsersType['typeView']]
  }, [
    dataUserAccountsActive,
    isLoadingUserAccountsActive,
    isSuccessUserAccountsActive,
    dataUserAccountsInactive,
    isLoadingUserAccountsInactive,
    isSuccessUserAccountsInactive,
    dataUsersInvite,
    isLoadingUsersInvite,
    isSuccessUsersInvite,
    filters?.typeView
  ])

  const options = useMemo((): SelectOption[] => {
    return t?.optionsUsers
  }, [t?.optionsUsers])

  const generateDynamicColumnWidths = (
    breakpoint: IBreakpoint,
    type: 'active' | 'inactive' | 'invite'
  ) => {
    let baseConfig: any

    if (type === 'active' || type === 'inactive') {
      baseConfig = {
        user: { width: '30%', minWidth: '200px' },
        status: { width: '15%', minWidth: '60px' },
        function: { width: '20%', minWidth: '90px' },
        createdBy: { width: '15%', minWidth: '80px' },
        updatedBy: { width: '15%', minWidth: '80px' },
        actions: { width: '5%', minWidth: '20px' }
      }
    }
    if (type === 'invite') {
      baseConfig = {
        guest: { width: '40%', minWidth: '250px' },
        function: { width: '30%', minWidth: '120px' },
        createdBy: { width: '20%', minWidth: '80px' },
        remove: { width: '20%', minWidth: '80px' }
      }
    }

    const scaleFactor = {
      '3xl': 2,
      '2xl': 1.2,
      xl: 1.2,
      lg: 1.4,
      md: 1.2,
      sm: 1.3,
      xs: 1.4
    }

    const scale = scaleFactor[breakpoint] || 1

    const scaledConfig = Object.fromEntries(
      Object.entries(baseConfig).map(([key, value]: any) => [
        key,
        {
          width: value.width,
          minWidth: `${parseInt(value.minWidth, 10) * scale}px`
        }
      ])
    )

    return scaledConfig
  }

  const tableColumnWidth = useMemo(() => {
    return generateDynamicColumnWidths(
      breakpoint,
      (filters?.typeView as FiltersUsersType['typeView']) || 'active'
    )
  }, [breakpoint, filters?.typeView])

  const handleAction = useCallback(
    async (action: 'activate' | 'deactivate', id: string) => {
      setIsLoadingModalConfirmAction(true)
      try {
        await mutateAsyncUserAccountsActionUpdate({
          action,
          id
        })
        await refreshProfile()
        await refetchCurrentSubscription()
        showToast.success(
          action === 'activate'
            ? t?.toasts?.successActivate
            : t?.toasts?.successDeactivate
        )
      } catch (error: any) {
        if (action === 'activate' && error?.response?.status === 402) {
          // Verificar se o usuário possui pendingInvoiceId
          if (dataCurrentSubscription?.pendingInvoiceId) {
            setModalPendingInvoices(true)
            return
          }
          setModalNoLicensedUsers({
            isOpen: true,
            action: 'activate',
            id
          })
          return
        }
        showToast.error(
          action === 'activate'
            ? t?.toasts?.errorActivate
            : t?.toasts?.errorDeactivate
        )
      } finally {
        setIsLoadingModalConfirmAction(false)
      }
    },
    [
      dataCurrentSubscription?.pendingInvoiceId,
      mutateAsyncUserAccountsActionUpdate,
      refetchCurrentSubscription,
      refreshProfile,
      t?.toasts?.errorActivate,
      t?.toasts?.errorDeactivate,
      t?.toasts?.successActivate,
      t?.toasts?.successDeactivate
    ]
  )

  const columnsUsers: CustomColumn<ItemUserAccount>[] = useMemo(() => {
    return [
      {
        Header: t?.tableInformationUsersActiveAndInactive?.user || '',
        accessor: 'name',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          return (
            <div className="flex items-center gap-2">
              <div>
                <Avatar
                  name={valuesOriginal.name}
                  imgSrc={valuesOriginal.avatar}
                />
              </div>
              <div className="flex-1 w-full">
                <Typography
                  variant="text-sm-medium"
                  className="whitespace-pre-wrap"
                >
                  {valuesOriginal.name}
                </Typography>
                <Typography
                  variant="text-xs-regular"
                  className="whitespace-pre-wrap"
                >
                  {valuesOriginal.email}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidth.name
      },
      {
        Header: t?.tableInformationUsersActiveAndInactive?.status || '',
        accessor: 'updatedAtUtc',
        Cell: () => {
          const item =
            filters?.typeView === 'active'
              ? t?.mappingStatus?.active
              : t?.mappingStatus?.inactive

          return (
            <Badge size="sm" className={item?.color}>
              {item?.label}
            </Badge>
          )
        },
        ...tableColumnWidth.status
      },
      {
        Header: t?.tableInformationUsersActiveAndInactive?.function || '',
        accessor: 'role',
        Cell: ({ getValue }) => {
          const value = getValue('role') as 'Administrator' | 'User'
          const item = t?.mappingRoles?.[value] || {
            label: '',
            color: ''
          }

          return (
            <Badge size="sm" className={item?.color}>
              {item?.label}
            </Badge>
          )
        },
        ...tableColumnWidth.function
      },
      {
        Header: t?.tableInformationUsersActiveAndInactive?.createdBy || '',
        accessor: 'createdById',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original
          if (!valuesOriginal.createdByName) {
            return (
              <Typography
                variant="text-xs-regular"
                className="whitespace-pre-wrap"
              >
                {formatData(valuesOriginal.createdAtUtc, lang)}
              </Typography>
            )
          }
          return (
            <div className="flex items-center gap-2">
              <div>
                <Avatar
                  name={valuesOriginal.createdByName}
                  imgSrc={valuesOriginal.createdByAvatar}
                />
              </div>
              <div className="flex-1 w-full">
                <Typography
                  variant="text-sm-medium"
                  className="whitespace-pre-wrap"
                >
                  {valuesOriginal.createdByName}
                </Typography>
                <Typography
                  variant="text-xs-regular"
                  className="whitespace-pre-wrap"
                >
                  {formatData(valuesOriginal.createdAtUtc, lang)}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidth.createdBy
      },
      {
        Header: t?.tableInformationUsersActiveAndInactive?.updatedBy || '',
        accessor: 'lastUpdateById',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original
          if (!valuesOriginal.updatedByName) {
            return (
              <Typography
                variant="text-xs-regular"
                className="whitespace-pre-wrap"
              >
                {formatData(valuesOriginal.updatedAtUtc, lang)}
              </Typography>
            )
          }
          return (
            <div className="flex items-center gap-2">
              <div>
                <Avatar
                  name={valuesOriginal.updatedByName}
                  imgSrc={valuesOriginal.updatedByAvatar}
                />
              </div>
              <div className="flex-1 w-full">
                <Typography
                  variant="text-sm-medium"
                  className="whitespace-pre-wrap"
                >
                  {valuesOriginal.updatedByName}
                </Typography>
                <Typography
                  variant="text-xs-regular"
                  className="whitespace-pre-wrap"
                >
                  {formatData(valuesOriginal.updatedAtUtc, lang)}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidth.updatedBy
      },
      {
        Header: t?.tableInformationUsersActiveAndInactive?.actions || '',
        accessor: 'id',
        Cell: (value) => {
          const valueOriginal = value?.row?.original
          const action =
            filters?.typeView === 'active' ? t?.deactivate : t?.activate

          const menu: MenuItem[] = [
            {
              key: filters?.typeView === 'active' ? 'inactive' : 'active',
              label: action,
              disabled:
                userContext.userAccountInformation.id === valueOriginal.id
            },
            {
              key: 'updateRole',
              label: t?.changeFunction,
              disabled:
                userContext.userAccountInformation.id === valueOriginal.id
            }
          ]

          return (
            <Dropdown
              menu={menu}
              onSelect={async (state) => {
                if (
                  userContext.userAccountInformation.id === valueOriginal.id
                ) {
                  return
                }
                if (state.key === 'active') {
                  setModalConfirmAction({
                    isOpen: true,
                    action: 'activate',
                    id: valueOriginal.id
                  })
                  return
                }
                if (state.key === 'inactive') {
                  setModalConfirmAction({
                    isOpen: true,
                    action: 'deactivate',
                    id: valueOriginal.id
                  })
                  return
                }
                if (state.key === 'updateRole') {
                  setModalUpdateRoleData({
                    id: valueOriginal.id,
                    role: valueOriginal.role,
                    isOpen: true
                  })
                  return
                }
              }}
            >
              <button className="flex items-center justify-center w-8 h-8 transition-all border-none rounded-full cursor-pointer bg-gray-50 hover:bg-gray-100">
                <EllipsisVerticalIcon className="w-5 h-5 text-secondary-700" />
              </button>
            </Dropdown>
          )
        },
        noInternalPadding: true,
        ...tableColumnWidth.actions
      }
    ] as CustomColumn<ItemUserAccount>[]
  }, [
    filters?.typeView,
    lang,
    t?.activate,
    t?.changeFunction,
    t?.deactivate,
    t?.mappingRoles,
    t?.mappingStatus?.active,
    t?.mappingStatus?.inactive,
    t?.tableInformationUsersActiveAndInactive?.actions,
    t?.tableInformationUsersActiveAndInactive?.createdBy,
    t?.tableInformationUsersActiveAndInactive?.function,
    t?.tableInformationUsersActiveAndInactive?.status,
    t?.tableInformationUsersActiveAndInactive?.updatedBy,
    t?.tableInformationUsersActiveAndInactive?.user,
    tableColumnWidth.actions,
    tableColumnWidth.createdBy,
    tableColumnWidth.function,
    tableColumnWidth.name,
    tableColumnWidth.status,
    tableColumnWidth.updatedBy,
    userContext.userAccountInformation.id
  ])

  const columnsInvite: CustomColumn<ItemUserInvite>[] = useMemo(() => {
    return [
      {
        Header: t?.tableInformationUserInvite?.guest || '',
        accessor: 'name',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original
          return (
            <div className="flex items-center gap-2">
              <div>
                <Avatar
                  name={valuesOriginal.name}
                  imgSrc={valuesOriginal.avatar}
                />
              </div>
              <div className="flex-1 w-full">
                <Typography
                  variant="text-sm-medium"
                  className="whitespace-pre-wrap"
                >
                  {valuesOriginal.name}
                </Typography>
                <Typography
                  variant="text-xs-regular"
                  className="whitespace-pre-wrap"
                >
                  {valuesOriginal.email}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidth.guest
      },
      {
        Header: t?.tableInformationUserInvite?.function || '',
        accessor: 'role',
        Cell: ({ getValue }) => {
          const value = getValue('role') as 'Administrator' | 'User'
          const item = t?.mappingRoles?.[value] || {
            label: '',
            color: ''
          }

          return (
            <div>
              <Badge size="sm" className={item?.color}>
                {item?.label}
              </Badge>
            </div>
          )
        },
        ...tableColumnWidth.function
      },
      {
        Header: t?.tableInformationUserInvite?.createdBy || '',
        accessor: 'createdById',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original
          if (!valuesOriginal.createdByName) {
            return (
              <Typography
                variant="text-xs-regular"
                className="whitespace-pre-wrap"
              >
                {formatData(valuesOriginal.createdAtUtc, lang)}
              </Typography>
            )
          }
          return (
            <div className="flex items-center gap-2">
              <div>
                <Avatar
                  name={valuesOriginal.createdByName}
                  imgSrc={valuesOriginal.createdByAvatar}
                />
              </div>
              <div className="flex-1 w-full">
                <Typography
                  variant="text-sm-medium"
                  className="whitespace-pre-wrap"
                >
                  {valuesOriginal.createdByName}
                </Typography>
                <Typography
                  variant="text-xs-regular"
                  className="whitespace-pre-wrap"
                >
                  {formatData(valuesOriginal.createdAtUtc, lang)}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidth.createdBy
      },

      {
        Header: t?.tableInformationUserInvite?.action || '',
        accessor: 'id',
        Cell: ({ getValue }) => (
          <ConfirmPop
            title={t?.confirmPopDeleteUserInvite?.title}
            description={t?.confirmPopDeleteUserInvite?.description}
            cancelButtonText={t?.confirmPopDeleteUserInvite?.cancelButtonText}
            confirmButtonText={t?.confirmPopDeleteUserInvite?.confirmButtonText}
            onConfirm={async () => {
              try {
                await mutateAsyncDeleteUserInvite(getValue('id'))
                await refreshProfile()
                showToast.success(t?.toasts?.successRemove)
              } catch {
                showToast.error(t?.toasts?.errorRemove)
              }
            }}
          >
            <Button variant="outlineDestructive">
              {t?.tableInformationUserInvite?.remove}
            </Button>
          </ConfirmPop>
        ),
        noInternalPadding: true,
        ...tableColumnWidth.remove
      }
    ] as CustomColumn<ItemUserInvite>[]
  }, [
    lang,
    mutateAsyncDeleteUserInvite,
    refreshProfile,
    t?.confirmPopDeleteUserInvite?.cancelButtonText,
    t?.confirmPopDeleteUserInvite?.confirmButtonText,
    t?.confirmPopDeleteUserInvite?.description,
    t?.confirmPopDeleteUserInvite?.title,
    t?.mappingRoles,
    t?.tableInformationUserInvite?.action,
    t?.tableInformationUserInvite?.createdBy,
    t?.tableInformationUserInvite?.function,
    t?.tableInformationUserInvite?.guest,
    t?.tableInformationUserInvite?.remove,
    t?.toasts?.errorRemove,
    t?.toasts?.successRemove,
    tableColumnWidth.createdBy,
    tableColumnWidth.function,
    tableColumnWidth.guest,
    tableColumnWidth.remove
  ])

  const columns: CustomColumn<ItemUserAccount | ItemUserInvite>[] =
    useMemo(() => {
      if (filters?.typeView === 'invite') {
        return columnsInvite as CustomColumn<ItemUserInvite>[]
      }
      return columnsUsers as CustomColumn<ItemUserAccount>[]
    }, [columnsInvite, columnsUsers, filters?.typeView])

  return {
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    currentDataTable,
    columns,
    options,
    filters,
    preparedFilters,
    setPreparedFilters,
    handleApplyFilters,
    modalUpdateRoleData,
    setModalUpdateRoleData,
    modalInviteUser,
    setModalInviteUser,
    modalNoLicensedUsers,
    setModalNoLicensedUsers,
    drawerPurchaseUserLicenses,
    setDrawerPurchaseUserLicenses,
    handleAction,
    modalPendingInvoices,
    setModalPendingInvoices,
    pendingInvoiceId,
    resetPagination,
    dataCurrentSubscription,
    refetchCurrentSubscription,
    modalConfirmAction,
    setModalConfirmAction,
    isLoadingModalConfirmAction
  }
}

export default useUsersData
