import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import { GetFiscalResponse, UpdateFiscalRequest } from './useFiscalApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'
import { useAuth } from '@/providers'

const useFiscalApi = () => {
  const { handleApi, queryConfig } = useApi()
  const queryClient = useQueryClient()
  const { isAdmin, isLogged } = useAuth()

  const useGetFiscal = () => {
    return useQuery({
      queryKey: [QueryKeys.AccountSettingsFiscal],
      queryFn: () =>
        handleApi<void, GetFiscalResponse>(
          `/administration/accounts/settings/fiscal`,
          'GET'
        ),
      ...queryConfig,
      enabled: Boolean(isLogged && isAdmin)
    })
  }

  const useUpdateFiscal = () =>
    useMutation({
      mutationFn: (data: UpdateFiscalRequest) =>
        handleApi<UpdateFiscalRequest, void>(
          `/administration/accounts/settings/fiscal`,
          'PUT',
          data
        ),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.AccountSettingsFiscal]
        })
      }
    })

  return {
    useGetFiscal,
    useUpdateFiscal
  }
}

export default useFiscalApi
