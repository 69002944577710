import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters
} from '@tanstack/react-query'
import { CustomColumn } from '@/types/react-table-config'
import { BillingCurrentSubscriptionResponse } from '../api/billing/useBillingApi/useBillingApi.types'
import {
  ItemUserInvite,
  IUsersInvite,
  RoleType
} from '@/hooks/api/administration/useInviteApi/useInviteApi.types'
import {
  ItemUserAccount,
  IUserAccounts
} from '@/hooks/api/administration/useUserAccountApi/useUserAccountApi.types'
import { SelectOption } from '@/ui/atoms'

export interface IMappingStatus {
  label: string
  color: string
}

export type ModalUpdateRoleData = {
  id: string | null
  role: string | null
  isOpen: boolean
}

export type CurrentDataTableType = {
  data: IUserAccounts | IUsersInvite | null | undefined
  isLoading: boolean
  isSuccess: boolean
  type: string
}

export enum FiltersKeyUsers {
  TYPE_VIEW = 'typeView'
}

export type FiltersUsersType = {
  typeView: 'active' | 'inactive' | 'invite'
}

export type ModalNoLicensedUsersType = {
  isOpen: boolean
  action: 'invite' | 'activate' | null
  id?: string
}

export type ModalInviteUserType = {
  isOpen: boolean
  data: {
    name: string
    email: string
    role: RoleType
  } | null
}

export type ModalConfirmActionType = {
  isOpen: boolean
  action: 'activate' | 'deactivate' | null
  id: string | null
}

export type UseUsersResponse = {
  pageNumber: number
  setPageNumber: React.Dispatch<React.SetStateAction<number>>
  pageSize: number
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  currentDataTable: CurrentDataTableType
  columns: CustomColumn<ItemUserAccount | ItemUserInvite>[]
  options: SelectOption[]
  filters: FiltersUsersType | null
  preparedFilters: FiltersUsersType | null
  setPreparedFilters: (filters: FiltersUsersType) => void
  handleApplyFilters: (filters: FiltersUsersType) => void
  modalUpdateRoleData: ModalUpdateRoleData
  setModalUpdateRoleData: React.Dispatch<
    React.SetStateAction<ModalUpdateRoleData>
  >
  modalInviteUser: ModalInviteUserType
  setModalInviteUser: React.Dispatch<ModalInviteUserType>
  modalNoLicensedUsers: ModalNoLicensedUsersType
  setModalNoLicensedUsers: React.Dispatch<ModalNoLicensedUsersType>
  drawerPurchaseUserLicenses: boolean
  setDrawerPurchaseUserLicenses: React.Dispatch<boolean>
  handleAction: (action: 'activate' | 'deactivate', id: string) => Promise<void>
  modalPendingInvoices: boolean
  setModalPendingInvoices: React.Dispatch<boolean>
  pendingInvoiceId: string | null
  resetPagination: () => void
  dataCurrentSubscription: BillingCurrentSubscriptionResponse | null | undefined
  refetchCurrentSubscription: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<
    QueryObserverResult<BillingCurrentSubscriptionResponse | null, unknown>
  >
  modalConfirmAction: ModalConfirmActionType
  setModalConfirmAction: React.Dispatch<ModalConfirmActionType>
  isLoadingModalConfirmAction: boolean
}
