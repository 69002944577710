import { useQuery } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import { useAuth } from '@/providers/Auth'
import {
  RecycleBinEnvelopesUserAccountsResponse,
  RecycleBinListEnvelopesAccountsResponse,
  RecycleBinListTemplatesAccountsResponse,
  RecycleBinOwnersResponse,
  RecycleBinTemplatesUserAccountsResponse
} from '@/hooks/api/ecm/useRecycleBinApi/useRecycleBinApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'

const useRecycleBinApi = () => {
  const { handleApi, queryConfig } = useApi()
  const { authMetadata, isAdmin } = useAuth()

  const useRecycleBinListOwners = () => {
    return useQuery({
      queryKey: [QueryKeys.RecycleBinListOwners],
      queryFn: () =>
        handleApi<void, RecycleBinOwnersResponse>(
          '/ecm/recycle-bin/owners',
          'GET'
        ),
      ...queryConfig,
      enabled: Boolean(!!authMetadata?.accessToken && isAdmin)
    })
  }

  const useRecycleBinListEnvelopesAccounts = (
    pageNumber = 0,
    pageSize = 10,
    enabled = false
  ) => {
    return useQuery({
      queryKey: [
        QueryKeys.RecycleBinListEnvelopeAccounts,
        pageNumber,
        pageSize
      ],
      queryFn: () =>
        handleApi<void, RecycleBinListEnvelopesAccountsResponse>(
          `/ecm/recycle-bin/envelopes/accounts?pageNumber=${
            pageNumber + 1
          }&pageSize=${pageSize}`,
          'GET'
        ),
      ...queryConfig,
      enabled: !!authMetadata?.accessToken && enabled
    })
  }

  const useRecycleBinEnvelopesUserAccounts = (
    pageNumber = 0,
    pageSize = 10,
    id: string | null,
    enabled = false
  ) => {
    const url = id
      ? `/ecm/recycle-bin/envelopes/user-accounts/${id}`
      : `/ecm/recycle-bin/envelopes/user-accounts`

    return useQuery({
      queryKey: [
        QueryKeys.RecycleBinEnvelopesUserAccounts,
        id,
        pageNumber,
        pageSize
      ],
      queryFn: () =>
        handleApi<void, RecycleBinEnvelopesUserAccountsResponse>(
          url + `?pageNumber=${pageNumber + 1}&pageSize=${pageSize}`,
          'GET'
        ),
      ...queryConfig,

      enabled: !!authMetadata?.accessToken && enabled
    })
  }

  const useRecycleBinListTemplatesAccounts = (
    pageNumber = 0,
    pageSize = 10,
    enabled = false
  ) => {
    return useQuery({
      queryKey: [
        QueryKeys.RecycleBinListTemplatesAccounts,
        pageNumber,
        pageSize
      ],
      queryFn: () =>
        handleApi<void, RecycleBinListTemplatesAccountsResponse>(
          `/ecm/recycle-bin/templates/accounts?pageNumber=${
            pageNumber + 1
          }&pageSize=${pageSize}`,
          'GET'
        ),
      ...queryConfig,

      enabled: !!authMetadata?.accessToken && enabled
    })
  }

  const useRecycleBinTemplatesUserAccounts = (
    pageNumber = 0,
    pageSize = 10,
    id: string | null,
    enabled = false
  ) => {
    const url = id
      ? `/ecm/recycle-bin/templates/user-accounts/${id}`
      : `/ecm/recycle-bin/templates/user-accounts`

    return useQuery({
      queryKey: [
        QueryKeys.RecycleBinTemplatesUserAccounts,
        id,
        pageNumber,
        pageSize
      ],
      queryFn: () =>
        handleApi<void, RecycleBinTemplatesUserAccountsResponse>(
          url + `?pageNumber=${pageNumber + 1}&pageSize=${pageSize}`,
          'GET'
        ),
      ...queryConfig,

      enabled: !!authMetadata?.accessToken && enabled
    })
  }

  return {
    useRecycleBinListOwners,
    useRecycleBinListEnvelopesAccounts,
    useRecycleBinEnvelopesUserAccounts,
    useRecycleBinListTemplatesAccounts,
    useRecycleBinTemplatesUserAccounts
  }
}

export default useRecycleBinApi
