import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import useTranslation from '@/hooks/useTranslation'
import { AuthTemplate } from '@/templates/index'
import { InviteForm, RegisterForm } from '@/organisms/index'

const RegisterPage = () => {
  const { query } = useRouter()
  const { t } = useTranslation('seo')

  const isRegister = !query?.inviteId

  return (
    <AuthTemplate>
      {isRegister ? (
        <RegisterForm />
      ) : (
        <InviteForm inviteId={query?.inviteId as string} />
      )}
      <NextSeo title={t?.titles?.register} />
    </AuthTemplate>
  )
}

export default RegisterPage
