import { memo } from 'react'
import { useBillingTaxData, SelectOptionType } from '@/hooks/useBillingTaxData'
import useTranslation from '@/hooks/useTranslation'
import { Typography } from '@/atoms/index'
import UnauthorizedPage from '../unauthorizedPage'
import withRole from '@/hoc/withRole'
import { Button } from '@/ui/atoms/shadcn'

const BillingTaxDataPage: React.FC = () => {
  const { t, isReady } = useTranslation('billingsTaxData')

  const {
    renderField,
    isFieldDisabled,
    optionsCountries,
    optionsStates,
    optionsCities,
    isLoadingGetCities,
    isLoadingUpdateFiscal,
    isLoadingInitialData,
    handleClickButtonSave,
    formik,
    resetChanges,
    setCanGetAddressByCep
  } = useBillingTaxData()
  const {
    values,
    setValues,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors
  } = formik

  return (
    <div className="flex flex-col w-full h-full min-w-[300px] md:min-w-[700px] max-w-5xl min-h-full gap-4">
      <div className="flex flex-col gap-1">
        <Typography
          variant="title-2xl-medium"
          className="text-gray-700 dark:text-gray-700"
          type="h1"
          showSkeleton={!isReady}
        >
          {t?.title}
        </Typography>
        <Typography
          variant="text-sm-regular"
          className="text-gray-700 dark:text-gray-700"
          type="p"
          showSkeleton={!isReady}
        >
          {t?.description}
        </Typography>
      </div>
      <form
        className="flex flex-col w-full h-full gap-4 p-6 bg-white rounded-lg shadow dark:bg-gray-100 dark:text-black"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-4 md:flex-row">
          {renderField({
            type: 'input',
            name: 'name',
            value: values?.name as string,
            handleChange,
            handleBlur,
            touched: touched?.name as boolean,
            errors: errors?.name,
            showSkeleton: isLoadingInitialData || !isReady
          })}
          {renderField({
            type: 'input',
            name: 'email',
            value: values?.email as string,
            handleChange,
            handleBlur,
            touched: touched?.email as boolean,
            errors: errors?.email,
            showSkeleton: isLoadingInitialData || !isReady
          })}
        </div>
        <div className="flex flex-col gap-4 md:flex-row">
          {renderField({
            type: 'select',
            name: 'type',
            options: t?.form?.type?.options as SelectOptionType[],
            value: values?.type as any,
            handleOptionChange: (value) => setFieldValue('type', value),
            handleBlur,
            touched: touched?.type as boolean,
            errors: errors?.type,
            showSkeleton: isLoadingInitialData || !isReady
          })}
          {renderField({
            type: 'input',
            name: 'document',
            value: values?.document as string,
            handleChange,
            handleBlur,
            touched: touched?.document as boolean,
            errors: errors?.document,
            disabled: isFieldDisabled('document', values),
            mask:
              values?.type?.value === 'Personal'
                ? 'cpf'
                : values?.type?.value === 'Company'
                  ? 'cnpj'
                  : undefined,
            showSkeleton: isLoadingInitialData || !isReady
          })}
        </div>
        <div className="flex flex-col gap-4 md:flex-row">
          {renderField({
            type: 'input',
            name: 'phone',
            value: values?.phone as string,
            handleChange,
            handleBlur,
            touched: touched?.phone as boolean,
            errors: errors?.phone,
            disabled: isFieldDisabled('phone', values),
            showSkeleton: isLoadingInitialData || !isReady
          })}
          {renderField({
            type: 'select',
            name: 'country',
            options: optionsCountries?.length ? optionsCountries : [],
            value: values?.country as any,
            handleOptionChange: (option) => {
              setFieldValue('country', option)

              if (option?.value !== 'BR') {
                setValues({
                  ...values,
                  country: option,
                  state: '',
                  city: '',
                  street: '',
                  number: '',
                  complement: '',
                  district: '',
                  zipCode: ''
                })
              }
            },
            handleBlur,
            touched: touched?.country as boolean,
            errors: errors?.country,
            disabled: false,
            showSkeleton: isLoadingInitialData || !isReady
          })}
        </div>
        <div className="flex flex-col gap-4 md:flex-row">
          {renderField({
            type: 'input',
            name: 'zipCode',
            value: values?.zipCode as string,
            handleChange: (e) => {
              const { value } = e.target
              setFieldValue('zipCode', value)
              if (value.replace(/\D/g, '').length === 8) {
                setCanGetAddressByCep(true)
              }
            },
            handleBlur,
            touched: touched?.zipCode as boolean,
            errors: errors?.zipCode,
            disabled: isFieldDisabled('zipCode', values),
            mask: values?.country?.value === 'BR' ? 'cep' : undefined,
            showSkeleton: isLoadingInitialData || !isReady
          })}
          {values?.country?.value === 'BR'
            ? renderField({
                type: 'select',
                name: 'state',
                options: optionsStates?.length ? optionsStates : [],
                value: values?.state as any,
                handleOptionChange: (option) => {
                  if (option && option?.value !== values?.state) {
                    setValues({
                      ...values,
                      state: option,
                      city: ''
                    })
                  }
                },
                handleBlur,
                touched: touched?.state as boolean,
                errors: errors?.state,
                disabled: isFieldDisabled('state', values),
                showSkeleton: isLoadingInitialData || !isReady
              })
            : renderField({
                type: 'input',
                name: 'state',
                value: values?.state as string,
                handleChange,
                handleBlur,
                touched: touched?.state as boolean,
                errors: errors?.state as string,
                disabled: isFieldDisabled('state', values),
                showSkeleton: isLoadingInitialData || !isReady
              })}
        </div>
        <div className="flex flex-col gap-4 md:flex-row">
          {typeof values?.country === 'object' &&
          values?.country?.value === 'BR'
            ? renderField({
                type: 'select',
                name: 'city',
                value: values?.city as any,
                handleOptionChange: (value) => setFieldValue('city', value),
                handleBlur,
                options: optionsCities?.length ? optionsCities : [],
                touched: touched?.city as boolean,
                errors: errors?.city,
                disabled: isFieldDisabled('city', values),
                showSkeleton: isLoadingInitialData || !isReady,
                isLoading: isLoadingGetCities
              })
            : renderField({
                type: 'input',
                name: 'city',
                value: values?.city as string,
                handleChange,
                handleBlur,
                touched: touched?.city as boolean,
                errors: errors?.city as string,
                disabled: isFieldDisabled('city', values),
                showSkeleton: isLoadingInitialData || !isReady
              })}
          {renderField({
            type: 'input',
            name: 'district',
            value: values?.district as string,
            handleChange,
            handleBlur,
            touched: touched?.district as boolean,
            errors: errors?.district,
            disabled: isFieldDisabled('district', values),
            showSkeleton: isLoadingInitialData || !isReady
          })}
        </div>
        <div className="flex flex-col gap-4 md:flex-row">
          {renderField({
            type: 'input',
            name: 'street',
            value: values?.street as string,
            handleChange,
            handleBlur,
            touched: touched?.street as boolean,
            errors: errors?.street,
            disabled: isFieldDisabled('street', values),
            showSkeleton: isLoadingInitialData || !isReady
          })}
          {renderField({
            type: 'input',
            name: 'number',
            value: values?.number as string,
            handleChange,
            handleBlur,
            touched: touched?.number as boolean,
            errors: errors?.number,
            disabled: isFieldDisabled('number', values),
            showSkeleton: isLoadingInitialData || !isReady
          })}
        </div>
        <div className="flex flex-col gap-4 md:flex-row">
          {renderField({
            type: 'input',
            name: 'complement',
            value: values?.complement as string,
            handleChange,
            handleBlur,
            touched: touched?.complement as boolean,
            errors: errors?.complement,
            disabled: isFieldDisabled('complement', values),
            showSkeleton: isLoadingInitialData || !isReady
          })}
          <div className="block w-full" />
        </div>
        <div className="flex justify-end gap-2 md:pt-6">
          <Button
            onClick={resetChanges}
            className="w-fit"
            variant="neutral"
            type="button"
            showSkeleton={!isReady || isLoadingInitialData}
          >
            {t?.form?.buttonCancel?.label}
          </Button>
          <Button
            className="w-fit"
            type="submit"
            onClick={handleClickButtonSave}
            showSkeleton={!isReady || isLoadingInitialData}
            loading={isLoadingUpdateFiscal}
          >
            {t?.form?.buttonSave?.label}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default withRole(BillingTaxDataPage, 'Administrator')
