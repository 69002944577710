import { useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import useTranslation from '@/hooks/useTranslation'
import api from '@/services/api'
import { useAuth } from '@/providers/Auth'
import { UploadAvatar } from '@/atoms/index'
import { useGeneralProfileApi } from '@/hooks/api/administration/profile/general'
import { showToast } from '@/ui/atoms/index'
import { Button } from '@/ui/atoms/shadcn'
import { Form, RenderFormField } from '@/ui/molecules'
import { SettingsTemplate } from '@/ui/templates'

type GeneralProfile = {
  name: string
}

const ProfileIndexPage: React.FC = () => {
  const { t, isReady } = useTranslation(['profileGeneral', 'validations'], true)
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null)
  const { useGetProfile, useUpdateProfile, useDeleteProfileAvatar } =
    useGeneralProfileApi()

  const {
    data: dataGetProfile,
    refetch: refetchGetProfile,
    isLoading: isLoadingGetProfile,
    isFetched: isFetchedGetProfile
  } = useGetProfile()
  const {
    mutateAsync: mutateAsyncUpdateProfile,
    isLoading: isLoadingUpdateProfile
  } = useUpdateProfile()
  const {
    mutateAsync: mutateAsyncDeleteProfileAvatar,
    isLoading: isLoadingDeleteProfileAvatar
  } = useDeleteProfileAvatar()

  const { authMetadata, refreshProfile, userContext } = useAuth()

  useEffect(() => {
    if (dataGetProfile?.avatar && !avatarUrl) {
      setAvatarUrl(dataGetProfile?.avatar || null)
    }
  }, [avatarUrl, dataGetProfile?.avatar])

  const formSchema = z.object({
    name: z
      .string()
      .min(1, { message: t?.requiredField })
      .min(2, { message: t?.errorMinLength?.(2) })
      .max(200, { message: t?.errorMaxLength?.(200) })
  })

  const form = useForm<GeneralProfile>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: ''
    }
  })

  useEffect(() => {
    if (dataGetProfile) {
      form.reset({
        name: dataGetProfile.name || ''
      })
      setAvatarUrl(dataGetProfile.avatar || null)
    }
  }, [dataGetProfile, form])

  return (
    <SettingsTemplate
      title={t?.title}
      description={t?.description}
      showSkeleton={!isReady}
    >
      <Form<GeneralProfile>
        {...form}
        onSubmit={async (values) => {
          try {
            await mutateAsyncUpdateProfile({
              name: values.name
            })
            refreshProfile()
            showToast.success(t?.toasts?.successUpdate)
          } catch {
            showToast.error(t?.toasts?.errorUpdate)
            form.setValue('name', dataGetProfile?.name || '')
          }
        }}
      >
        <UploadAvatar
          url={avatarUrl}
          loading={isLoadingGetProfile || isLoadingDeleteProfileAvatar}
          onFileValidated={async (file, setLoading) => {
            try {
              const formData = new FormData()
              formData.append('image', file)
              await api.put('/administration/profile/avatar', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${authMetadata?.accessToken}`,
                  ...(userContext?.userAccountInformation?.id && {
                    'X-Signater-UserAccountId':
                      userContext?.userAccountInformation?.id
                  })
                }
              })

              const { data } = await refetchGetProfile()
              setAvatarUrl(data?.avatar || null)
              refreshProfile()
            } catch (error) {
              console.log(error)
            } finally {
              setLoading(false)
            }
          }}
          onFileDeleted={async () => {
            await mutateAsyncDeleteProfileAvatar()
            const { data } = await refetchGetProfile()
            setAvatarUrl(data?.avatar || null)
            refreshProfile()
          }}
        />
        <div className="flex flex-col gap-4">
          <RenderFormField
            control={form.control}
            name="name"
            type="input"
            showSkeleton={isLoadingGetProfile}
            {...t?.form?.name}
          />
          <div className="flex justify-end w-full">
            <Button
              className="w-fit"
              type="submit"
              showSkeleton={!isReady || !isFetchedGetProfile}
              loading={isLoadingUpdateProfile}
            >
              {t?.form?.buttonSubmitSave?.label}
            </Button>
          </div>
        </div>
        {/* <RenderFormField */}
      </Form>
    </SettingsTemplate>
  )
}

export default ProfileIndexPage
