import { useQuery } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import { useAuth } from '@/providers/Auth'
import {
  AuditSignerActionAccountItem,
  AuditsSignerActionAccounts,
  AuditsSignerActionOwners,
  AuditsSignerActionUserAccounts
} from '@/hooks/api/report/useAuditApi/useAuditApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'

const useAuditApi = () => {
  const { handleApi, queryConfig } = useApi()
  const { authMetadata } = useAuth()

  const useAuditsSignerActionsOwners = (enabled = true) => {
    return useQuery({
      queryKey: [QueryKeys.AuditsSignerActionsOwners],
      queryFn: () =>
        handleApi<void, AuditsSignerActionOwners>(
          '/report/audits/owners',
          'GET'
        ),
      ...queryConfig,
      enabled: enabled && !!authMetadata?.accessToken
    })
  }

  const useAuditsSignerActionAllAccounts = (
    enabled = true,
    pageNumber = 0,
    pageSize = 10
  ) => {
    return useQuery({
      queryKey: [QueryKeys.AuditsSignerActionAccounts, pageNumber, pageSize],
      queryFn: () =>
        handleApi<void, AuditsSignerActionAccounts>(
          `/report/audits/accounts?pageNumber=${
            pageNumber + 1
          }&pageSize=${pageSize}`,
          'GET'
        ),
      ...queryConfig,
      enabled: enabled && !!authMetadata?.accessToken
    })
  }

  const useAuditsSignerActionUserAccounts = (
    id: string | null,
    enabled = true,
    pageNumber = 0,
    pageSize = 10
  ) => {
    const url = id
      ? `/report/audits/user-accounts/${id}`
      : `/report/audits/user-accounts`

    return useQuery({
      queryKey: [
        QueryKeys.AuditsSignerActionUserAccounts,
        id,
        pageNumber,
        pageSize
      ],
      queryFn: () =>
        handleApi<void, AuditsSignerActionUserAccounts>(
          url + `?pageNumber=${pageNumber + 1}&pageSize=${pageSize}`,
          'GET'
        ),
      ...queryConfig,
      enabled: enabled && !!authMetadata?.accessToken
    })
  }

  const useAuditDetails = (id: string | null) =>
    useQuery({
      queryKey: [QueryKeys.AuditDetails, id],
      queryFn: () =>
        handleApi<void, AuditSignerActionAccountItem>(
          `/report/audits/${id}`,
          'GET'
        ),
      ...queryConfig,
      enabled: !!authMetadata?.accessToken && !!id
    })

  return {
    useAuditsSignerActionsOwners,
    useAuditsSignerActionAllAccounts,
    useAuditsSignerActionUserAccounts,
    useAuditDetails
  }
}

export default useAuditApi
