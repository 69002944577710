import React from 'react'
import { useContactsData } from '@/hooks/useContactsData'
import useTranslation from '@/hooks/useTranslation'
import { DataTableTemplate } from '@/templates/index'
import { Table } from '@/organisms/index'
import {
  EmptyStateTable,
  FilterDropdown,
  ModalActionContact,
  ModalConfirm
} from '@/molecules/index'
import { Typography } from '@/atoms/index'
import { Contact } from '@/hooks/api/ecm/useContactApi/useContactApi.types'
import { FiltersKeyContacts } from '@/hooks/useContactsData/useContactsData.types'
import { Button } from '@/ui/atoms/shadcn'

const ContactsPage: React.FC = () => {
  const { t, isReady } = useTranslation('contacts')

  const {
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    currentDataTable,
    columns,
    onSubmitActionModal,
    modalActionContact,
    setModalActionContact,
    handleDeleteContact,
    isLoadingUserAccountContacts,
    filters,
    handleApplyFilters,
    preparedFilters,
    setPreparedFilters,
    isLoadingDeleteContact,
    isLoadingSubmitModalAction
  } = useContactsData()

  const breadcrumbs = [
    { name: t?.breadcrumbs?.manage, href: null, current: false },
    { name: t?.breadcrumbs?.contacts, href: '/contacts', current: true }
  ]

  return (
    <DataTableTemplate
      header={
        <div className="flex flex-col gap-4 sm:flex-row sm:gap-2 sm:justify-between sm:items-center">
          <div className="flex flex-col flex-1 w-full gap-1">
            <Typography
              variant="title-2xl-medium"
              className="text-gray-700 dark:text-gray-700"
              classNameSkeleton="w-40"
              showSkeleton={!isReady}
              type="h1"
            >
              {t?.title}
            </Typography>
            <Typography
              variant="text-sm-regular"
              className="text-gray-700 dark:text-gray-700"
              classNameSkeleton="w-60"
              showSkeleton={!isReady}
              type="p"
            >
              {t?.description}
            </Typography>
          </div>
          <div className="flex flex-row items-end justify-end gap-2">
            <FilterDropdown
              filtersSchema={[
                {
                  id: FiltersKeyContacts.QUERY,
                  type: 'inputSearch',
                  label: t?.filterQuery?.label,
                  placeholder: t?.filterQuery?.placeholder,
                  initialValue: '',
                  isActive: Boolean(filters?.query),
                  showSkeleton: !isReady
                },
                {
                  id: FiltersKeyContacts.TYPE_FAVORITE,
                  type: 'filterFavorites',
                  label: t?.filterFavoritesLabel,
                  showSkeleton: !isReady,
                  isActive: Boolean(filters?.typeFavorite),
                  disabled: isLoadingUserAccountContacts
                }
              ]}
              values={{
                query: preparedFilters?.query || '',
                typeFavorite: preparedFilters?.typeFavorite as
                  | 'favorite'
                  | 'unfavorite'
              }}
              onFilter={(filters) => {
                setPageNumber(0)
                handleApplyFilters({
                  query: filters?.query as string,
                  typeFavorite: filters?.typeFavorite as
                    | 'favorite'
                    | 'unfavorite'
                })
              }}
              setPreparedValues={setPreparedFilters}
              showSkeleton={!isReady}
            />
            <Button
              variant="secondary"
              onClick={() => {
                setModalActionContact({
                  isOpen: true,
                  action: 'create',
                  id: null
                })
              }}
              className="w-full h-10 px-6 md:w-auto lg:px-6"
              showSkeleton={!isReady}
              timeoutSkeleton={500}
            >
              {t?.buttonCreate}
            </Button>
          </div>
        </div>
      }
      breadcrumbs={breadcrumbs}
      showSkeleton={!isReady}
    >
      {currentDataTable?.isLoading ||
      (currentDataTable?.isSuccess &&
        currentDataTable?.data &&
        currentDataTable?.data?.items?.length > 0) ? (
        <div className="flex items-center justify-center">
          <Table<Contact>
            columns={columns}
            data={currentDataTable?.data?.items || []}
            fetchData={setPageNumber}
            canPreviousPage={pageNumber > 0}
            canNextPage={
              (currentDataTable?.data?.pagination &&
                currentDataTable?.data?.pagination?.totalItems /
                  currentDataTable?.data?.pagination?.pageSize >
                  pageNumber + 1) ||
              false
            }
            pagination={currentDataTable?.data?.pagination}
            isLoading={currentDataTable?.isLoading}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageSize={setPageSize}
            setPageNumber={setPageNumber}
          />
        </div>
      ) : (
        <EmptyStateTable type="contacts" />
      )}
      {modalActionContact.isOpen &&
        !!modalActionContact.action &&
        modalActionContact.action !== 'delete' && (
          <ModalActionContact
            id={modalActionContact.id}
            isOpen={modalActionContact.isOpen}
            action={modalActionContact.action}
            onClose={() =>
              setModalActionContact({ isOpen: false, action: null, id: null })
            }
            setModalActionContact={setModalActionContact}
            onSubmit={onSubmitActionModal}
            isLoading={isLoadingSubmitModalAction}
          />
        )}
      {modalActionContact.isOpen &&
        modalActionContact.id &&
        modalActionContact.action === 'delete' && (
          <ModalConfirm
            isOpen={modalActionContact.isOpen}
            onClose={() =>
              setModalActionContact({ isOpen: false, action: null, id: null })
            }
            title={t?.modalConfirmDelete?.title}
            description={t?.modalConfirmDelete?.description}
            confirmButtonText={t?.modalConfirmDelete?.buttonConfirm}
            cancelButtonText={t?.modalConfirmDelete?.buttonCancel}
            onConfirm={handleDeleteContact}
            loadingSubmit={isLoadingDeleteContact}
          />
        )}
    </DataTableTemplate>
  )
}

export default ContactsPage
