import { useMemo } from 'react'
import { CustomColumn } from '@/types/react-table-config'
import { UsePendingInvitationsDataResponse } from './usePendingInvitationsData.types'
import { useInviteApi } from '../api/administration/user/useInviteApi'
import { ItemUserInvite } from '../api/administration/user/useInviteApi/useInviteApi.types'
import useAuthApi from '../api/auth/useAuthApi/useAuthApi'
import { useTable } from '../useTable'
import useTranslation from '../useTranslation'
import { useViewport } from '../useViewport'
import { IBreakpoint } from '../useViewport/useViewport'
import { useAuth, useLocale } from '@/providers'
import { Avatar, Typography } from '@/ui/atoms'
import { showToast } from '@/ui/atoms/index'
import { Button } from '@/ui/atoms/shadcn'
import { ConfirmPop } from '@/ui/molecules'
import { formatData } from '@/utils'

const usePendingInvitationsData = (): UsePendingInvitationsDataResponse => {
  const { t, isReady } = useTranslation('profilePendingInvitations')
  const { breakpoint } = useViewport()
  const { lang } = useLocale()
  const { refreshProfile } = useAuth()
  const { useUserInvite, useAcceptInvite, useRejectInvite } = useInviteApi()
  const { pageNumber, pageSize, setPageNumber, setPageSize } = useTable()
  const dataListUserInvite = useUserInvite(pageNumber, pageSize)
  const { mutateAsync: mutateAsyncAcceptInvite } = useAcceptInvite()
  const { mutateAsync: mutateAsyncRejectInvite } = useRejectInvite()
  const { useGetListUserAccounts } = useAuthApi()
  const {
    data: dataListUserAccounts,
    isLoading: isLoadingListUserAccounts,
    isFetched: isFetchedListUserAccounts,
    refetch: refetchListUserAccounts
  } = useGetListUserAccounts()

  const generateDynamicColumnWidths = (breakpoint: IBreakpoint) => {
    const baseConfig = {
      name: { width: '30%', minWidth: '80px' },
      invitedBy: { width: '40%', minWidth: '150px' },
      actions: { width: '30%', minWidth: '140px' }
    }

    const scaleFactor = {
      '3xl': 1,
      '2xl': 1,
      xl: 1,
      lg: 1,
      md: 1.3,
      sm: 1.2,
      xs: 1.2
    }

    const scale = scaleFactor[breakpoint] || 1

    const scaledConfig = Object.fromEntries(
      Object.entries(baseConfig).map(([key, value]) => [
        key,
        {
          width: value.width,
          minWidth: `${parseInt(value.minWidth, 10) * scale}px`
        }
      ])
    )

    return scaledConfig
  }

  const tableColumnWidth = useMemo(() => {
    return generateDynamicColumnWidths(breakpoint)
  }, [breakpoint])

  const tableColumns: CustomColumn<ItemUserInvite>[] = useMemo(() => {
    return [
      {
        Header: t?.tableInformation?.name,
        accessor: 'name',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original
          return (
            <Typography variant="text-sm-medium">
              {valuesOriginal?.name}
            </Typography>
          )
        },
        ...tableColumnWidth.name
      },
      {
        Header: t?.tableInformation?.invitedBy,
        accessor: 'invitedBy',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original
          return (
            <div className="flex items-center gap-1 lg:gap-2">
              <Avatar
                name={valuesOriginal.invitedByName}
                imgSrc={valuesOriginal.invitedByAvatar}
              />
              <div className="flex flex-col flex-1">
                <Typography variant="text-sm-medium">
                  {valuesOriginal.invitedByName}
                </Typography>
                <Typography variant="text-xs-regular">
                  {formatData(valuesOriginal.invitedAtUtc, lang)}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidth.invitedBy
      },
      {
        Header: t?.tableInformation?.actions,
        accessor: 'actions',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original
          return (
            <div className="flex gap-2">
              <ConfirmPop
                title={t?.confirmPopReject?.title}
                description={t?.confirmPopReject?.description}
                cancelButtonText={t?.confirmPopReject?.cancelButtonText}
                confirmButtonText={t?.confirmPopReject?.confirmButtonText}
                onConfirm={async () => {
                  const delay = (ms: number) =>
                    new Promise((resolve) => setTimeout(resolve, ms))

                  try {
                    await delay(200)
                    await mutateAsyncRejectInvite(valuesOriginal.id)
                    showToast.success(t?.toasts?.successReject)
                  } catch {
                    showToast.error(t?.toasts?.errorReject)
                  } finally {
                    dataListUserInvite.refetch()
                  }
                }}
              >
                <Button variant="destructive">
                  {t?.tableInformation?.reject}
                </Button>
              </ConfirmPop>
              <ConfirmPop
                title={t?.confirmPopAccept?.title}
                description={t?.confirmPopAccept?.description}
                cancelButtonText={t?.confirmPopAccept?.cancelButtonText}
                confirmButtonText={t?.confirmPopAccept?.confirmButtonText}
                onConfirm={async () => {
                  const delay = (ms: number) =>
                    new Promise((resolve) => setTimeout(resolve, ms))
                  try {
                    await delay(200)
                    await mutateAsyncAcceptInvite(valuesOriginal.id)
                    if (
                      !isLoadingListUserAccounts &&
                      isFetchedListUserAccounts &&
                      dataListUserAccounts?.items &&
                      dataListUserAccounts?.items?.length === 1
                    ) {
                      await refetchListUserAccounts()
                    }
                    await refreshProfile()
                    showToast.success(t?.toasts?.successAccept)
                  } catch {
                    showToast.error(t?.toasts?.errorAccept)
                  } finally {
                    dataListUserInvite.refetch()
                  }
                }}
                variantConfirmButton="secondary"
              >
                <Button>{t?.tableInformation?.accept}</Button>
              </ConfirmPop>
            </div>
          )
        },
        ...tableColumnWidth.actions
      }
    ]
  }, [
    dataListUserAccounts?.items,
    dataListUserInvite,
    isFetchedListUserAccounts,
    isLoadingListUserAccounts,
    lang,
    mutateAsyncAcceptInvite,
    mutateAsyncRejectInvite,
    refetchListUserAccounts,
    refreshProfile,
    t?.confirmPopAccept?.cancelButtonText,
    t?.confirmPopAccept?.confirmButtonText,
    t?.confirmPopAccept?.description,
    t?.confirmPopAccept?.title,
    t?.confirmPopReject?.cancelButtonText,
    t?.confirmPopReject?.confirmButtonText,
    t?.confirmPopReject?.description,
    t?.confirmPopReject?.title,
    t?.tableInformation?.accept,
    t?.tableInformation?.actions,
    t?.tableInformation?.invitedBy,
    t?.tableInformation?.name,
    t?.tableInformation?.reject,
    t?.toasts?.errorAccept,
    t?.toasts?.errorReject,
    t?.toasts?.successAccept,
    t?.toasts?.successReject,
    tableColumnWidth.actions,
    tableColumnWidth.invitedBy,
    tableColumnWidth.name
  ])

  return {
    tableColumns,
    dataListUserInvite,
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    showSkeleton: !isReady
  }
}

export default usePendingInvitationsData
