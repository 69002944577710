import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  ChatBubbleLeftEllipsisIcon,
  CheckCircleIcon,
  EnvelopeOpenIcon,
  ExclamationCircleIcon,
  InboxArrowDownIcon,
  MinusCircleIcon,
  XCircleIcon,
  DevicePhoneMobileIcon,
  LockClosedIcon,
  EnvelopeIcon,
  ArrowDownTrayIcon
} from '@heroicons/react/24/outline'
import { useRouter } from 'next/router'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import useTranslation from '@/hooks/useTranslation'
import { formatData } from '@/utils/date'
import { useLocale } from '@/providers/Locale'
import { Avatar, Typography } from '@/atoms/index'
import type {
  CurrentDocumentType,
  IsLoadingDownloadDocumentType,
  UsePublicEnvelopeViewDataResponse
} from './usePublicEnvelopeViewData.types'
import { usePublicApi } from '@/hooks/api/public/usePublicApi'
import { SignerStatus } from '@/hooks/api/public/usePublicApi/usePublicApi.types'
import { cn } from '@/lib/utils'
import { Badge, Button } from '@/ui/atoms/shadcn'
import * as Popover from '@/ui/atoms/shadcn/Popover'
import { IconWithTooltip } from '@/ui/molecules'

const usePublicEnvelopeViewData = (): UsePublicEnvelopeViewDataResponse => {
  const { t, isReady } = useTranslation(['envelopes', 'viewEnvelope'], true)
  const [loadingPdf, setLoadingPdf] = useState(false)
  const [currentDocument, setCurrentDocument] =
    useState<CurrentDocumentType>(null)
  const [valueSelectedDocument, setValueSelectedDocument] = useState<
    string | null
  >(null)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [IsInitialLoading, setIsInitialLoading] = useState(true)
  const [initialDataLoaded, setInitialDataLoaded] = useState(false)
  const [openModalProcessingActions, setOpenModalProcessingActions] =
    useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)
  const retryCountModalProcessingActions = useRef(0)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const { lang } = useLocale()
  const router = useRouter()
  const { id } = router.query

  const {
    useGetEnvelope,
    useGetSignedDocument,
    useGetCertificateEnvelope,
    useDownloadCertificateEnvelope,
    useDownloadSignedDocument,
    useDownloadDocumentOriginal
  } = usePublicApi()
  const {
    mutateAsync: downloadCertificateEnvelope,
    isLoading: isLoadingDownloadCertificateEnvelope
  } = useDownloadCertificateEnvelope()
  const {
    mutateAsync: downloadSignedDocument,
    isLoading: isLoadingDownloadSignedDocument
  } = useDownloadSignedDocument()
  const {
    mutateAsync: downloadDocumentOriginal,
    isLoading: isLoadingDownloadDocumentOriginal
  } = useDownloadDocumentOriginal()
  const {
    data: envelopeData,
    isLoading: isLoadingEnvelopeData,
    isError: isErrorEnvelopeData,
    isSuccess: isSuccessEnvelopeData,
    refetch: refetchEnvelopeData
  } = useGetEnvelope({
    id: id as string,
    recaptchaToken
  })
  const { data: dataGetSignedDocument } = useGetSignedDocument(
    currentDocument?.type === 'document' && currentDocument?.id
      ? currentDocument.id
      : null
  )
  const { data: dataGetCertificateEnvelope } = useGetCertificateEnvelope(
    currentDocument?.type === 'certificate' && currentDocument?.id
      ? currentDocument.id
      : null
  )

  const valuesSelectDocuments = useMemo(() => {
    if (!envelopeData?.id || isLoadingEnvelopeData) return []
    const documents = envelopeData?.documents?.map((file) => ({
      id: file?.id,
      value: file?.name,
      label: file?.name?.replace('.pdf', '')
    }))
    return [
      ...(documents || []),
      {
        id: 'certificate-envelope',
        value: 'certificate-envelope',
        label: t?.sectionSelectDocument?.optionAuditCertificate
      }
    ]
  }, [
    envelopeData?.documents,
    envelopeData?.id,
    isLoadingEnvelopeData,
    t?.sectionSelectDocument?.optionAuditCertificate
  ])

  const getRecaptchaToken = useCallback(async () => {
    const token = executeRecaptcha
      ? await executeRecaptcha('envelope_view')
      : null
    if (token) {
      setRecaptchaToken(token)
    }
  }, [executeRecaptcha])

  useEffect(() => {
    getRecaptchaToken()
  }, [getRecaptchaToken])

  useEffect(() => {
    if (
      !isSuccessEnvelopeData ||
      isErrorEnvelopeData ||
      !envelopeData?.id ||
      isLoadingEnvelopeData ||
      !isReady ||
      !t ||
      initialDataLoaded ||
      valuesSelectDocuments?.length === 0
    )
      return

    if (envelopeData?.documents?.length > 0) {
      const firstDocument = envelopeData.documents[0]
      setCurrentDocument({
        type: 'document',
        id: firstDocument.id,
        name: firstDocument.name,
        description: firstDocument.description || '',
        pageSizes: firstDocument.pageSizes
      })
      setValueSelectedDocument(firstDocument.name)
    } else {
      setCurrentDocument({
        type: 'certificate',
        id: id as string,
        name: t?.sectionSelectDocument?.optionAuditCertificate
      })
      setValueSelectedDocument('certificate-envelope')
    }
    setInitialDataLoaded(true)
    if (IsInitialLoading) {
      setIsInitialLoading(false)
      setLoadingPdf(true)
    }
    if (envelopeData?.hasActionsBeingProcessed) {
      setOpenModalProcessingActions(true)
    }
  }, [
    IsInitialLoading,
    envelopeData,
    id,
    initialDataLoaded,
    isErrorEnvelopeData,
    isLoadingEnvelopeData,
    isReady,
    isSuccessEnvelopeData,
    t,
    valuesSelectDocuments?.length
  ])

  useEffect(() => {
    const checkProcessingActions = async () => {
      if (openModalProcessingActions) {
        for (let i = 0; i < 10; i++) {
          await new Promise((resolve) => setTimeout(resolve, 5000))
          const result = await refetchEnvelopeData()
          if (!result.data?.hasActionsBeingProcessed) {
            setOpenModalProcessingActions(false)
            break
          }
          retryCountModalProcessingActions.current += 1
        }
      } else if (!envelopeData?.hasActionsBeingProcessed) {
        setOpenModalProcessingActions(false)
        retryCountModalProcessingActions.current = 0
      }
    }

    checkProcessingActions()
  }, [
    openModalProcessingActions,
    envelopeData?.hasActionsBeingProcessed,
    refetchEnvelopeData
  ])

  const handleSelectDocument = useCallback(
    (documentName: string) => {
      setValueSelectedDocument(documentName)
      setLoadingPdf(true)
      if (documentName === 'certificate-envelope') {
        setCurrentDocument({
          type: 'certificate',
          id: id as string,
          name: t?.sectionSelectDocument?.optionAuditCertificate
        })
        return
      }
      const document = envelopeData?.documents?.find(
        (doc) => doc.name === documentName
      )
      if (document?.id) {
        setCurrentDocument({
          type: 'document',
          id: document?.id,
          name: document?.name,
          description: document?.description || '',
          pageSizes: document?.pageSizes
        })
      }
    },
    [
      envelopeData?.documents,
      id,
      t?.sectionSelectDocument?.optionAuditCertificate
    ]
  )

  const currentDocumentFile = useMemo(() => {
    if (currentDocument?.type === 'document' && dataGetSignedDocument) {
      return {
        id: currentDocument.id,
        type: currentDocument.type,
        file: dataGetSignedDocument as any,
        name: currentDocument.name as string,
        description: currentDocument?.description || '',
        pageSizes: envelopeData?.documents?.find(
          (doc) => doc.id === currentDocument.id
        )?.pageSizes
      }
    }
    if (currentDocument?.type === 'certificate' && dataGetCertificateEnvelope) {
      return {
        id: currentDocument.id,
        type: currentDocument.type,
        file: dataGetCertificateEnvelope as any,
        name: t?.sectionSelectDocument?.optionAuditCertificate as string,
        description: null
      }
    }
    return null
  }, [
    currentDocument?.description,
    currentDocument?.id,
    currentDocument?.name,
    currentDocument?.type,
    dataGetSignedDocument,
    dataGetCertificateEnvelope,
    envelopeData?.documents,
    t?.sectionSelectDocument?.optionAuditCertificate
  ])

  const handleDownloadDocument = useCallback(
    (
      type: IsLoadingDownloadDocumentType['type'],
      document?: { id: string; name: string }
    ) => {
      if (type === 'certificateEnvelope') {
        downloadCertificateEnvelope(id as string)
      }
      if (type === 'signedDocument' && document) {
        downloadSignedDocument({
          id: document?.id,
          name: document?.name?.replace('.pdf', '')
        })
      }
      if (type === 'originalDocument' && document) {
        downloadDocumentOriginal({
          id: document?.id,
          name: document?.name?.replace('.pdf', '')
        })
      }
    },
    [
      downloadDocumentOriginal,
      downloadSignedDocument,
      downloadCertificateEnvelope,
      id
    ]
  )

  const isLoadingDownloadDocument: IsLoadingDownloadDocumentType =
    useMemo(() => {
      return {
        loading:
          isLoadingDownloadCertificateEnvelope ||
          isLoadingDownloadSignedDocument ||
          isLoadingDownloadDocumentOriginal,
        type: isLoadingDownloadCertificateEnvelope
          ? 'certificateEnvelope'
          : isLoadingDownloadSignedDocument
            ? 'signedDocument'
            : isLoadingDownloadDocumentOriginal
              ? 'originalDocument'
              : null
      }
    }, [
      isLoadingDownloadDocumentOriginal,
      isLoadingDownloadSignedDocument,
      isLoadingDownloadCertificateEnvelope
    ])

  const sectionEnvelopeInfo = useMemo(() => {
    const envelopeStatus = t?.mappingStatus?.[envelopeData?.status as string]
    return {
      title: t?.sectionEnvelopeInfo?.title,
      items: [
        {
          title: t?.sectionEnvelopeInfo?.name,
          value: envelopeData?.name || ''
        },
        {
          title: t?.sectionEnvelopeInfo?.description,
          value: envelopeData?.description || (
            <Typography variant="text-sm-regular" className="text-gray-500">
              -
            </Typography>
          )
        },
        {
          title: t?.sectionEnvelopeInfo?.status,
          value: envelopeStatus ? (
            <Badge size="sm" className={envelopeStatus?.color}>
              {envelopeStatus?.label}
            </Badge>
          ) : (
            <Typography variant="text-sm-regular" className="text-gray-500">
              -
            </Typography>
          )
        },
        {
          title: t?.sectionEnvelopeInfo?.creation,
          value: envelopeData?.createdByName ? (
            <div className="flex items-center gap-1 lg:gap-2">
              <Avatar
                name={envelopeData?.createdByName as string}
                imgSrc={envelopeData?.createdByAvatar}
              />
              <div className="flex flex-col flex-1">
                <Typography
                  variant="text-sm-medium"
                  className="w-full text-gray-500 text-start"
                >
                  {envelopeData?.createdByName}
                </Typography>
                {envelopeData?.createdAtUtc && (
                  <Typography
                    variant="text-xs-regular"
                    className="text-gray-500 text-start"
                  >
                    {formatData(envelopeData?.createdAtUtc, lang)}
                  </Typography>
                )}
              </div>
            </div>
          ) : (
            <Typography variant="text-sm-regular" className="text-gray-500">
              -
            </Typography>
          )
        },
        {
          title: t?.sectionEnvelopeInfo?.lastUpdate,
          value: envelopeData?.updatedByName ? (
            <div className="flex items-center gap-1 lg:gap-2">
              <Avatar
                name={envelopeData?.updatedByName as string}
                imgSrc={envelopeData?.updatedByAvatar}
              />
              <div className="flex flex-col flex-1">
                <Typography
                  variant="text-sm-medium"
                  className="text-gray-500 text-start"
                >
                  {envelopeData?.updatedByName}
                </Typography>
                {envelopeData?.updatedAtUtc && (
                  <Typography
                    variant="text-xs-regular"
                    className="text-gray-500 text-start"
                  >
                    {formatData(envelopeData?.updatedAtUtc, lang)}
                  </Typography>
                )}
              </div>
            </div>
          ) : (
            <Typography variant="text-sm-regular" className="text-gray-500">
              -
            </Typography>
          )
        },
        {
          title: t?.sectionEnvelopeInfo?.expirationDate,
          value: envelopeData?.expiresAtUtc ? (
            <Typography
              variant="text-xs-regular"
              className="text-gray-500 text-start"
            >
              {formatData(envelopeData?.expiresAtUtc, lang)}
            </Typography>
          ) : (
            <Typography variant="text-sm-regular" className="text-gray-500">
              -
            </Typography>
          )
        }
      ]
    }
  }, [
    envelopeData?.createdAtUtc,
    envelopeData?.createdByAvatar,
    envelopeData?.createdByName,
    envelopeData?.description,
    envelopeData?.expiresAtUtc,
    envelopeData?.updatedAtUtc,
    envelopeData?.updatedByAvatar,
    envelopeData?.updatedByName,
    envelopeData?.name,
    envelopeData?.status,
    lang,
    t?.mappingStatus,
    t?.sectionEnvelopeInfo?.creation,
    t?.sectionEnvelopeInfo?.description,
    t?.sectionEnvelopeInfo?.expirationDate,
    t?.sectionEnvelopeInfo?.lastUpdate,
    t?.sectionEnvelopeInfo?.name,
    t?.sectionEnvelopeInfo?.status,
    t?.sectionEnvelopeInfo?.title
  ])

  const sectionEnvelopeViewDocuments = useMemo(() => {
    return {
      title: t?.sectionEnvelopeViewDocuments?.title,
      items: envelopeData?.documents?.map((document, index) => ({
        title: (
          <div
            className="flex flex-col"
            key={`document-${document.id}-${index}`}
          >
            <Typography variant="text-sm-regular" className="text-gray-500">
              {document.name}
            </Typography>
            {document.description && (
              <Typography variant="text-sm-regular" className="text-gray-500">
                {document.description}
              </Typography>
            )}
          </div>
        ),
        value: (
          <Popover.Popover>
            <Popover.PopoverTrigger asChild>
              <div>
                <Button
                  variant="outlineSecondary"
                  size="sm"
                  className="flex items-center gap-1"
                >
                  <ArrowDownTrayIcon className="w-4 h-4" />
                  {t?.sectionEnvelopeViewDocuments?.buttonDownloadDocument}
                </Button>
              </div>
            </Popover.PopoverTrigger>
            <Popover.PopoverContent className="dark:border-gray-300 w-72">
              <div className="flex flex-col gap-4 p-1">
                {t?.sectionEnvelopeViewDocuments?.downloadButtonList?.map(
                  (button: any, index: any) => (
                    <Button
                      key={`button-${button.label}-${index}`}
                      variant="secondary"
                      className="w-full"
                      size="sm"
                      loading={
                        isLoadingDownloadDocument.type === button?.type &&
                        isLoadingDownloadDocument.loading
                      }
                      onClick={() => {
                        if (button.type === 'certificateEnvelope') {
                          handleDownloadDocument(button?.type)
                          return
                        }
                        handleDownloadDocument(button?.type, document)
                      }}
                    >
                      <ArrowDownTrayIcon className="w-4 h-4" />
                      {button.label}
                    </Button>
                  )
                )}
              </div>
            </Popover.PopoverContent>
          </Popover.Popover>
        )
      })),
      emptyState: envelopeData?.documents?.length === 0,
      emptyStateTitle: t?.sectionEnvelopeViewDocuments?.emptyState?.title,
      emptyStateMessage: t?.sectionEnvelopeViewDocuments?.emptyState?.message
    }
  }, [
    envelopeData?.documents,
    handleDownloadDocument,
    isLoadingDownloadDocument.loading,
    isLoadingDownloadDocument.type,
    t?.sectionEnvelopeViewDocuments?.buttonDownloadDocument,
    t?.sectionEnvelopeViewDocuments?.downloadButtonList,
    t?.sectionEnvelopeViewDocuments?.emptyState,
    t?.sectionEnvelopeViewDocuments?.title
  ])

  const sectionEnvelopeSigners = useMemo(() => {
    return {
      title: (
        <div className="flex items-center gap-1 px-4 py-3">
          <Typography variant="text-base-medium">
            {t?.sectionEnvelopeSigners?.title}
          </Typography>
          <Badge
            size="xs"
            className={t?.sectionEnvelopeSigners?.badgeSignedOrder?.className}
          >
            {t?.sectionEnvelopeSigners?.badgeSignedOrder?.label(
              envelopeData?.signInOrder
            )}
          </Badge>
        </div>
      ),
      items: envelopeData?.signers?.map((signer, index) => {
        const statusEnvelope =
          t?.sectionEnvelopeSigners?.status?.[signer?.status]
        return {
          title: (
            <div key={`signer-${signer.id}-${index}`} className="flex flex-col">
              <Typography
                variant="text-base-medium"
                className="text-sm text-gray-800"
              >
                {signer.name}
              </Typography>
              <Typography variant="text-sm-regular" className="text-gray-800">
                {signer.email}
              </Typography>
            </div>
          ),
          value: (
            <>
              <div className="relative flex justify-center w-full h-6 sm:hidden">
                <div className="flex items-center self-center gap-3">
                  <IconWithTooltip
                    icon={
                      <LockClosedIcon
                        className={`h-4 w-4 ${
                          signer.shouldEnforcePasscodeValidation
                            ? 'text-success-600'
                            : 'text-gray-400'
                        }`}
                      />
                    }
                    tooltipText={
                      t?.sectionEnvelopeSigners?.tooltips
                        ?.shouldEnforcePasscodeValidation
                    }
                  />
                  <IconWithTooltip
                    icon={
                      <EnvelopeIcon
                        className={`h-4 w-4 ${
                          signer.shouldEnforceEmailValidation
                            ? 'text-success-600'
                            : 'text-gray-400'
                        }`}
                      />
                    }
                    tooltipText={
                      t?.sectionEnvelopeSigners?.tooltips
                        ?.shouldEnforceEmailValidation
                    }
                  />
                  <IconWithTooltip
                    icon={
                      <DevicePhoneMobileIcon
                        className={`h-4 w-4 ${
                          signer.shouldEnforceSmsValidation
                            ? 'text-success-600'
                            : 'text-gray-400'
                        }`}
                      />
                    }
                    tooltipText={
                      t?.sectionEnvelopeSigners?.tooltips
                        ?.shouldEnforceSmsValidation
                    }
                  />
                </div>
                {signer?.status !== SignerStatus.None && (
                  <Badge
                    size="sm"
                    className={cn(
                      'absolute right-0',
                      statusEnvelope?.className
                    )}
                  >
                    {statusEnvelope?.label}
                  </Badge>
                )}
              </div>
              <div className="hidden gap-4 sm:flex">
                <div className="flex items-center gap-3">
                  <IconWithTooltip
                    icon={
                      <LockClosedIcon
                        className={`h-4 w-4 ${
                          signer.shouldEnforcePasscodeValidation
                            ? 'text-success-600'
                            : 'text-gray-400'
                        }`}
                      />
                    }
                    tooltipText={
                      t?.sectionEnvelopeSigners?.tooltips
                        ?.shouldEnforcePasscodeValidation
                    }
                  />
                  <IconWithTooltip
                    icon={
                      <EnvelopeIcon
                        className={`h-4 w-4 ${
                          signer.shouldEnforceEmailValidation
                            ? 'text-success-600'
                            : 'text-gray-400'
                        }`}
                      />
                    }
                    tooltipText={
                      t?.sectionEnvelopeSigners?.tooltips
                        ?.shouldEnforceEmailValidation
                    }
                  />
                  <IconWithTooltip
                    icon={
                      <DevicePhoneMobileIcon
                        className={`h-4 w-4 ${
                          signer.shouldEnforceSmsValidation
                            ? 'text-success-600'
                            : 'text-gray-400'
                        }`}
                      />
                    }
                    tooltipText={
                      t?.sectionEnvelopeSigners?.tooltips
                        ?.shouldEnforceSmsValidation
                    }
                  />
                </div>
                {signer?.status !== SignerStatus.None && (
                  <Badge size="sm" className={statusEnvelope?.className}>
                    {statusEnvelope?.label}
                  </Badge>
                )}
              </div>
            </>
          )
        }
      }),
      emptyState: envelopeData?.signers?.length === 0,
      emptyStateTitle: t?.sectionEnvelopeSigners?.emptyState?.title,
      emptyStateMessage: t?.sectionEnvelopeSigners?.emptyState?.message
    }
  }, [
    envelopeData?.signInOrder,
    envelopeData?.signers,
    t?.sectionEnvelopeSigners?.badgeSignedOrder,
    t?.sectionEnvelopeSigners?.emptyState,
    t?.sectionEnvelopeSigners?.status,
    t?.sectionEnvelopeSigners?.title,
    t?.sectionEnvelopeSigners?.tooltips?.shouldEnforceEmailValidation,
    t?.sectionEnvelopeSigners?.tooltips?.shouldEnforcePasscodeValidation,
    t?.sectionEnvelopeSigners?.tooltips?.shouldEnforceSmsValidation
  ])

  const getSignersActions = useCallback(() => {
    const actions = envelopeData?.signers?.flatMap((signer) => {
      const iconsActionsType = {
        View: <EnvelopeOpenIcon className="w-4 h-4 text-gray-400" />,
        Approval: <CheckCircleIcon className="w-4 h-4 text-gray-400" />,
        Rejection: <XCircleIcon className="w-4 h-4 text-gray-400" />,
        CancellationByMfaError: (
          <ExclamationCircleIcon className="w-4 h-4 text-gray-400" />
        ),
        ConfirmSignMark: <CheckCircleIcon className="w-4 h-4 text-gray-400" />,
        EmailMfaRequest: (
          <InboxArrowDownIcon className="w-4 h-4 text-gray-400" />
        ),
        SmsMfaRequest: (
          <ChatBubbleLeftEllipsisIcon className="w-4 h-4 text-gray-400" />
        ),
        Unsubscribe: <MinusCircleIcon className="w-4 h-4 text-gray-400" />
      }
      return signer.actions.map((action) => ({
        user: signer.name,
        action:
          t?.sectionEnvelopeActivity?.actionsTypeLabel?.[action.type]?.(
            action.ip,
            action.location
          ) || '',
        time: formatData(action.createdAtUtc, lang),
        icon: iconsActionsType?.[action.type]
      }))
    })

    return actions?.sort(
      (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
    )
  }, [
    envelopeData?.signers,
    lang,
    t?.sectionEnvelopeActivity?.actionsTypeLabel
  ])

  const sectionEnvelopeTimeline = useMemo(() => {
    return {
      title: t?.sectionEnvelopeActivity?.title,
      items: getSignersActions() || [],
      emptyState: getSignersActions()?.length === 0,
      emptyStateTitle: t?.sectionEnvelopeActivity?.emptyState?.title,
      emptyStateMessage: t?.sectionEnvelopeActivity?.emptyState?.message
    }
  }, [
    getSignersActions,
    t?.sectionEnvelopeActivity?.emptyState,
    t?.sectionEnvelopeActivity?.title
  ])

  const isLoadingSkeleton = useMemo(
    () => isLoadingEnvelopeData || !isReady,
    [isLoadingEnvelopeData, isReady]
  )

  const isSandboxEnvelope = useMemo(() => {
    if (envelopeData?.id) {
      return envelopeData?.isSandbox
    }
    return null
  }, [envelopeData?.id, envelopeData?.isSandbox])

  return {
    loadingPdf,
    setLoadingPdf,
    currentDocumentFile,
    sectionEnvelopeInfo,
    sectionEnvelopeViewDocuments,
    sectionEnvelopeSigners,
    sectionEnvelopeTimeline,
    handleSelectDocument,
    valuesSelectDocuments,
    valueSelectedDocument,
    isDrawerOpen,
    setIsDrawerOpen,
    isLoadingSkeleton,
    isSandboxEnvelope,
    openModalProcessingActions,
    setOpenModalProcessingActions,
    handleDownloadDocument,
    isLoadingDownloadDocument
  }
}

export default usePublicEnvelopeViewData
