import Link from 'next/link'
import { NextSeo } from 'next-seo'
import useTranslation from '@/hooks/useTranslation'
import Typography from '@/atoms/Typography'
import { Button } from '@/ui/atoms/shadcn'

const NotFoundPage = () => {
  const { t } = useTranslation(['seo', '404'], true)
  return (
    <main className="min-h-screen w-screen px-4 flex justify-center items-center bg-gray-50">
      <NextSeo title={t?.titles?.notFound} />

      <div className="text-center">
        <Typography
          variant="title-lg-medium"
          className="text-primary-700 dark:text-primary-700 text-center"
          type="p"
        >
          {t?.numberError}
        </Typography>
        <Typography
          variant="title-3xl-bold"
          className="text-gray-900 mt-2 md:mt-4 text-center"
          type="h1"
        >
          {t?.title}
        </Typography>
        <Typography
          variant="text-base-regular"
          className="text-gray-600 mt-3 md:mt-6 leading-7 text-center"
        >
          {t?.subtitle}
        </Typography>
        <div className="mt-5 md:mt-10 flex items-center justify-center">
          <Link href="/">
            <Button>{t?.back}</Button>
          </Link>
        </div>
      </div>
    </main>
  )
}

export default NotFoundPage
