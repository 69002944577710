import React, { useMemo, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useRouter } from 'next/router'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import useTranslation from '@/hooks/useTranslation'
import { useEnvelopeApi } from '@/hooks/api/ecm/useEnvelopeApi'
import { Typography } from '@/ui/atoms'
import { Button } from '@/ui/atoms/shadcn'
import { Form, RenderFormField } from '@/ui/molecules'
import { Header } from '@/ui/organisms'
import SectionUnsubscribeFeedback from '@/ui/organisms/SectionUnsubscribeFeedback'

type FormValues = {
  reason: string
}

enum Section {
  Initial = 'initial',
  Success = 'success',
  Error = 'error'
}

const UnsubscribePage: React.FC = () => {
  const [currentSection, setCurrentSection] = useState<Section>(Section.Initial)
  const { t, isReady } = useTranslation('unsubscribe')
  const { query } = useRouter()
  const signatureLinkId = query?.id as string
  const { useEnvelopeUnsubscribe } = useEnvelopeApi()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const {
    mutateAsync: mutateAsyncUnsubscribe,
    isLoading: isLoadingUnsubscribe
  } = useEnvelopeUnsubscribe()

  const formSchema = z.object({
    reason: z.string().optional()
  })

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      reason: ''
    }
  })

  const sectionsUnsubscribe = useMemo(() => {
    return {
      initial: (
        <div className="flex flex-col items-center gap-2">
          <Typography
            variant="title-2xl-medium"
            className="text-center"
            showSkeleton={!isReady}
          >
            {t?.title}
          </Typography>
          <Typography
            variant="text-sm-regular"
            className="text-center"
            showSkeleton={!isReady}
          >
            {t?.subtitle}
          </Typography>
          <Form<FormValues>
            {...form}
            onSubmit={async (values) => {
              const recaptchaToken = executeRecaptcha
                ? await executeRecaptcha('signer_unsubscription')
                : ''
              try {
                await mutateAsyncUnsubscribe({
                  id: signatureLinkId,
                  reason: values.reason,
                  recaptchaToken
                })
                setCurrentSection(Section.Success)
              } catch {
                setCurrentSection(Section.Error)
              }
            }}
            className="gap-4 mt-5"
          >
            <RenderFormField
              control={form.control}
              name="reason"
              type="textarea"
              {...t?.form?.reason}
            />
            <Button
              type="submit"
              fullWidth
              showSkeleton={!isReady}
              loading={isLoadingUnsubscribe}
            >
              {t?.form?.button?.label}
            </Button>
          </Form>
        </div>
      ),
      success: <SectionUnsubscribeFeedback status="success" />,
      error: <SectionUnsubscribeFeedback status="error" />
    }
  }, [
    executeRecaptcha,
    form,
    isLoadingUnsubscribe,
    isReady,
    mutateAsyncUnsubscribe,
    signatureLinkId,
    t?.form?.button?.label,
    t?.form?.reason,
    t?.subtitle,
    t?.title
  ])

  return (
    <div className="min-h-screen">
      <Header />
      <main className="flex justify-center w-full px-4 py-8 h-containerFull bg-gray-50">
        <div className="w-full max-w-lg py-5 mx-auto bg-white shadow px-7 md:px-14 md:py-10 rounded-3xl dark:bg-black h-fit">
          {sectionsUnsubscribe[currentSection]}
        </div>
      </main>
    </div>
  )
}

export default UnsubscribePage
