import { PlansList, PlansTable } from '@/organisms/index'
import withRole from '@/hoc/withRole'

const BillingPlansPage = () => {
  return (
    <div className="flex flex-col gap-16">
      <PlansList />
      <PlansTable />
    </div>
  )
}

export default withRole(BillingPlansPage, 'Administrator')
