import { useState, useMemo, useCallback, useEffect } from 'react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { formatData } from '@/utils/date'
import { useAuth } from '@/providers/Auth'
import { useLocale } from '@/providers/Locale'
import { CustomColumn } from '@/types/react-table-config'
import { AvatarGroup } from '@/molecules/index'
import { Avatar, SelectOption, Typography } from '@/atoms/index'
import {
  MappingStatusType,
  UseRecycleBinDataResponse,
  StatusType,
  RestoreModalDataType,
  FiltersRecycleBinType,
  FiltersKeyRecycleBin
} from './useRecycleBinData.types'
import { useCreateOptionsUser } from '../useCreateOptionsUser'
import { useTable } from '../useTable'
import useTableFilters from '../useTableFilters'
import useTranslation from '../useTranslation'
import { useViewport } from '../useViewport'
import { IBreakpoint } from '../useViewport/useViewport'
import { useEnvelopeApi } from '@/hooks/api/ecm/useEnvelopeApi'
import useRecycleBinApi from '@/hooks/api/ecm/useRecycleBinApi/useRecycleBinApi'
import {
  RecycleBinEnvelopeAccountItem,
  RecycleBinTemplateAccountItem
} from '@/hooks/api/ecm/useRecycleBinApi/useRecycleBinApi.types'
import useTemplateApi from '@/hooks/api/ecm/useTemplatesApi/useTemplatesApi'
import { showToast } from '@/ui/atoms/index'
import { Badge, Button } from '@/ui/atoms/shadcn'

const useRecycleBinData = (): UseRecycleBinDataResponse => {
  const { t } = useTranslation('recycleBin')
  const { isAdmin, userContext } = useAuth()
  const [restoreModalData, setRestoreModalData] =
    useState<RestoreModalDataType>({
      id: null,
      open: false
    })
  const [isInsertedInitialFilters, setIsInsertedInitialFilters] =
    useState<boolean>(false)
  const { pageNumber, setPageNumber, pageSize, setPageSize } = useTable()
  const { breakpoint } = useViewport()
  const { lang } = useLocale()
  const {
    filters,
    preparedFilters,
    setPreparedFilters,
    setFilter,
    handleApplyFilters,
    isReady
  } = useTableFilters<FiltersRecycleBinType>({
    entity: 'envelopes'
  })
  const {
    useRecycleBinListOwners,
    useRecycleBinListEnvelopesAccounts,
    useRecycleBinEnvelopesUserAccounts,
    useRecycleBinListTemplatesAccounts,
    useRecycleBinTemplatesUserAccounts
  } = useRecycleBinApi()
  const { useTemplateRestore } = useTemplateApi()
  const { useEnvelopeRestore } = useEnvelopeApi()

  const fetchAll = useMemo(
    () => isAdmin && filters?.user === 'all',
    [isAdmin, filters?.user]
  )
  const fetchMe = useMemo(
    () =>
      filters?.user === userContext?.userAccountInformation?.id ||
      !filters?.user,
    [filters?.user, userContext?.userAccountInformation?.id]
  )

  const { data: dataRecycleBinListOwners } = useRecycleBinListOwners()
  const {
    data: dataRecycleBinListEnvelopesAccounts,
    isSuccess: isSuccessRecycleBinListEnvelopesAccounts,
    isLoading: isLoadingRecycleBinListEnvelopesAccounts
  } = useRecycleBinListEnvelopesAccounts(
    pageNumber,
    pageSize,
    (fetchAll && filters?.entity === 'envelopes' && isInsertedInitialFilters) ??
      false
  )
  const {
    data: dataRecycleBinEnvelopesUserAccounts,
    isLoading: isLoadingRecycleBinEnvelopesUserAccounts,
    isSuccess: isSuccessRecycleBinEnvelopesUserAccounts,
    isFetching: isFetchingRecycleBinEnvelopesUserAccounts
  } = useRecycleBinEnvelopesUserAccounts(
    pageNumber,
    pageSize,
    fetchMe ? null : (filters?.user as string),
    isInsertedInitialFilters && !fetchAll && filters?.entity === 'envelopes'
  )
  const {
    data: dataRecycleBinListTemplatesAccounts,
    isSuccess: isSuccessRecycleBinListTemplatesAccounts,
    isLoading: isLoadingRecycleBinListTemplatesAccounts
  } = useRecycleBinListTemplatesAccounts(
    pageNumber,
    pageSize,
    (fetchAll && filters?.entity === 'templates' && isInsertedInitialFilters) ??
      false
  )
  const {
    data: dataRecycleBinTemplatesUserAccounts,
    isLoading: isLoadingRecycleBinTemplatesUserAccounts,
    isSuccess: isSuccessRecycleBinTemplatesUserAccounts,
    isFetching: isFetchingRecycleBinTemplatesUserAccounts
  } = useRecycleBinTemplatesUserAccounts(
    pageNumber,
    pageSize,
    fetchMe ? null : (filters?.user as string),
    isInsertedInitialFilters && !fetchAll && filters?.entity === 'templates'
  )
  const {
    mutateAsync: mutateAsyncEnvelopeRestore,
    isLoading: isLoadingEnvelopeRestore
  } = useEnvelopeRestore()
  const {
    mutateAsync: mutateAsyncTemplateRestore,
    isLoading: isLoadingTemplateRestore
  } = useTemplateRestore()

  useEffect(() => {
    const applyInitialFilters = async () => {
      if (!isReady || isInsertedInitialFilters) return

      const entity = filters?.entity || 'envelopes'

      await setFilter(FiltersKeyRecycleBin.ENTITY, entity)
      setIsInsertedInitialFilters(true)
    }

    applyInitialFilters()
  }, [
    isReady,
    isInsertedInitialFilters,
    userContext?.userAccountInformation?.id,
    isAdmin,
    dataRecycleBinListOwners?.items,
    dataRecycleBinListOwners,
    filters?.user,
    filters?.entity,
    setFilter
  ])

  const currentDataTable = useMemo(() => {
    if (filters?.user === 'all') {
      if (filters?.entity === 'envelopes') {
        return {
          data: dataRecycleBinListEnvelopesAccounts,
          isLoading: isLoadingRecycleBinListEnvelopesAccounts,
          isSuccess: isSuccessRecycleBinListEnvelopesAccounts
        }
      }
      if (filters?.entity === 'templates') {
        return {
          data: dataRecycleBinListTemplatesAccounts,
          isLoading: isLoadingRecycleBinListTemplatesAccounts,
          isSuccess: isSuccessRecycleBinListTemplatesAccounts
        }
      }
    }
    if (filters?.user !== 'all') {
      if (filters?.entity === 'envelopes') {
        return {
          data: dataRecycleBinEnvelopesUserAccounts,
          isLoading:
            isLoadingRecycleBinEnvelopesUserAccounts ||
            isFetchingRecycleBinEnvelopesUserAccounts,
          isSuccess: isSuccessRecycleBinEnvelopesUserAccounts
        }
      }
      if (filters?.entity === 'templates') {
        return {
          data: dataRecycleBinTemplatesUserAccounts,
          isLoading:
            isLoadingRecycleBinTemplatesUserAccounts ||
            isFetchingRecycleBinTemplatesUserAccounts,
          isSuccess: isSuccessRecycleBinTemplatesUserAccounts
        }
      }
    }
    return {
      data: null,
      isLoading: false,
      isSuccess: false
    }
  }, [
    dataRecycleBinEnvelopesUserAccounts,
    dataRecycleBinListEnvelopesAccounts,
    dataRecycleBinListTemplatesAccounts,
    dataRecycleBinTemplatesUserAccounts,
    filters?.entity,
    filters?.user,
    isFetchingRecycleBinEnvelopesUserAccounts,
    isFetchingRecycleBinTemplatesUserAccounts,
    isLoadingRecycleBinEnvelopesUserAccounts,
    isLoadingRecycleBinListEnvelopesAccounts,
    isLoadingRecycleBinListTemplatesAccounts,
    isLoadingRecycleBinTemplatesUserAccounts,
    isSuccessRecycleBinEnvelopesUserAccounts,
    isSuccessRecycleBinListEnvelopesAccounts,
    isSuccessRecycleBinListTemplatesAccounts,
    isSuccessRecycleBinTemplatesUserAccounts
  ])

  const handleVaultRestore = useCallback(
    async ({ vaultId }: { vaultId: string }) => {
      if (filters?.entity === 'envelopes') {
        try {
          await mutateAsyncEnvelopeRestore({
            id: restoreModalData.id as string,
            vaultId
          })
          showToast.success(t?.toasts?.successRestore(filters?.entity))
        } catch {
          showToast.error(t?.toasts?.errorRestore(filters?.entity))
        } finally {
          setRestoreModalData({ id: null, open: false })
        }
      } else if (filters?.entity === 'templates') {
        try {
          await mutateAsyncTemplateRestore({
            id: restoreModalData.id as string,
            vaultId
          })
          showToast.success(t?.toasts?.successRestore(filters?.entity))
        } catch {
          showToast.error(t?.toasts?.errorRestore(filters?.entity))
        } finally {
          setRestoreModalData({ id: null, open: false })
        }
      }
      setRestoreModalData({ id: null, open: false })
    },
    [
      filters?.entity,
      mutateAsyncEnvelopeRestore,
      mutateAsyncTemplateRestore,
      restoreModalData.id,
      t?.toasts
    ]
  )

  const optionsUser = useCreateOptionsUser({
    users: dataRecycleBinListOwners?.items as any[]
  })

  const optionsEntity = useMemo((): SelectOption[] => {
    return [
      {
        value: 'envelopes',
        label: t?.organizeData?.labelEnvelopes
      },
      {
        value: 'templates',
        label: t?.organizeData?.labelTemplates
      }
    ]
  }, [t?.organizeData?.labelEnvelopes, t?.organizeData?.labelTemplates])

  const mappingStatus: Record<StatusType, MappingStatusType> = t?.mappingStatus

  const generateDynamicColumnWidthsEnvelope = (breakpoint: IBreakpoint) => {
    const baseConfig = {
      name: { width: '25%', minWidth: '120px' },
      status: { width: '20%', minWidth: '100px' },
      owner: { width: '15%', minWidth: '100px' },
      removal: { width: '20%', minWidth: '120px' },
      signers: { width: '15%', minWidth: '80px' },
      action: { width: '5%', minWidth: '20px' }
    }

    const scaleFactor = {
      '3xl': 2,
      '2xl': 1.3,
      xl: 1.2,
      lg: 1.5,
      md: 1.1,
      sm: 1.5,
      xs: 1.5
    }

    const scale = scaleFactor[breakpoint] || 1

    const scaledConfig = Object.fromEntries(
      Object.entries(baseConfig).map(([key, value]) => [
        key,
        {
          width: value.width,
          minWidth: `${parseInt(value.minWidth, 10) * scale}px`
        }
      ])
    )

    return scaledConfig
  }

  const tableColumnWidthEnvelope = useMemo(() => {
    return generateDynamicColumnWidthsEnvelope(breakpoint)
  }, [breakpoint])

  const columnsEnvelope: CustomColumn<RecycleBinEnvelopeAccountItem>[] =
    useMemo(() => {
      return [
        {
          Header: t?.tableInformation?.envelope || '',
          accessor: 'name',
          Cell: ({ getValue }) => (
            <div className="w-full whitespace-pre-wrap">
              <Typography variant="text-sm-medium">
                {getValue('name')}
              </Typography>
            </div>
          ),
          ...tableColumnWidthEnvelope.name
        },
        {
          Header: t?.tableInformation?.status || '',
          accessor: 'status',
          Cell: ({ getValue }) => {
            const value = getValue('status') as StatusType
            const item = mappingStatus?.[value] || {
              label: '',
              color: ''
            }

            return (
              <div>
                <Badge
                  size="sm"
                  icon={InformationCircleIcon}
                  className={item?.color}
                  tooltip={item?.label}
                >
                  {item?.label}
                </Badge>
              </div>
            )
          },
          ...tableColumnWidthEnvelope.status
        },
        {
          Header: t?.tableInformation?.owner || '',
          accessor: 'userAccountOwnerName',
          Cell: (value) => {
            const valuesOriginal = value?.row?.original

            return (
              <div className="flex items-center gap-1 lg:gap-2">
                <Avatar
                  imgSrc={valuesOriginal.userAccountOwnerAvatar}
                  name={valuesOriginal.userAccountOwnerName}
                />
                <div className="flex-1">
                  <Typography variant="text-sm-medium">
                    {valuesOriginal.userAccountOwnerName}
                  </Typography>
                </div>
              </div>
            )
          },
          ...tableColumnWidthEnvelope.owner
        },
        {
          Header: t?.tableInformation?.removal || '',
          accessor: 'removedByName',
          Cell: (value) => {
            const valuesOriginal = value?.row?.original

            if (!valuesOriginal.removedByName) {
              return (
                <Typography variant="text-xs-regular">
                  {formatData(valuesOriginal.removedAtUtc, lang)}
                </Typography>
              )
            }

            return (
              <div className="flex items-center gap-1 lg:gap-2">
                <div>
                  <Avatar
                    name={valuesOriginal.removedByName}
                    imgSrc={valuesOriginal.removedByAvatar}
                  />
                </div>
                <div className="flex flex-col flex-1">
                  <Typography variant="text-sm-medium">
                    {valuesOriginal.removedByName}
                  </Typography>
                  <Typography variant="text-xs-regular">
                    {formatData(valuesOriginal.removedAtUtc, lang)}
                  </Typography>
                </div>
              </div>
            )
          },
          ...tableColumnWidthEnvelope.removal
        },
        {
          Header: t?.tableInformation?.signers || '',
          accessor: 'signers',
          Cell: (value) => {
            const valuesOriginal = value?.row?.original as any

            const signers = valuesOriginal.signers?.map((signer: any) => ({
              name: signer.name,
              email: signer.email
            }))

            return (
              <div className="flex items-center gap-2">
                <AvatarGroup users={signers} />
              </div>
            )
          },
          ...tableColumnWidthEnvelope.signers
        },
        {
          Header: t?.tableInformation?.action || '',
          accessor: 'id',
          Cell: ({ getValue }) => (
            <Button
              variant="outlineSecondary"
              onClick={() =>
                setRestoreModalData({
                  id: getValue('id'),
                  open: true
                })
              }
            >
              {t?.tableInformation?.restore}
            </Button>
          ),
          ...tableColumnWidthEnvelope.action
        }
      ]
    }, [
      lang,
      mappingStatus,
      t?.tableInformation?.action,
      t?.tableInformation?.envelope,
      t?.tableInformation?.owner,
      t?.tableInformation?.removal,
      t?.tableInformation?.restore,
      t?.tableInformation?.signers,
      t?.tableInformation?.status,
      tableColumnWidthEnvelope.action,
      tableColumnWidthEnvelope.name,
      tableColumnWidthEnvelope.owner,
      tableColumnWidthEnvelope.removal,
      tableColumnWidthEnvelope.signers,
      tableColumnWidthEnvelope.status
    ])

  const generateDynamicColumnWidthsTemplate = (breakpoint: IBreakpoint) => {
    const baseConfig = {
      name: { width: '30%', minWidth: '100px' },
      fields: { width: '30%', minWidth: '100px' },
      removal: { width: '30%', minWidth: '150px' },
      action: { width: '10%', minWidth: '40px' }
    }

    const scaleFactor = {
      '3xl': 2,
      '2xl': 1.3,
      xl: 1.2,
      lg: 1.4,
      md: 1.3,
      sm: 1.5,
      xs: 1.5
    }

    const scale = scaleFactor[breakpoint] || 1

    const scaledConfig = Object.fromEntries(
      Object.entries(baseConfig).map(([key, value]) => [
        key,
        {
          width: value.width,
          minWidth: `${parseInt(value.minWidth, 10) * scale}px`
        }
      ])
    )

    return scaledConfig
  }

  const tableColumnWidthTemplate = useMemo(() => {
    return generateDynamicColumnWidthsTemplate(breakpoint)
  }, [breakpoint])

  const columnsTemplate: CustomColumn<RecycleBinTemplateAccountItem>[] =
    useMemo(() => {
      return [
        {
          Header: t?.tableInformation?.template || '',
          accessor: 'name',
          Cell: ({ getValue }) => (
            <div className="w-full whitespace-pre-wrap">
              <Typography variant="text-sm-medium">
                {getValue('name')}
              </Typography>
            </div>
          ),
          ...tableColumnWidthTemplate.name
        },
        {
          Header: t?.tableInformation?.fields || '',
          accessor: 'fields',
          Cell: ({ getValue }) => {
            const value = getValue('fields')

            return (
              <Badge
                size="sm"
                className="bg-blue-100 text-info-600"
                tooltip={value}
              >
                {t?.tableInformation?.fieldsBadge(value)}
              </Badge>
            )
          },
          ...tableColumnWidthTemplate.fields
        },
        {
          Header: t?.tableInformation?.removal || '',
          accessor: 'removedByName',
          Cell: (value) => {
            const valuesOriginal = value?.row?.original

            return (
              <div className="flex items-center gap-1 lg:gap-2">
                <div>
                  <Avatar name={valuesOriginal.removedByName} />
                </div>
                <div className="flex flex-col flex-1">
                  <Typography variant="text-sm-medium">
                    {valuesOriginal.removedByName}
                  </Typography>
                  <Typography variant="text-xs-regular">
                    {formatData(valuesOriginal.removedAtUtc, lang)}
                  </Typography>
                </div>
              </div>
            )
          },
          ...tableColumnWidthTemplate.removal
        },
        {
          Header: t?.tableInformation?.action || '',
          accessor: 'id',
          Cell: ({ getValue }) => (
            <Button
              variant="outlineSecondary"
              onClick={() =>
                setRestoreModalData({
                  id: getValue('id'),
                  open: true
                })
              }
            >
              {t?.tableInformation?.restore}
            </Button>
          ),
          ...tableColumnWidthTemplate.action
        }
      ]
    }, [
      lang,
      t?.tableInformation,
      tableColumnWidthTemplate.action,
      tableColumnWidthTemplate.fields,
      tableColumnWidthTemplate.name,
      tableColumnWidthTemplate.removal
    ])

  const isLoadingRestore = useMemo(() => {
    return isLoadingEnvelopeRestore || isLoadingTemplateRestore
  }, [isLoadingEnvelopeRestore, isLoadingTemplateRestore])

  return {
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    currentDataTable,
    columns:
      filters?.entity === 'envelopes' ? columnsEnvelope : columnsTemplate,
    optionsUser,
    optionsEntity,
    restoreModalData,
    setRestoreModalData,
    filters,
    preparedFilters,
    setPreparedFilters,
    handleApplyFilters,
    handleVaultRestore,
    isLoadingRestore
  }
}

export default useRecycleBinData
