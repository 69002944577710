import useTranslation from '@/hooks/useTranslation'
import { ItemUserInvite } from '@/hooks/api/administration/user/useInviteApi/useInviteApi.types'
import usePendingInvitationsData from '@/hooks/usePendingInvitationsData/usePendingInvitationsData'
import { EmptyStateTable } from '@/ui/molecules'
import { Table } from '@/ui/organisms'
import { SettingsTemplate } from '@/ui/templates'

const ProfilePendingInvitationsPage: React.FC = () => {
  const { t, isReady } = useTranslation('profilePendingInvitations')
  const {
    tableColumns,
    dataListUserInvite,
    pageNumber,
    pageSize,
    setPageNumber,
    setPageSize
  } = usePendingInvitationsData()

  return (
    <SettingsTemplate
      title={t?.title}
      description={t?.description}
      showSkeleton={!isReady}
      className="max-w-2xl sm:w-full h-fit"
    >
      <div className="w-full">
        {dataListUserInvite?.isLoading ||
        (dataListUserInvite?.isSuccess &&
          dataListUserInvite?.data &&
          dataListUserInvite?.data?.items?.length > 0) ? (
          <div className="flex items-center justify-center">
            <Table<ItemUserInvite>
              columns={tableColumns}
              data={dataListUserInvite?.data?.items || []}
              fetchData={setPageNumber}
              canPreviousPage={pageNumber > 0}
              canNextPage={
                (dataListUserInvite?.data?.pagination &&
                  dataListUserInvite?.data?.pagination?.totalItems /
                    dataListUserInvite?.data?.pagination?.pageSize >
                    pageNumber + 1) ||
                false
              }
              pagination={dataListUserInvite?.data?.pagination}
              isLoading={dataListUserInvite?.isLoading}
              pageSize={pageSize}
              pageNumber={pageNumber}
              setPageSize={setPageSize}
              setPageNumber={setPageNumber}
            />
          </div>
        ) : (
          <EmptyStateTable type="profilePendingInvitations" />
        )}
      </div>
    </SettingsTemplate>
  )
}

export default ProfilePendingInvitationsPage
