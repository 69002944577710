import { useSignaturesData } from '@/hooks/useSignaturesData'
import useTranslation from '@/hooks/useTranslation'
import { SignatureType } from '@/types/constants'
import { SignatureMark } from '@/hooks/api/administration/profile/user/signatures/useSignaturesApi.types'
import { Typography } from '@/ui/atoms'
import { Button } from '@/ui/atoms/shadcn'
import { EmptyStateTable, ModalCreateSignature } from '@/ui/molecules'
import { Table } from '@/ui/organisms'

const ProfileSignaturesPage: React.FC = () => {
  const { t, isReady } = useTranslation('profileSignatures')

  const {
    dataListSignature,
    dataListRubric,
    columnsSignature,
    columnsRubric,
    openModalSignature,
    setOpenModalSignature,
    onCloseModalSignature,
    handleSuccessCallback,
    tablePaginationSignature,
    tablePaginationRubric
  } = useSignaturesData({
    enabledListRubricMarks: true,
    enabledListSignatureMarks: true
  })

  return (
    <div className="flex flex-col max-w-5xl gap-10">
      <div className="flex flex-col w-full gap-4">
        <div className="flex items-center justify-between gap-1">
          <div className="flex flex-col gap-1">
            <Typography
              variant="title-2xl-medium"
              className="text-gray-700 dark:text-gray-700"
              type="h1"
              showSkeleton={!isReady}
            >
              {t?.titleSignature}
            </Typography>
            <Typography
              variant="text-sm-regular"
              className="text-gray-700 dark:text-gray-700"
              type="p"
              showSkeleton={!isReady}
            >
              {t?.descriptionSignature}
            </Typography>
          </div>
          <Button
            onClick={() =>
              setOpenModalSignature({
                type: SignatureType.Signature,
                open: true
              })
            }
            showSkeleton={!isReady}
          >
            {t?.buttonCreateSignature}
          </Button>
        </div>
        <div className="flex flex-col w-full gap-6 p-6 bg-white rounded-lg shadow dark:bg-gray-100 dark:text-black">
          {dataListSignature?.isLoading ||
          (dataListSignature?.isSuccess &&
            dataListSignature?.data &&
            dataListSignature?.data?.items?.length > 0) ? (
            <div className="flex justify-center items-center">
              <Table<SignatureMark>
                columns={columnsSignature}
                data={dataListSignature?.data?.items || []}
                fetchData={tablePaginationSignature.setPageNumber}
                canPreviousPage={tablePaginationSignature.pageNumber > 0}
                canNextPage={
                  (dataListSignature?.data?.pagination &&
                    dataListSignature?.data?.pagination?.totalItems /
                      dataListSignature?.data?.pagination?.pageSize >
                      tablePaginationSignature.pageNumber + 1) ||
                  false
                }
                pagination={dataListSignature?.data?.pagination}
                isLoading={dataListSignature?.isLoading}
                pageSize={tablePaginationSignature.pageSize}
                pageNumber={tablePaginationSignature.pageNumber}
                setPageSize={tablePaginationSignature.setPageSize}
                setPageNumber={tablePaginationSignature.setPageNumber}
              />
            </div>
          ) : (
            <EmptyStateTable type="profileSignatures" />
          )}
        </div>
      </div>
      <div className="flex flex-col w-full h-full gap-4">
        <div className="flex items-center justify-between gap-1">
          <div className="flex flex-col gap-1">
            <Typography
              variant="title-2xl-medium"
              className="text-gray-700 dark:text-gray-700"
              type="h1"
              showSkeleton={!isReady}
            >
              {t?.titleRubric}
            </Typography>
            <Typography
              variant="text-sm-regular"
              className="text-gray-700 dark:text-gray-700"
              type="p"
              showSkeleton={!isReady}
            >
              {t?.descriptionRubric}
            </Typography>
          </div>
          <Button
            onClick={() =>
              setOpenModalSignature({
                type: SignatureType.Rubric,
                open: true
              })
            }
            showSkeleton={!isReady}
          >
            {t?.buttonCreateRubric}
          </Button>
        </div>
        <div className="flex flex-col w-full h-full gap-6 p-6 bg-white rounded-lg shadow dark:bg-gray-100 dark:text-black">
          {dataListRubric?.isLoading ||
          (dataListRubric?.isSuccess &&
            dataListRubric?.data &&
            dataListRubric?.data?.items?.length > 0) ? (
            <div className="flex justify-center items-center">
              <Table<SignatureMark>
                columns={columnsRubric}
                data={dataListRubric?.data?.items || []}
                fetchData={tablePaginationRubric.setPageNumber}
                canPreviousPage={tablePaginationRubric.pageNumber > 0}
                canNextPage={
                  (dataListRubric?.data?.pagination &&
                    dataListRubric?.data?.pagination?.totalItems /
                      dataListRubric?.data?.pagination?.pageSize >
                      tablePaginationRubric.pageNumber + 1) ||
                  false
                }
                pagination={dataListRubric?.data?.pagination}
                isLoading={dataListRubric?.isLoading}
                pageSize={tablePaginationRubric.pageSize}
                pageNumber={tablePaginationRubric.pageNumber}
                setPageSize={tablePaginationRubric.setPageSize}
                setPageNumber={tablePaginationRubric.setPageNumber}
              />
            </div>
          ) : (
            <EmptyStateTable type="profileRubrics" />
          )}
        </div>
      </div>
      {openModalSignature.open && (
        <ModalCreateSignature
          open={openModalSignature.open}
          onClose={onCloseModalSignature}
          type={
            openModalSignature.type as
              | SignatureType.Rubric
              | SignatureType.Signature
          }
          onSuccessCallback={handleSuccessCallback}
          hiddenSaveMyProfile
        />
      )}
    </div>
  )
}

export default ProfileSignaturesPage
