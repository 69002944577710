import useTranslation from '@/hooks/useTranslation'
import { DataTableTemplate } from '@/templates/index'
import { Table } from '@/organisms/index'
import { EmptyStateTable } from '@/molecules/index'
import { Typography } from '@/atoms/index'
import { IInboxItem } from '@/hooks/api/ecm/useInboxApi/useInboxApi.types'
import useInboxData from '@/hooks/useInboxData/useInboxData'

const InboxPage = () => {
  const { t, isReady } = useTranslation('inboxPage')

  const {
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    currentDataTable,
    columns
  } = useInboxData()

  return (
    <DataTableTemplate
      header={
        <Typography
          variant="title-2xl-medium"
          className="text-gray-700 dark:text-gray-700"
          classNameSkeleton="w-40"
          showSkeleton={!isReady}
          type="h1"
        >
          {t?.title}
        </Typography>
      }
      showSkeleton={!isReady}
    >
      {currentDataTable?.isLoading ||
      (currentDataTable?.isSuccess &&
        currentDataTable?.data &&
        currentDataTable?.data?.items?.length > 0) ? (
        <div className="flex justify-center items-center">
          <Table<IInboxItem>
            columns={columns}
            data={currentDataTable?.data?.items || []}
            fetchData={setPageNumber}
            canPreviousPage={pageNumber > 0}
            canNextPage={
              (currentDataTable?.data?.pagination &&
                currentDataTable?.data?.pagination?.totalItems /
                  currentDataTable?.data?.pagination?.pageSize >
                  pageNumber + 1) ||
              false
            }
            pagination={currentDataTable?.data?.pagination}
            isLoading={currentDataTable?.isLoading}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageSize={setPageSize}
            setPageNumber={setPageNumber}
          />
        </div>
      ) : (
        <EmptyStateTable type="inbox" />
      )}
    </DataTableTemplate>
  )
}

export default InboxPage
