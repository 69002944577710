import { useState, useCallback, useEffect, useMemo } from 'react'
import { useFormik } from 'formik'
import { parsePhoneNumber } from 'react-phone-number-input'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { useLocale } from '@/providers/Locale'
import { Input, InputPhone, InputWithMask, SelectSearch } from '@/atoms/index'
import {
  DataAddressType,
  FiscalBaseType,
  RenderFieldProps,
  SelectOptionType,
  UseBillingTaxDataResponse
} from './useBillingTaxData.types'
import useTranslation from '../useTranslation'
import useFiscalApi from '@/hooks/api/administration/account/settings/useFiscalApi/useFiscalApi'
import { useAddressApi } from '@/hooks/api/platform/useAddressApi'
import { showToast } from '@/ui/atoms/index'
import { validateCNPJ, validateCPF } from '@/utils'

const useBillingTaxData = (): UseBillingTaxDataResponse => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [insertedInitialValues, setInsertedInitialValues] = useState(false)
  const [canGetAddressByCep, setCanGetAddressByCep] = useState(false)
  const [dataAddress, setDataAddress] = useState<DataAddressType>({
    countryCode: '',
    stateCode: ''
  })
  const { t } = useTranslation('billingsTaxData')
  const { t: tCountries } = useTranslation('countries')
  const { useGetCountries, useGetStates, useGetCities, useGetAddressByCep } =
    useAddressApi()
  const { useGetFiscal, useUpdateFiscal } = useFiscalApi()
  const { lang } = useLocale()
  const {
    mutateAsync: mutateAsyncUpdateFiscal,
    isLoading: isLoadingUpdateFiscal
  } = useUpdateFiscal()
  const {
    data: dataGetFiscal,
    isLoading: isLoadingGetFiscal,
    isError: errorGetFiscal
  } = useGetFiscal()

  const formSchema = Yup.object().shape({
    name: Yup.string().required(t?.validations?.required),
    email: Yup.string()
      .email(t?.validations?.email)
      .required(t?.validations?.required),
    type: Yup.object().shape({
      value: Yup.string().required(t?.validations?.required),
      label: Yup.string().required(t?.validations?.required)
    }),
    document: Yup.string().when('type', (arrType, schema) => {
      if (arrType && arrType[0]?.value === 'Personal') {
        return schema
          .transform((value) => normalizeInput(value))
          .test('cpf', t?.validations?.cpf, (value) => {
            return validateCPF(value as string)
          })
          .required(t?.validations?.required)
      }
      if (arrType && arrType[0]?.value === 'Company') {
        return schema
          .transform((value) => normalizeInput(value))
          .test('cnpj', t?.validations?.cnpj, (value) => {
            return validateCNPJ(value as string)
          })
          .required(t?.validations?.required)
      }
      return Yup.string().required(t?.validations?.required)
    }),
    phone: Yup.string().required(t?.validations?.required),
    country: Yup.object().shape({
      value: Yup.string().required(t?.validations?.required),
      label: Yup.string().required(t?.validations?.required)
    }),
    state: Yup.mixed().when('$country', (arrType, schema) => {
      if (arrType && arrType[0]?.value === 'BR') {
        return schema.required(t?.validations?.required)
      }
      if (arrType && arrType[0]?.value !== 'BR') {
        return schema.required(t?.validations?.required)
      }
      return Yup.string().required(t?.validations?.required)
    }),
    city: Yup.mixed().when('$country', (arrType, schema) => {
      if (arrType && arrType[0]?.value === 'BR') {
        return schema.required(t?.validations?.required)
      }
      if (arrType && arrType[0]?.value !== 'BR') {
        return schema.required(t?.validations?.required)
      }
      return Yup.string().required(t?.validations?.required)
    }),
    street: Yup.string().required(t?.validations?.required),
    number: Yup.string().required(t?.validations?.required),
    complement: Yup.string(),
    district: Yup.string().required(t?.validations?.required),
    zipCode: Yup.string().when('country', (arrCountry, schema) => {
      return arrCountry && arrCountry[0]?.value === 'BR'
        ? schema
            .transform((value) => normalizeInput(value))
            .matches(/^\d{8}$/, t?.validations?.zipCode)
            .required(t?.validations?.required)
        : schema.required(t?.validations?.required)
    })
  })

  const onSubmit = useCallback(
    async (values: FiscalBaseType) => {
      const phoneIddValue = values?.phone
        ? (parsePhoneNumber(values?.phone)?.countryCallingCode as string)
        : ''
      const phoneNumberValue = values?.phone
        ? (parsePhoneNumber(values?.phone)?.nationalNumber as string)
        : ''

      const addressCountryCode =
        typeof values?.country === 'object'
          ? values?.country?.value
          : values?.country
      const addressState =
        typeof values?.state === 'object' ? values?.state?.value : values?.state
      const addressCity =
        typeof values?.city === 'object' ? values?.city?.value : values?.city

      try {
        await mutateAsyncUpdateFiscal({
          addressCity: addressCity as string,
          addressComplement: values?.complement as string,
          addressCountryCode: addressCountryCode as string,
          addressDistrict: values?.district as string,
          addressNumber: values?.number as string,
          addressState: addressState as string,
          addressStreet: values?.street as string,
          addressZipCode: values?.zipCode as string,
          document: values?.document as string,
          email: values?.email as string,
          name: values?.name as string,
          phoneIdd: phoneIddValue as any,
          phoneNumber: phoneNumberValue,
          type: values?.type?.value as 'Personal' | 'Company' | 'Foreign'
        })

        showToast.success(t?.toasts?.buttonSaveSuccess)
      } catch ({ response }: any) {
        if (
          response?.data?.errors?.[0] === 'Invalid CNPJ' ||
          response?.data?.errors?.[0] === 'Invalid CPF'
        ) {
          return showToast.error(t?.validations?.document)
        }
        showToast.error(t?.toasts?.buttonSaveError)
      }
    },
    [
      mutateAsyncUpdateFiscal,
      t?.toasts?.buttonSaveError,
      t?.toasts?.buttonSaveSuccess,
      t?.validations?.document
    ]
  )

  const { values, setValues, ...restFormik } = useFormik<FiscalBaseType>({
    initialValues: {
      type: null,
      name: '',
      email: '',
      document: '',
      phone: '',
      country: {
        value: 'BR',
        label: tCountries?.countries?.BR
      },
      state: null,
      city: null,
      street: '',
      number: '',
      complement: '',
      district: '',
      zipCode: ''
    },
    validationSchema: formSchema,
    onSubmit: onSubmit
  })

  const {
    data: dataGetCountries,
    isLoading: isLoadingGetCountries,
    fetchStatus: fetchStatusGetCountries
  } = useGetCountries()
  const {
    data: dataGetStates,
    isLoading: isLoadingGetStates,
    fetchStatus: fetchStatusGetStates
  } = useGetStates(
    dataAddress?.countryCode === 'BR' ? dataAddress?.countryCode : undefined
  )
  const {
    data: dataGetCities,
    isLoading: isLoadingGetCities,
    fetchStatus: fetchStatusGetCities
  } = useGetCities(
    dataAddress?.countryCode === 'BR' ? dataAddress?.countryCode : undefined,
    dataAddress?.countryCode === 'BR' && dataAddress?.stateCode
      ? dataAddress?.stateCode
      : undefined
  )

  const hasAddress = useMemo(() => {
    return (
      !!dataGetFiscal?.addressCity &&
      !!dataGetFiscal?.addressState &&
      !isLoadingGetFiscal
    )
  }, [
    dataGetFiscal?.addressCity,
    dataGetFiscal?.addressState,
    isLoadingGetFiscal
  ])

  const { data: dataAddressByCep, isLoading: isLoadingGetAddressByCep } =
    useGetAddressByCep(
      dataAddress?.countryCode === 'BR' && (!hasAddress || canGetAddressByCep)
        ? values.zipCode?.replace(/\D/g, '') || ''
        : ''
    )

  const optionsCountries = useMemo(() => {
    return dataGetCountries?.items?.map((item) => {
      return {
        label: tCountries?.countries?.[item.code as string],
        value: item.code
      }
    }) as SelectOptionType[]
  }, [dataGetCountries?.items, tCountries?.countries])

  const optionsStates = useMemo(() => {
    return dataGetStates?.items?.map((item) => {
      return {
        label: item.name,
        value: item.code
      }
    }) as SelectOptionType[]
  }, [dataGetStates?.items])

  const optionsCities = useMemo(() => {
    return dataGetCities?.items?.map((item) => {
      return {
        label: item.name,
        value: item.ibgeCode
      }
    }) as SelectOptionType[]
  }, [dataGetCities?.items])

  useEffect(() => {
    if (
      dataGetFiscal?.addressCountryCode ||
      (!dataGetFiscal?.addressCountryCode &&
        dataGetFiscal?.name &&
        dataGetFiscal?.email) ||
      (dataGetFiscal?.addressState &&
        !dataAddress?.countryCode &&
        !dataAddress?.stateCode)
    ) {
      setDataAddress({
        countryCode: (dataGetFiscal?.addressCountryCode as string) || 'BR',
        stateCode: dataGetFiscal?.addressState as string
      })
    }
  }, [
    dataAddress?.countryCode,
    dataAddress?.stateCode,
    dataGetFiscal,
    dataGetFiscal?.addressCountryCode,
    dataGetFiscal?.addressState,
    dataGetFiscal?.email,
    dataGetFiscal?.name
  ])

  useEffect(() => {
    const optionsStatesLoaded = optionsStates?.some((option) => option.label)
    const optionsCitiesLoaded = optionsCities?.some((option) => option.label)

    if (
      dataGetFiscal?.name &&
      !insertedInitialValues &&
      t?.form?.type?.options &&
      tCountries?.countries
    ) {
      if (
        !!dataAddress?.countryCode &&
        dataAddress?.countryCode === 'BR' &&
        optionsStatesLoaded &&
        optionsCitiesLoaded
      ) {
        const type =
          dataGetFiscal?.type === 'Company' ||
          dataGetFiscal?.type === 'Foreign' ||
          dataGetFiscal?.type === 'Personal'
            ? dataGetFiscal?.type
            : 'Personal'
        const country = optionsCountries?.find(
          (option) => option.value === dataGetFiscal?.addressCountryCode
        )
        const state = optionsStates?.find(
          (option) => option.value === dataGetFiscal?.addressState
        )
        const city = optionsCities?.find(
          (option) => option.value === dataGetFiscal?.addressCity
        )

        const data = {
          type: {
            label: t?.form?.type?.options?.find(
              (option: any) => option.value === type
            )?.label,
            value: type
          },
          name: dataGetFiscal?.name,
          email: dataGetFiscal?.email,
          document: dataGetFiscal?.document,
          phone:
            dataGetFiscal?.phoneIdd && dataGetFiscal?.phoneNumber
              ? `+${String(
                  dataGetFiscal?.phoneIdd
                )}${dataGetFiscal?.phoneNumber}`
              : '',
          country,
          state,
          city,
          street: dataGetFiscal?.addressStreet,
          number: dataGetFiscal?.addressNumber,
          complement: dataGetFiscal?.addressComplement,
          district: dataGetFiscal?.addressDistrict,
          zipCode: dataGetFiscal?.addressZipCode
        }
        setValues(data as FiscalBaseType)
        setInsertedInitialValues(true)
        return
      }
      if (!!dataAddress?.countryCode && dataAddress?.countryCode !== 'BR') {
        const type =
          dataGetFiscal?.type === 'Company' ||
          dataGetFiscal?.type === 'Foreign' ||
          dataGetFiscal?.type === 'Personal'
            ? dataGetFiscal?.type
            : 'Personal'
        const country = dataGetFiscal?.addressCountryCode || {
          label:
            tCountries?.countries?.[dataGetFiscal?.addressCountryCode as any] ||
            tCountries?.countries?.BR,
          value: dataGetFiscal?.addressCountryCode || 'BR'
        }
        const state = dataGetFiscal?.addressState || null
        const city = dataGetFiscal?.addressCity || null

        const data = {
          type: {
            label: t?.form?.type?.options?.find(
              (option: any) => option.value === type
            )?.label,
            value: type
          },
          name: dataGetFiscal?.name,
          email: dataGetFiscal?.email,
          document: dataGetFiscal?.document,
          phone:
            dataGetFiscal?.phoneIdd && dataGetFiscal?.phoneNumber
              ? `+${String(
                  dataGetFiscal?.phoneIdd
                )}${dataGetFiscal?.phoneNumber}`
              : '',
          country,
          state,
          city,
          street: dataGetFiscal?.addressStreet,
          number: dataGetFiscal?.addressNumber,
          complement: dataGetFiscal?.addressComplement,
          district: dataGetFiscal?.addressDistrict,
          zipCode: dataGetFiscal?.addressZipCode
        }
        setValues(data as FiscalBaseType)
        setInsertedInitialValues(true)
        return
      }
      if (
        dataAddress?.countryCode === 'BR' &&
        !dataAddress?.stateCode &&
        optionsStatesLoaded
      ) {
        const type =
          dataGetFiscal?.type === 'Company' ||
          dataGetFiscal?.type === 'Foreign' ||
          dataGetFiscal?.type === 'Personal'
            ? dataGetFiscal?.type
            : 'Personal'
        const country = optionsCountries?.find(
          (option) => option.value === dataGetFiscal?.addressCountryCode
        )
        const state = optionsStates?.find(
          (option) => option.value === dataGetFiscal?.addressState
        )
        const city =
          optionsCities?.find(
            (option) => option.value === dataGetFiscal?.addressCity
          ) || null

        const data = {
          type: {
            label: t?.form?.type?.options?.find(
              (option: any) => option.value === type
            )?.label,
            value: type
          },
          name: dataGetFiscal?.name,
          email: dataGetFiscal?.email,
          document: dataGetFiscal?.document,
          phone:
            dataGetFiscal?.phoneIdd && dataGetFiscal?.phoneNumber
              ? `+${String(
                  dataGetFiscal?.phoneIdd
                )}${dataGetFiscal?.phoneNumber}`
              : '',
          country,
          state,
          city,
          street: dataGetFiscal?.addressStreet,
          number: dataGetFiscal?.addressNumber,
          complement: dataGetFiscal?.addressComplement,
          district: dataGetFiscal?.addressDistrict,
          zipCode: dataGetFiscal?.addressZipCode
        }
        setValues(data as FiscalBaseType)
        setInsertedInitialValues(true)

        return
      }
    }
  }, [
    dataAddress?.countryCode,
    dataAddress.stateCode,
    dataGetFiscal?.addressCity,
    dataGetFiscal?.addressComplement,
    dataGetFiscal?.addressCountryCode,
    dataGetFiscal?.addressDistrict,
    dataGetFiscal?.addressNumber,
    dataGetFiscal?.addressState,
    dataGetFiscal?.addressStreet,
    dataGetFiscal?.addressZipCode,
    dataGetFiscal?.document,
    dataGetFiscal?.email,
    dataGetFiscal?.name,
    dataGetFiscal?.phoneIdd,
    dataGetFiscal?.phoneNumber,
    dataGetFiscal?.type,
    insertedInitialValues,
    optionsCities,
    optionsCountries,
    optionsStates,
    setValues,
    t?.form?.type?.options,
    tCountries?.countries
  ])

  useEffect(() => {
    if (
      (!hasAddress || canGetAddressByCep) &&
      !isLoadingGetFiscal &&
      dataAddressByCep?.localidade
    ) {
      setValues((prevValues) => ({
        ...prevValues,
        city: {
          label: dataAddressByCep?.localidade,
          value: dataAddressByCep?.ibge
        },
        state: {
          label: dataAddressByCep?.uf,
          value: dataAddressByCep?.uf
        },
        district: dataAddressByCep?.bairro,
        complement: dataAddressByCep?.complemento,
        street: dataAddressByCep?.logradouro
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataAddressByCep?.bairro,
    dataAddressByCep?.complemento,
    dataAddressByCep?.ibge,
    dataAddressByCep?.localidade,
    dataAddressByCep?.logradouro,
    dataAddressByCep?.uf,
    hasAddress,
    isLoadingGetFiscal
  ])

  const isLoadingInitialData = useMemo(() => {
    return (
      (isLoadingGetCountries && fetchStatusGetCountries === 'fetching') ||
      (isLoadingGetStates && fetchStatusGetStates === 'fetching') ||
      (isLoadingGetFiscal && !errorGetFiscal) ||
      (!insertedInitialValues && !errorGetFiscal)
    )
  }, [
    errorGetFiscal,
    fetchStatusGetCountries,
    fetchStatusGetStates,
    insertedInitialValues,
    isLoadingGetCountries,
    isLoadingGetFiscal,
    isLoadingGetStates
  ])

  const resetChanges = useCallback(() => {
    if (dataGetFiscal?.name) {
      const type =
        dataGetFiscal?.type === 'Company' || dataGetFiscal?.type === 'Foreign'
          ? dataGetFiscal?.type
          : 'Personal'

      const country = optionsCountries?.find(
        (option) => option.value === dataGetFiscal?.addressCountryCode
      )
      const state = optionsStates?.find(
        (option) => option.value === dataGetFiscal?.addressState
      )
      const city = optionsCities?.find(
        (option) => option.value === dataGetFiscal?.addressCity
      )

      const data = {
        type: {
          label: t?.form?.type?.options?.find(
            (option: any) => option.value === type
          )?.label,
          value: type
        },
        name: dataGetFiscal?.name,
        email: dataGetFiscal?.email,
        document: dataGetFiscal?.document,
        phone:
          dataGetFiscal?.phoneIdd && dataGetFiscal?.phoneNumber
            ? `+${String(dataGetFiscal?.phoneIdd)}${dataGetFiscal?.phoneNumber}`
            : '',
        country,
        state,
        city,
        street: dataGetFiscal?.addressStreet,
        number: dataGetFiscal?.addressNumber,
        complement: dataGetFiscal?.addressComplement,
        district: dataGetFiscal?.addressDistrict,
        zipCode: dataGetFiscal?.addressZipCode
      }
      setValues(data as FiscalBaseType)
      return
    }
    const data = {
      type: null,
      name: '',
      email: '',
      document: '',
      phone: '',
      country: null,
      state: null,
      city: null,
      street: '',
      number: '',
      complement: '',
      district: '',
      zipCode: ''
    }
    setValues(data as FiscalBaseType)
  }, [
    dataGetFiscal?.addressCity,
    dataGetFiscal?.addressComplement,
    dataGetFiscal?.addressCountryCode,
    dataGetFiscal?.addressDistrict,
    dataGetFiscal?.addressNumber,
    dataGetFiscal?.addressState,
    dataGetFiscal?.addressStreet,
    dataGetFiscal?.addressZipCode,
    dataGetFiscal?.document,
    dataGetFiscal?.email,
    dataGetFiscal?.name,
    dataGetFiscal?.phoneIdd,
    dataGetFiscal?.phoneNumber,
    dataGetFiscal?.type,
    optionsCities,
    optionsCountries,
    optionsStates,
    setValues,
    t?.form?.type?.options
  ])

  const normalizeInput = useCallback((value: string | null) => {
    if (!value || !value.length) {
      return value
    }
    return value.replace(/[^\d]/g, '')
  }, [])

  const renderField = useCallback(
    ({
      type,
      name,
      value,
      options,
      handleChange,
      handleOptionChange,
      handleBlur,
      touched,
      errors,
      disabled = false,
      mask,
      showSkeleton = false,
      isLoading = false
    }: RenderFieldProps) => {
      if (type === 'select') {
        return (
          <SelectSearch
            id={t?.form?.[name]?.name}
            label={t?.form?.[name]?.label}
            options={options || []}
            value={value}
            onChange={(option) => {
              handleOptionChange?.(option)
            }}
            error={touched && errors ? errors : undefined}
            placeholder={t?.form?.[name]?.placeholder}
            isDisabled={disabled}
            showSkeleton={showSkeleton}
            isLoading={isLoading}
            fullWidth
          />
        )
      }
      if (type === 'input' && name === 'phone') {
        return (
          <InputPhone
            placeholder={t?.form?.phone?.placeholder}
            label={t?.form?.phone?.label}
            value={value}
            onChange={(value) => {
              if (handleChange) {
                handleChange({
                  target: {
                    name: t?.form?.phone?.name,
                    value
                  }
                } as any)
              }
            }}
            defaultCountry={lang === 'PtBr' ? 'BR' : 'US'}
            error={errors && isSubmitted ? (errors as string) : undefined}
            showSkeleton={showSkeleton}
            international
          />
        )
      }
      if (type === 'input' && mask) {
        return (
          <InputWithMask
            maskType={mask}
            name={t?.form?.[name]?.name}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors && isSubmitted ? (errors as string) : undefined}
            label={t?.form?.[name]?.label}
            placeholder={t?.form?.[name]?.placeholder}
            required={t?.form?.[name]?.required}
            disabled={disabled}
            showSkeleton={showSkeleton}
          />
        )
      }
      return (
        <Input
          name={t?.form?.[name]?.name}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors && isSubmitted ? (errors as string) : undefined}
          label={t?.form?.[name]?.label}
          placeholder={t?.form?.[name]?.placeholder}
          required={t?.form?.[name]?.required}
          showSkeleton={showSkeleton}
          disabled={disabled}
        />
      )
    },
    [isSubmitted, lang, t?.form]
  )

  const isFieldDisabled = useCallback(
    (field: keyof FiscalBaseType, values: FiscalBaseType) => {
      switch (field) {
        case 'document':
          return !values.type
        case 'phone':
          return !values.type
        case 'state':
          return !values?.country
        case 'city':
          return !values?.country || !values.state
        case 'street':
        case 'number':
        case 'complement':
        case 'district':
          return !values?.country || !values.state || !values.city
        case 'zipCode':
          return !values?.country
        default:
          return false
      }
    },
    []
  )

  const handleClickButtonSave = useCallback(() => {
    setIsSubmitted(true)
  }, [])

  return {
    renderField,
    isFieldDisabled,
    optionsCountries,
    optionsStates,
    optionsCities,
    isLoadingGetCities:
      isLoadingGetCities && fetchStatusGetCities === 'fetching',
    isLoadingInitialData,
    isLoadingUpdateFiscal,
    handleClickButtonSave,
    formik: {
      ...restFormik,
      values,
      setValues
    },
    resetChanges,
    setCanGetAddressByCep
  }
}

export default useBillingTaxData
