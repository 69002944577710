import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import {
  NotificationsResponse,
  UpdateNotificationsRequest
} from './useNotificationsApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'

const useNotificationsApi = () => {
  const { handleApi, queryConfig } = useApi()
  const queryClient = useQueryClient()

  const useGetNotifications = () => {
    return useQuery({
      queryKey: [QueryKeys.ProfileSettingsNotifications],
      queryFn: () =>
        handleApi<void, NotificationsResponse>(
          `/administration/profile/settings/notifications`,
          'GET'
        ),
      ...queryConfig
    })
  }

  const useUpdateNotifications = () =>
    useMutation({
      mutationFn: (data: UpdateNotificationsRequest) =>
        handleApi<UpdateNotificationsRequest, void>(
          `/administration/profile/settings/notifications`,
          'PUT',
          data
        ),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ProfileSettingsNotifications]
        })
      }
    })

  return {
    useGetNotifications,
    useUpdateNotifications
  }
}

export default useNotificationsApi
