import { useMemo } from 'react'
import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline'
import { PencilIcon } from '@heroicons/react/24/solid'
import mixpanel from 'mixpanel-browser'
import { useRouter } from 'next/router'
import useTranslation from '@/hooks/useTranslation'
import { useVaultsData } from '@/hooks/useVaultsData'
import { DataTableTemplate } from '@/templates/index'
import { Table } from '@/organisms/index'
import {
  EmptyStateTable,
  ModalErrorPublishEnvelope,
  ModalActionVault,
  ModalConfirm,
  ButtonWithTooltip,
  ModalRenameTemplate,
  FilterDropdown
} from '@/molecules/index'
import { DropdownButton, Typography } from '@/atoms/index'
import {
  FiltersKeyVaults,
  FlattenVaultItem
} from '@/hooks/useVaultsData/useVaultsData.types'
import { Button } from '@/ui/atoms/shadcn'
import ModalSearchVault from '@/ui/molecules/ModalSearchVault'

const VaultPage = () => {
  const { t, isReady } = useTranslation('vaults')
  const { query, push } = useRouter()
  const id = query?.id as string

  const {
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    currentDataTable,
    columns,
    modalActionVault,
    setModalActionVault,
    onSubmitActionModal,
    handleApplyFilters,
    filters,
    preparedFilters,
    setPreparedFilters,
    handleActionModal,
    isLoadingModalConfirm,
    modalErrorPublishEnvelope,
    setModalErrorPublishEnvelope,
    dataCurrentVault
  } = useVaultsData({
    id: id as string,
    type: 'vaultId'
  })

  const title = useMemo(() => {
    return dataCurrentVault?.name
  }, [dataCurrentVault?.name])

  const loadingT = useMemo(() => {
    if (isReady && title) {
      return false
    }
    return true
  }, [isReady, title])

  const breadcrumbs = useMemo(() => {
    const baseBreadcrumb = [
      { name: t?.breadcrumbs?.manage, href: null, current: false },
      { name: t?.breadcrumbs?.vaults, href: '/vaults', current: true }
    ]

    if (dataCurrentVault?.name) {
      return [
        ...baseBreadcrumb,
        {
          name: dataCurrentVault?.name,
          href: `/vaults/${id}`,
          current: true
        }
      ]
    }
  }, [
    dataCurrentVault?.name,
    id,
    t?.breadcrumbs?.manage,
    t?.breadcrumbs?.vaults
  ])

  const tModalConfirm = useMemo(() => {
    return {
      deleteVault: t?.modalConfirmDeleteVault,
      deleteEnvelope: t?.modalConfirmDeleteEnvelope,
      deleteTemplate: t?.modalConfirmDeleteTemplate,
      publishEnvelope: t?.modalConfirmPublishEnvelope,
      unpublishEnvelope: t?.modalConfirmUnpublishEnvelope,
      unpublishToEditEnvelope: t?.modalConfirmUnpublishToEditEnvelope,
      unpublishToDeleteEnvelope: t?.modalConfirmUnpublishToDeleteEnvelope,
      cancelEnvelope: t?.modalConfirmCancelEnvelope,
      unschedulePublication: t?.modalConfirmUnschedulePublication
    }[
      modalActionVault?.action as
        | 'deleteEnvelope'
        | 'deleteTemplate'
        | 'deleteVault'
        | 'publishEnvelope'
        | 'unpublishEnvelope'
        | 'unpublishToEditEnvelope'
        | 'unpublishToDeleteEnvelope'
        | 'cancelEnvelope'
        | 'unschedulePublication'
    ]
  }, [
    modalActionVault?.action,
    t?.modalConfirmCancelEnvelope,
    t?.modalConfirmDeleteEnvelope,
    t?.modalConfirmDeleteTemplate,
    t?.modalConfirmDeleteVault,
    t?.modalConfirmPublishEnvelope,
    t?.modalConfirmUnpublishEnvelope,
    t?.modalConfirmUnpublishToDeleteEnvelope,
    t?.modalConfirmUnpublishToEditEnvelope,
    t?.modalConfirmUnschedulePublication
  ])

  const buttonsToggleFilter = useMemo(
    () => [
      {
        key: 'envelopes',
        label: t?.toggleButtonGroup?.envelopes
      },
      {
        key: 'templates',
        label: t?.toggleButtonGroup?.templates
      },
      {
        key: 'all',
        label: t?.toggleButtonGroup?.all
      }
    ],
    [
      t?.toggleButtonGroup?.all,
      t?.toggleButtonGroup?.envelopes,
      t?.toggleButtonGroup?.templates
    ]
  )

  const createEnvelope = () => {
    mixpanel.track('Create envelope', { origin: 'vault' })
    push(`/envelope`)
  }

  return (
    <DataTableTemplate
      header={
        <div className="flex flex-col gap-4 sm:flex-row sm:gap-2 sm:justify-between sm:items-center">
          <div className="flex flex-col gap-1 md:min-w-96">
            <div className="flex items-center">
              <Typography
                variant="title-2xl-medium"
                className="text-gray-700 dark:text-gray-700"
                classNameSkeleton="w-40"
                showSkeleton={loadingT}
                type="h1"
              >
                {title}
              </Typography>
              <ButtonWithTooltip
                variant="neutral"
                className="px-2 ml-2 bg-transparent border-none cursor-pointer hover:bg-transparent sm:px-2 md:px-2 lg:p-2"
                onClick={() => {
                  setModalActionVault({
                    isOpen: true,
                    action: 'updateVault',
                    id: id
                  })
                }}
                tooltipText={t?.tooltipRename}
              >
                <PencilIcon className="w-4 h-4 text-gray-700 hover:text-gray-500" />
              </ButtonWithTooltip>
            </div>
            {(dataCurrentVault?.description || loadingT) && (
              <Typography
                variant="text-sm-regular"
                className="text-gray-700 dark:text-gray-700"
                classNameSkeleton="w-60"
                showSkeleton={loadingT}
                type="p"
              >
                {dataCurrentVault?.description}
              </Typography>
            )}
          </div>
          <div className="flex flex-col items-end justify-end flex-1 gap-2 lg:flex-row lg:w-fit">
            <div className="flex items-end justify-end w-full gap-2 md:w-fit">
              <Button
                variant="outlineSecondary"
                onClick={() => push('/vaults')}
                showSkeleton={loadingT}
                timeoutSkeleton={500}
              >
                <ArrowSmallLeftIcon
                  className="w-4 h-4 fill-current"
                  aria-hidden="true"
                />
                {t?.cancel}
              </Button>
              <FilterDropdown
                filtersSchema={[
                  {
                    id: FiltersKeyVaults.ENTITY,
                    type: 'toggleButtonGroup',
                    label: t?.toggleButtonGroup?.label,
                    initialValue: 'all',
                    isActive: !!filters?.entity && filters?.entity !== 'all',
                    disabled: currentDataTable?.isLoading,
                    showSkeleton: loadingT,
                    buttons: buttonsToggleFilter
                  },
                  {
                    id: FiltersKeyVaults.QUERY,
                    type: 'inputSearch',
                    label: t?.filterQueryInsideVault?.label,
                    placeholder: t?.filterQueryInsideVault?.placeholder,
                    initialValue: '',
                    isActive: !!filters?.query,
                    showSkeleton: loadingT
                  }
                ]}
                values={{
                  query: preparedFilters?.query || '',
                  entity: preparedFilters?.entity as
                    | 'envelopes'
                    | 'templates'
                    | 'all'
                }}
                setPreparedValues={setPreparedFilters}
                onFilter={async (filters) => {
                  handleApplyFilters({
                    entity: filters?.entity as
                      | 'envelopes'
                      | 'templates'
                      | 'all'
                      | null,
                    query: filters?.query as any
                  })
                }}
                showSkeleton={loadingT}
              />
              <DropdownButton
                variant="secondary"
                label={t?.buttonCreateEnvelope}
                dropdownItems={[
                  {
                    key: 'createTemplate',
                    label: t?.buttonCreateTemplate,
                    onClick: () => push('/template')
                  }
                ]}
                onClick={(key) => {
                  if (key === 'main') createEnvelope()
                  if (key === 'createTemplate') push('/template')
                }}
                showSkeleton={loadingT}
              />
            </div>
          </div>
        </div>
      }
      breadcrumbs={breadcrumbs}
      showSkeleton={!isReady}
    >
      {currentDataTable?.isLoading ||
      (currentDataTable?.isSuccess &&
        currentDataTable?.data &&
        currentDataTable?.data?.items?.length > 0) ? (
        <div className="flex items-center justify-center ">
          <Table<FlattenVaultItem>
            columns={columns}
            data={currentDataTable?.data?.items || []}
            fetchData={setPageNumber}
            canPreviousPage={pageNumber > 0}
            canNextPage={
              (currentDataTable?.data?.pagination &&
                currentDataTable?.data?.pagination?.totalItems /
                  currentDataTable?.data?.pagination?.pageSize >
                  pageNumber + 1) ||
              false
            }
            pagination={currentDataTable?.data?.pagination}
            isLoading={currentDataTable?.isLoading}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageSize={setPageSize}
            setPageNumber={setPageNumber}
          />
        </div>
      ) : (
        <EmptyStateTable type="vault" />
      )}
      {modalActionVault.isOpen &&
        (modalActionVault.action === 'viewVault' ||
          modalActionVault.action === 'createVault' ||
          modalActionVault.action === 'updateVault') && (
          <ModalActionVault
            id={modalActionVault.id as string}
            isOpen={modalActionVault.isOpen}
            action={modalActionVault.action}
            onClose={() =>
              setModalActionVault({ isOpen: false, action: null, id: null })
            }
            setDataModalActionVault={setModalActionVault}
            onSubmit={onSubmitActionModal}
          />
        )}
      {modalActionVault.isOpen &&
        (modalActionVault.action === 'deleteVault' ||
          modalActionVault.action === 'deleteEnvelope' ||
          modalActionVault.action === 'deleteTemplate' ||
          modalActionVault.action === 'publishEnvelope' ||
          modalActionVault.action === 'unpublishToEditEnvelope' ||
          modalActionVault.action === 'unpublishEnvelope' ||
          modalActionVault.action === 'unpublishToDeleteEnvelope' ||
          modalActionVault.action === 'cancelEnvelope' ||
          modalActionVault.action === 'unschedulePublication') && (
          <ModalConfirm
            isOpen={modalActionVault.isOpen}
            onClose={() =>
              setModalActionVault({
                isOpen: false,
                action: null,
                id: null
              })
            }
            title={tModalConfirm?.title}
            description={tModalConfirm?.description}
            confirmButtonText={tModalConfirm?.buttonConfirm}
            cancelButtonText={tModalConfirm?.buttonCancel}
            onConfirm={handleActionModal}
            loadingSubmit={isLoadingModalConfirm}
            variantConfirmButton={
              modalActionVault.action === 'publishEnvelope' ||
              modalActionVault.action === 'unpublishToEditEnvelope' ||
              modalActionVault.action === 'unpublishEnvelope' ||
              modalActionVault.action === 'unschedulePublication'
                ? 'secondary'
                : 'destructive'
            }
          />
        )}
      <ModalErrorPublishEnvelope
        isOpen={modalErrorPublishEnvelope.open}
        typeError={modalErrorPublishEnvelope.error}
        onConfirm={() => {
          if (modalErrorPublishEnvelope.error === 'Envelopes') {
            push('/billing/plans')
          }
          if (modalErrorPublishEnvelope.error === 'SignerMfaCredits') {
            push('/billing/plans?trigger=signer-mfa-credits')
          }
          if (modalErrorPublishEnvelope.error === 'ApiEnvelopes') {
            push('/billing/plans?trigger=envelope-api-credit')
          }
          setModalErrorPublishEnvelope({ open: false, error: null })
        }}
        onClose={() => {
          setModalErrorPublishEnvelope({ open: false, error: null })
        }}
      />
      {modalActionVault.isOpen &&
        (modalActionVault.action === 'moveEnvelope' ||
          modalActionVault.action === 'moveTemplate') && (
          <ModalSearchVault
            isOpen={modalActionVault.isOpen}
            onClose={() =>
              setModalActionVault({ isOpen: false, action: null, id: null })
            }
            onSearchVaultCallback={async (vault) => {
              await handleActionModal(vault.vaultId)
            }}
            actionType={modalActionVault.action}
          />
        )}
      {modalActionVault.action === 'renameTemplate' &&
        modalActionVault &&
        modalActionVault.id && (
          <ModalRenameTemplate
            isOpen={
              modalActionVault.action === 'renameTemplate' &&
              modalActionVault.isOpen
            }
            onClose={() =>
              setModalActionVault({
                isOpen: false,
                action: null,
                id: null
              })
            }
            id={modalActionVault.id}
          />
        )}
    </DataTableTemplate>
  )
}

export default VaultPage
