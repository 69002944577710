import React, { useCallback, useState } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import useTranslation from '@/hooks/useTranslation'
import tToast from '@/translations/toast'
import { Lang } from '@/types/global'
import useAuthApi from '@/hooks/api/auth/useAuthApi/useAuthApi'
import { UserAccount } from '@/hooks/api/auth/useAuthApi/useAuthApi.types'
import { useAuth } from '@/providers'
import { Typography } from '@/ui/atoms'
import { showToast } from '@/ui/atoms/index'
import { Button } from '@/ui/atoms/shadcn'
import { AuthTemplate } from '@/ui/templates'

type LoadingSubmitAccountType = {
  isLoading: boolean
  accountId: string | null
}

const SwitchAccountPage: React.FC = () => {
  const { t, isReady } = useTranslation('switchAccount')
  const [loadingSubmitAccount, setLoadingSubmitAccount] =
    useState<LoadingSubmitAccountType>({
      isLoading: false,
      accountId: null
    })
  const { useGetListUserAccounts } = useAuthApi()
  const { setUserContext, getUserContextByAccount } = useAuth()
  const {
    data: dataListUserAccounts,
    isLoading: isLoadingListUserAccounts,
    isFetched: isFetchedListUserAccounts
  } = useGetListUserAccounts()
  const { push } = useRouter()

  const getToastT = useCallback(
    (lang: Lang, item: keyof (typeof tToast)[Lang]) => {
      return tToast?.[lang]?.[item as keyof (typeof tToast)[Lang]]
    },
    []
  )

  const handleChangeAccount = useCallback(
    async (account: UserAccount) => {
      setLoadingSubmitAccount({
        isLoading: true,
        accountId: account.accountId
      })
      try {
        const response = await getUserContextByAccount(account.userAccountId)
        if (response?.tenantInformation?.id) {
          setUserContext(response)
          showToast.success(
            getToastT(
              response?.userAccountInformation?.language,
              'successLoginAction'
            )
          )
          push('/')
        }
      } catch (error) {
        console.error(error)
        setLoadingSubmitAccount({
          isLoading: false,
          accountId: null
        })
      }
    },
    [getToastT, getUserContextByAccount, push, setUserContext]
  )

  return (
    <AuthTemplate>
      <div className="flex flex-col gap-2">
        <Typography
          type="h1"
          variant="title-lg-regular"
          className="text-gray-700 dark:text-gray-700"
          showSkeleton={!isReady}
        >
          {t?.title}
        </Typography>
        <div className="flex flex-col gap-2">
          {isLoadingListUserAccounts ||
          !isFetchedListUserAccounts ||
          !isReady ? (
            Array.from({ length: 3 }).map((_, index) => (
              <div key={index} className="w-full h-14 skeleton" />
            ))
          ) : dataListUserAccounts?.items?.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-32 gap-1 p-2">
              <div className="relative w-full h-full max-w-32">
                <Image
                  src="/assets/icons/empty-state.svg"
                  alt="Folder Dashboard"
                  layout="fill"
                />
              </div>
              <Typography
                variant="text-sm-regular"
                className="text-center"
                showSkeleton={!isReady}
              >
                {t?.emptyState}
              </Typography>
            </div>
          ) : (
            dataListUserAccounts?.items?.map((account) => (
              <div
                key={account.accountId}
                className="flex items-center justify-between px-4 py-3 border border-gray-300 rounded shadow-sm cursor-pointer dark:bg-gray-50 dark:text-black"
              >
                <Typography variant="text-base-regular" className="flex-1">
                  {account.accountName}
                </Typography>
                <Button
                  variant="link"
                  className="flex items-center gap-1 w-fit"
                  onClick={() => handleChangeAccount(account)}
                  loading={
                    loadingSubmitAccount.accountId === account.accountId &&
                    loadingSubmitAccount.isLoading
                  }
                >
                  {t?.goTo}
                </Button>
              </div>
            ))
          )}
        </div>
      </div>
    </AuthTemplate>
  )
}

export default SwitchAccountPage
