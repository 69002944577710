import { useEffect, useMemo } from 'react'
import {
  EllipsisVerticalIcon,
  InformationCircleIcon
} from '@heroicons/react/24/outline'
import { useRouter } from 'next/router'
import { useTable } from '@/hooks/useTable'
import useTranslation from '@/hooks/useTranslation'
import formatCurrency from '@/utils/currency'
import { formatData } from '@/utils/date'
import { useLocale } from '@/providers/Locale'
import { CustomColumn } from '@/types/react-table-config'
import { Table } from '@/organisms/index'
import { EmptyStateTable } from '@/molecules/index'
import { Dropdown, MenuItem } from '@/atoms/index'
import Typography from '@/atoms/Typography'
import useBillingApi from '@/hooks/api/billing/useBillingApi/useBillingApi'
import { BillingInvoiceItem } from '@/hooks/api/billing/useBillingApi/useBillingApi.types'
import useViewport, { IBreakpoint } from '@/hooks/useViewport/useViewport'
import { useAuth } from '@/providers'
import { Badge } from '@/ui/atoms/shadcn'

const BillingPaymentsPage = () => {
  const { t } = useTranslation('billingsPayments')
  const { pageNumber, setPageNumber, pageSize, setPageSize } = useTable()
  const { isAdmin } = useAuth()

  const {
    useBillingInvoices,
    useBillingGetLinkNfe,
    useBillingGetLinkPendingInvoice,
    useBillingGetLinkReceipt
  } = useBillingApi()
  const {
    data: dataBillingInvoices,
    isLoading: isLoadingBillingInvoices,
    isSuccess: isSuccessBillingInvoices
  } = useBillingInvoices(pageNumber, pageSize)
  const { mutateAsync: mutateAsyncBillingGetLinkNfe } = useBillingGetLinkNfe()
  const { mutateAsync: mutateAsyncBillingGetPendingInvoice } =
    useBillingGetLinkPendingInvoice()
  const { mutateAsync: mutateAsyncBillingGetLinkReceipt } =
    useBillingGetLinkReceipt()
  const { breakpoint } = useViewport()
  const { lang } = useLocale()
  const { push } = useRouter()

  useEffect(() => {
    if (isAdmin === false) {
      push('/unauthorized')
    }
  }, [isAdmin, push])

  const generateDynamicColumnWidths = (breakpoint: IBreakpoint) => {
    const baseConfig = {
      createdAtUtc: { width: '20%', minWidth: '140px' },
      type: { width: '15%', minWidth: '90px' },
      dueDate: { width: '20%', minWidth: '140px' },
      paidAtUtc: { width: '20%', minWidth: '140px' },
      currency: { width: '10%', minWidth: '75px' },
      status: { width: '10%', minWidth: '70px' },
      actions: { width: '5%', minWidth: '40px' }
    }

    const scaleFactor = {
      '3xl': 1,
      '2xl': 1,
      xl: 1,
      lg: 1,
      md: 1.3,
      sm: 1.2,
      xs: 1.2
    }

    const scale = scaleFactor[breakpoint] || 1

    return Object.fromEntries(
      Object.entries(baseConfig).map(([key, value]) => [
        key,
        {
          width: value.width,
          minWidth: `${parseInt(value.minWidth, 10) * scale}px`
        }
      ])
    )
  }

  const tableColumnWidth = useMemo(() => {
    return generateDynamicColumnWidths(breakpoint)
  }, [breakpoint])

  const columns: CustomColumn<BillingInvoiceItem>[] = useMemo(() => {
    return [
      {
        Header: t?.tableInformation?.createdAtUtc || '',
        accessor: 'createdAtUtc',
        Cell: ({ getValue }) => {
          return (
            <div className="flex flex-col flex-1">
              <Typography variant="text-sm-medium">
                {formatData(getValue('createdAtUtc'), lang)}
              </Typography>
            </div>
          )
        },
        ...tableColumnWidth.createdAtUtc
      },
      {
        Header: t?.tableInformation?.type || '',
        accessor: 'type',
        Cell: ({ getValue }) => {
          const value = getValue('type')
          const createdAtUtc = t?.mappingTypes?.[value] || {
            label: '',
            color: '',
            tooltip: ''
          }

          return (
            <div className="flex-1">
              <Badge
                size="sm"
                icon={InformationCircleIcon}
                className={createdAtUtc?.color}
                tooltip={createdAtUtc?.tooltip}
              >
                {createdAtUtc?.label}
              </Badge>
            </div>
          )
        },
        ...tableColumnWidth.type
      },
      {
        Header: t?.tableInformation?.dueDate || '',
        accessor: 'dueDate',
        Cell: ({ getValue }) => {
          return (
            <div className="flex flex-col flex-1">
              <Typography variant="text-sm-medium">
                {formatData(getValue('dueDate'), lang)}
              </Typography>
            </div>
          )
        },
        ...tableColumnWidth.dueDate
      },
      {
        Header: t?.tableInformation?.paidAtUtc || '',
        accessor: 'paidAtUtc',
        Cell: ({ getValue }) => {
          const value = getValue('paidAtUtc')
          if (!value) {
            return (
              <div className="flex items-center justify-center text-accent-900">
                -
              </div>
            )
          }
          return (
            <div className="flex flex-col flex-1">
              <Typography variant="text-sm-medium">
                {formatData(value, lang)}
              </Typography>
            </div>
          )
        },
        ...tableColumnWidth.paidAtUtc
      },
      {
        Header: t?.tableInformation?.currency || '',
        accessor: 'currency',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          return (
            <div className="flex flex-col flex-1">
              <Typography variant="text-sm-medium">
                {formatCurrency(
                  valuesOriginal.value,
                  valuesOriginal.currency === 'BRL' ? 'PtBr' : 'EnUs'
                )}
              </Typography>
            </div>
          )
        },
        ...tableColumnWidth.currency
      },
      {
        Header: t?.tableInformation?.status || '',
        accessor: 'value',
        Cell: (value) => {
          const paidAtUtc = value?.row?.original?.paidAtUtc
          const dueDate = value?.row?.original?.dueDate
          const currentDate = new Date()
          let statusLabel: string

          if (paidAtUtc) {
            statusLabel = 'Paid'
          } else if (!paidAtUtc && currentDate > new Date(dueDate)) {
            statusLabel = 'Overdue'
          } else {
            statusLabel = 'Pending'
          }

          const createdAtUtc = t?.mappingStatus?.[statusLabel] || {
            label: '',
            color: '',
            tooltip: ''
          }

          return (
            <div className="flex-1">
              <Badge
                size="sm"
                icon={InformationCircleIcon}
                className={createdAtUtc?.color}
                tooltip={createdAtUtc?.tooltip}
              >
                {createdAtUtc?.label}
              </Badge>
            </div>
          )
        },
        ...tableColumnWidth.status
      },
      {
        Header: t?.tableInformation?.actions || '',
        accessor: 'id',
        Cell: (value) => {
          const paidAtUtc = value?.row?.original?.paidAtUtc
          const shouldHaveNfe = value?.row?.original?.shouldHaveNfe
          const nfeCompletedAtUtc = value?.row?.original?.nfeCompletedAtUtc
          const menu: MenuItem[] = []

          if (!paidAtUtc) {
            menu.push({
              key: 'pay',
              label: t?.options?.pay
            })
          } else {
            menu.push({
              key: 'viewReceipt',
              label: t?.options?.viewReceipt
            })

            if (shouldHaveNfe) {
              menu.push({
                key: 'viewNfe',
                label: t?.options?.viewNfe,
                disabled: !nfeCompletedAtUtc
              })
            }
          }

          return (
            <Dropdown
              menu={menu}
              onSelect={async (state) => {
                if (state.key === 'viewReceipt') {
                  try {
                    const response = await mutateAsyncBillingGetLinkReceipt({
                      id: value?.row?.original?.id
                    })
                    window.open(response?.url as string, '_blank')
                    return t?.toasts?.successGetLinkCheckout
                  } catch {
                    return t?.toasts?.errorGetLinkCheckout
                  }
                }
                if (state.key === 'viewNfe') {
                  try {
                    const response = await mutateAsyncBillingGetLinkNfe({
                      id: value?.row?.original?.id
                    })
                    window.open(response?.url as string, '_blank')
                    return t?.toasts?.successGetLinkNfe
                  } catch {
                    return t?.toasts?.errorGetLinkNfe
                  }
                }
                if (state.key === 'pay') {
                  try {
                    const response = await mutateAsyncBillingGetPendingInvoice({
                      id: value?.row?.original?.id
                    })
                    window.open(response?.url as string, '_blank')
                    return t?.toasts?.successGetPendingInvoice
                  } catch {
                    return t?.toasts?.errorGetPendingInvoice
                  }
                }
              }}
            >
              <button className="flex items-center justify-center w-8 h-8 transition-all border-none rounded-full cursor-pointer bg-gray-50 hover:bg-gray-100">
                <EllipsisVerticalIcon className="w-5 h-5 text-secondary-700" />
              </button>
            </Dropdown>
          )
        },
        ...tableColumnWidth.actions
      }
    ]
  }, [
    lang,
    mutateAsyncBillingGetLinkNfe,
    mutateAsyncBillingGetLinkReceipt,
    mutateAsyncBillingGetPendingInvoice,
    t?.mappingStatus,
    t?.mappingTypes,
    t?.options?.pay,
    t?.options?.viewNfe,
    t?.options?.viewReceipt,
    t?.tableInformation?.actions,
    t?.tableInformation?.createdAtUtc,
    t?.tableInformation?.currency,
    t?.tableInformation?.dueDate,
    t?.tableInformation?.paidAtUtc,
    t?.tableInformation?.status,
    t?.tableInformation?.type,
    t?.toasts?.errorGetLinkCheckout,
    t?.toasts?.errorGetLinkNfe,
    t?.toasts?.errorGetPendingInvoice,
    t?.toasts?.successGetLinkCheckout,
    t?.toasts?.successGetLinkNfe,
    t?.toasts?.successGetPendingInvoice,
    tableColumnWidth.actions,
    tableColumnWidth.createdAtUtc,
    tableColumnWidth.currency,
    tableColumnWidth.dueDate,
    tableColumnWidth.paidAtUtc,
    tableColumnWidth.status,
    tableColumnWidth.type
  ])

  if (isAdmin) {
    return (
      <div className="flex flex-col w-full h-full max-w-5xl min-h-full gap-4">
        <div className="flex flex-col">
          <Typography
            variant="title-2xl-medium"
            className="text-gray-700 dark:text-gray-700"
            type="h1"
          >
            {t?.title}
          </Typography>
          <Typography
            variant="text-sm-regular"
            className="text-gray-700 dark:text-gray-700"
            type="p"
          >
            {t?.description}
          </Typography>
        </div>
        <div className="flex flex-col w-full h-full gap-6 p-6 bg-white rounded-lg shadow dark:bg-gray-100 dark:text-black">
          {isLoadingBillingInvoices ||
          (isSuccessBillingInvoices &&
            dataBillingInvoices &&
            dataBillingInvoices?.items?.length > 0) ? (
            <div className="flex items-center justify-center">
              <Table<BillingInvoiceItem>
                columns={columns}
                data={dataBillingInvoices?.items || []}
                fetchData={setPageNumber}
                canPreviousPage={pageNumber > 0}
                canNextPage={
                  (dataBillingInvoices?.pagination &&
                    dataBillingInvoices?.pagination?.totalItems /
                      dataBillingInvoices?.pagination?.pageSize >
                      pageNumber + 1) ||
                  false
                }
                pagination={dataBillingInvoices?.pagination}
                isLoading={isLoadingBillingInvoices}
                pageSize={pageSize}
                pageNumber={pageNumber}
                setPageSize={setPageSize}
                setPageNumber={setPageNumber}
              />
            </div>
          ) : (
            <EmptyStateTable type="billingPayments" />
          )}
        </div>
      </div>
    )
  }
  return null
}

export default BillingPaymentsPage
