import { Dispatch, SetStateAction } from 'react'
import { TypeSignerActionAccount } from '@/types/global'
import { CustomColumn } from '@/types/react-table-config'
import {
  HistorySignerActionAccounts,
  SignerActionAccountItem
} from '@/hooks/api/ecm/useHistoryApi/useHistoryApi.types'
import { SelectGroupOption, SelectOption } from '@/ui/atoms'
import { DataCardType, DataListType } from '@/ui/molecules'

export type StatusType = TypeSignerActionAccount

export type MappingStatusType = {
  label: string
  color: string
}

export type DataModalType = {
  isOpen: boolean
  id: string | null
}

export enum FiltersKeyHistory {
  USER = 'user'
}

export type FiltersHistoryType = {
  user?: string | null
}

export type UseHistoryDataResponse = {
  pageNumber: number
  setPageNumber: React.Dispatch<React.SetStateAction<number>>
  pageSize: number
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  currentDataTable: {
    data: HistorySignerActionAccounts | null | undefined
    isLoading: boolean
    isSuccess: boolean
  }
  columns: CustomColumn<SignerActionAccountItem>[]
  optionsUsers: SelectGroupOption[] | SelectOption[]
  dataModal: DataModalType
  setDataModal: Dispatch<SetStateAction<DataModalType>>
  isLoadingSignerActionDetails: boolean
  cardsDetails: DataCardType[] | null
  listDetails: DataListType[] | null
  filters: FiltersHistoryType | null
  preparedFilters: FiltersHistoryType | null
  setPreparedFilters: (filters: FiltersHistoryType) => void
  handleApplyFilters: (filters: FiltersHistoryType) => void
}
