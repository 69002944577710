import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import {
  NotificationsResponse,
  UpdateNotificationsRequest
} from './useNotificationsApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'

const useNotificationsApi = () => {
  const { handleApi, queryConfig } = useApi()
  const queryClient = useQueryClient()

  const useGetNotifications = () => {
    return useQuery({
      queryKey: [QueryKeys.AccountSettingsNotifications],
      queryFn: () =>
        handleApi<void, NotificationsResponse>(
          `/administration/accounts/settings/notifications`,
          'GET'
        ),
      ...queryConfig
    })
  }

  const useUpdateNotifications = () => {
    return useMutation({
      mutationFn: (data: UpdateNotificationsRequest) =>
        handleApi<UpdateNotificationsRequest, void>(
          `/administration/accounts/settings/notifications`,
          'PUT',
          data
        ),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.AccountSettingsNotifications]
        })
      }
    })
  }

  return {
    useGetNotifications,
    useUpdateNotifications
  }
}

export default useNotificationsApi
