import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import useApi from '@/hooks/useApi'
import { useAuth } from '@/providers/Auth'
import {
  GetAddressByCepResponse,
  GetCitiesResponse,
  GetCountriesResponse,
  GetStatesResponse
} from './useAddressApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'

const useAddressApi = () => {
  const { handleApi, queryConfig } = useApi()
  const { authMetadata } = useAuth()

  const useGetCountries = () => {
    return useQuery({
      queryKey: [QueryKeys.Countries],
      queryFn: () =>
        handleApi<void, GetCountriesResponse>(`/platform/countries`, 'GET'),
      ...queryConfig,
      enabled: !!authMetadata?.accessToken
    })
  }

  const useGetStates = (countryId?: string) => {
    return useQuery({
      queryKey: [QueryKeys.States, countryId],
      queryFn: () =>
        handleApi<void, GetStatesResponse>(
          `/platform/countries/${countryId}/states`,
          'GET'
        ),
      ...queryConfig,
      enabled: !!authMetadata?.accessToken && !!countryId
    })
  }

  const useGetCities = (countryId?: string, stateId?: string) => {
    return useQuery({
      queryKey: [QueryKeys.Cities, countryId, stateId],
      queryFn: () =>
        handleApi<void, GetCitiesResponse>(
          `/platform/countries/${countryId}/states/${stateId}/cities`,
          'GET'
        ),
      ...queryConfig,
      enabled: !!authMetadata?.accessToken && !!countryId && !!stateId
    })
  }

  const useGetAddressByCep = (cep: string) => {
    return useQuery({
      queryKey: [QueryKeys.AddressByCep, cep],
      queryFn: async () => {
        try {
          // Primeiro tenta a API do OpenCep
          const responseOpenCep = await axios.get<GetAddressByCepResponse>(
            `https://opencep.com/v1/${cep}`
          )
          return responseOpenCep.data
        } catch (error) {
          // Se falhar, tenta a API do ViaCep
          const responseViaCep = await axios.get<GetAddressByCepResponse>(
            `https://viacep.com.br/ws/${cep}/json/`
          )
          return responseViaCep.data
        }
      },
      ...queryConfig,
      enabled: !!cep && cep.length === 8
    })
  }

  return {
    useGetCountries,
    useGetStates,
    useGetCities,
    useGetAddressByCep
  }
}

export default useAddressApi
