import { useMutation, useQueryClient } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import { ChangeUserPasswordRequest } from './usePasswordApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'

const usePasswordApi = () => {
  const { handleApi, queryConfig } = useApi()
  const queryClient = useQueryClient()

  const useChangeUserPassword = () =>
    useMutation({
      mutationFn: (data: ChangeUserPasswordRequest) =>
        handleApi<ChangeUserPasswordRequest, void>(
          `/administration/user/password`,
          'PATCH',
          data
        ),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ProfilePassword]
        })
      }
    })

  return {
    useChangeUserPassword
  }
}

export default usePasswordApi
