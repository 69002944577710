import useTranslation from '@/hooks/useTranslation'
import { DataTableTemplate } from '@/templates/index'
import { Table } from '@/organisms/index'
import {
  DrawerPurchaseUserLicenses,
  EmptyStateTable,
  FilterDropdown,
  ModalConfirm,
  ModalInviteUser,
  ModalNoLicensedUsers,
  ModalPendingInvoices,
  ModalUpdateRoleUser
} from '@/molecules/index'
import { Typography } from '@/atoms/index'
import withRole from '@/hoc/withRole'
import { ItemUserInvite } from '@/hooks/api/administration/useInviteApi/useInviteApi.types'
import { ItemUserAccount } from '@/hooks/api/administration/useUserAccountApi/useUserAccountApi.types'
import useUsersData from '@/hooks/useUsersData/useUsersData'
import { FiltersKeyUsers } from '@/hooks/useUsersData/useUsersData.types'
import { useAuth } from '@/providers'
import { Button } from '@/ui/atoms/shadcn'

const UsersPage = () => {
  const { t, isReady } = useTranslation('usersPage')
  const { isAdmin } = useAuth()

  const {
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    currentDataTable,
    columns,
    options,
    modalUpdateRoleData,
    setModalUpdateRoleData,
    filters,
    preparedFilters,
    setPreparedFilters,
    handleApplyFilters,
    modalInviteUser,
    setModalInviteUser,
    modalNoLicensedUsers,
    setModalNoLicensedUsers,
    drawerPurchaseUserLicenses,
    setDrawerPurchaseUserLicenses,
    handleAction,
    modalPendingInvoices,
    setModalPendingInvoices,
    pendingInvoiceId,
    resetPagination,
    dataCurrentSubscription,
    refetchCurrentSubscription,
    modalConfirmAction,
    setModalConfirmAction,
    isLoadingModalConfirmAction
  } = useUsersData()

  const breadcrumbs = [
    { name: t?.breadcrumbs?.settings, href: null, current: false },
    { name: t?.breadcrumbs?.users, href: '/users', current: true }
  ]

  return (
    <DataTableTemplate
      header={
        <div className="flex flex-col gap-4 sm:flex-row sm:gap-2 sm:justify-between sm:items-center">
          <div className="flex flex-col w-full gap-1">
            <Typography
              variant="title-2xl-medium"
              className="text-gray-700 dark:text-gray-700"
              classNameSkeleton="w-40"
              showSkeleton={!isReady}
              type="h1"
            >
              {t?.title}
            </Typography>
            <Typography
              variant="text-sm-regular"
              className="text-gray-700 dark:text-gray-700"
              classNameSkeleton="w-60"
              showSkeleton={!isReady}
              type="p"
            >
              {t?.description}
            </Typography>
          </div>
          <div className="flex flex-row items-end justify-end gap-2">
            <FilterDropdown
              filtersSchema={[
                {
                  id: FiltersKeyUsers.TYPE_VIEW,
                  type: 'select',
                  label: t?.selectLabelUsers,
                  placeholder: t?.selectPlaceholder,
                  initialValue: 'active',
                  options: options,
                  showSkeleton: !isReady || !filters?.typeView,
                  hidden: !isAdmin,
                  isActive: Boolean(
                    isAdmin &&
                      filters?.typeView !== 'active' &&
                      !!filters?.typeView
                  )
                }
              ]}
              values={{
                typeView: preparedFilters?.typeView || 'active'
              }}
              setPreparedValues={setPreparedFilters}
              onFilter={(filters, reset) => {
                if (reset) {
                  resetPagination()
                }
                handleApplyFilters({
                  typeView: filters?.typeView as
                    | 'active'
                    | 'inactive'
                    | 'invite'
                })
              }}
              showSkeleton={!isReady}
            />
            <Button
              onClick={() => setModalInviteUser({ isOpen: true, data: null })}
              showSkeleton={!isReady}
              timeoutSkeleton={500}
              variant="secondary"
              className="w-full h-10 px-6 md:w-auto lg:px-6"
            >
              {t?.inviteUser}
            </Button>
          </div>
        </div>
      }
      breadcrumbs={breadcrumbs}
      showSkeleton={!isReady}
    >
      {currentDataTable?.isLoading ||
      (currentDataTable?.isSuccess &&
        currentDataTable?.data &&
        currentDataTable?.data?.items?.length > 0) ? (
        <div className="flex items-center justify-center">
          <Table<ItemUserAccount | ItemUserInvite>
            columns={columns}
            data={currentDataTable?.data?.items || []}
            fetchData={setPageNumber}
            canPreviousPage={pageNumber > 0}
            canNextPage={
              (currentDataTable?.data?.pagination &&
                currentDataTable?.data?.pagination?.totalItems /
                  currentDataTable?.data?.pagination?.pageSize >
                  pageNumber + 1) ||
              false
            }
            pagination={currentDataTable?.data?.pagination}
            isLoading={currentDataTable?.isLoading}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageSize={setPageSize}
            setPageNumber={setPageNumber}
          />
        </div>
      ) : (
        <EmptyStateTable
          type={currentDataTable?.type === 'users' ? 'users' : 'usersInvite'}
        />
      )}
      <ModalUpdateRoleUser
        id={modalUpdateRoleData.id as string}
        role={modalUpdateRoleData.role as string}
        isOpen={modalUpdateRoleData.isOpen}
        onClose={() =>
          setModalUpdateRoleData({ id: null, isOpen: false, role: null })
        }
      />
      <ModalInviteUser
        isOpen={modalInviteUser?.isOpen}
        onClose={() => setModalInviteUser({ isOpen: false, data: null })}
        setModalNoLicensedUsers={(isOpen) =>
          setModalNoLicensedUsers({
            action: 'invite',
            isOpen
          })
        }
        setModalPendingInvoices={setModalPendingInvoices}
        setModalInviteUser={setModalInviteUser}
        dataUser={modalInviteUser?.data}
        dataCurrentSubscription={dataCurrentSubscription}
      />
      <ModalNoLicensedUsers
        isOpen={modalNoLicensedUsers?.isOpen}
        action={modalNoLicensedUsers?.action}
        onClose={() =>
          setModalNoLicensedUsers({
            isOpen: false,
            action: null
          })
        }
        onBuyMoreLicenses={() => {
          setDrawerPurchaseUserLicenses(true)
          setModalNoLicensedUsers({
            ...modalNoLicensedUsers,
            isOpen: false
          })
        }}
      />
      <DrawerPurchaseUserLicenses
        isOpen={drawerPurchaseUserLicenses}
        onClose={() => setDrawerPurchaseUserLicenses(false)}
        onFinished={(status) => {
          if (status && modalNoLicensedUsers?.action === 'invite') {
            setModalInviteUser({ ...modalInviteUser, isOpen: true })
          }
          if (status && modalNoLicensedUsers?.action === 'activate') {
            handleAction('activate', modalNoLicensedUsers?.id as string)
          }
        }}
        dataCurrentSubscription={dataCurrentSubscription}
        refetchCurrentSubscription={refetchCurrentSubscription}
      />
      {pendingInvoiceId && (
        <ModalPendingInvoices
          isOpen={modalPendingInvoices}
          onClose={() => setModalPendingInvoices(false)}
          pendingInvoiceId={pendingInvoiceId}
        />
      )}
      {modalConfirmAction?.isOpen && (
        <ModalConfirm
          isOpen={modalConfirmAction?.isOpen}
          onClose={() =>
            setModalConfirmAction({
              isOpen: false,
              action: null,
              id: null
            })
          }
          loadingSubmit={isLoadingModalConfirmAction}
          onConfirm={async () => {
            try {
              if (modalConfirmAction?.action && modalConfirmAction?.id) {
                await handleAction(
                  modalConfirmAction?.action,
                  modalConfirmAction?.id
                )
                setModalConfirmAction({
                  isOpen: false,
                  action: null,
                  id: null
                })
              }
            } catch {
              setModalConfirmAction({
                isOpen: false,
                action: null,
                id: null
              })
            }
          }}
          title={t?.modalConfirm?.title(modalConfirmAction?.action)}
          description={t?.modalConfirm?.description(modalConfirmAction?.action)}
          cancelButtonText={t?.modalConfirm?.cancelButtonText}
          confirmButtonText={t?.modalConfirm?.confirmButtonText(
            modalConfirmAction?.action
          )}
        />
      )}
    </DataTableTemplate>
  )
}

export default withRole(UsersPage, 'Administrator')
