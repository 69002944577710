import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import { UserInviteResponse } from './useInviteApi.types'
import { IUsersInvite } from '@/hooks/api/administration/useInviteApi/useInviteApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'
import { useAuth } from '@/providers'

const useInviteApi = () => {
  const { handleApi, queryConfig } = useApi()
  const queryClient = useQueryClient()
  const { authMetadata } = useAuth()

  const useUserInvite = (pageNumber = 0, pageSize = 10) =>
    useQuery({
      queryKey: [QueryKeys.UserInvite, pageNumber, pageSize],
      queryFn: () =>
        handleApi<void, UserInviteResponse>(
          `/administration/user/invites?pageNumber=${
            pageNumber + 1
          }&pageSize=${pageSize}`,
          'GET'
        ),
      ...queryConfig,
      enabled: !!authMetadata?.accessToken
    })

  const useInvite = (inviteId: string) =>
    useQuery({
      queryKey: [QueryKeys.Invite, inviteId],
      queryFn: () =>
        handleApi<void, IUsersInvite>(
          `/administration/user/invites/${inviteId}`,
          'GET'
        ),
      ...queryConfig,
      enabled: !!authMetadata?.accessToken
    })

  const useAcceptInvite = () =>
    useMutation({
      mutationFn: (inviteId: string) => {
        return handleApi<void, void>(
          `/administration/user/invites/${inviteId}/accept`,
          'POST'
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.UserInvite]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ListNotifications]
        })
      }
    })

  const useRejectInvite = () =>
    useMutation({
      mutationFn: (inviteId: string) => {
        return handleApi<void, void>(
          `/administration/user/invites/${inviteId}/reject`,
          'POST'
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.UserInvite]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ListNotifications]
        })
      }
    })

  return {
    useUserInvite,
    useInvite,
    useAcceptInvite,
    useRejectInvite
  }
}

export default useInviteApi
