import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useAuth } from '@/providers'

const AccountIndexPage = () => {
  const { isAdmin } = useAuth()
  const { push } = useRouter()

  useEffect(() => {
    if (isAdmin) {
      push('/account/notifications')
    } else {
      push('/unauthorized')
    }
  }, [isAdmin, push])

  return null
}

export default AccountIndexPage
