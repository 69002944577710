import { useMemo, useState } from 'react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { formatData } from '@/utils/date'
import { useAuth } from '@/providers/Auth'
import { useLocale } from '@/providers/Locale'
import { CustomColumn } from '@/types/react-table-config'
import { Avatar, Typography } from '@/atoms/index'
import {
  DataModalType,
  FiltersAuditsType,
  UseAuditsDataResponse
} from './useAuditsData.types'
import { useCreateOptionsUser } from '../useCreateOptionsUser'
import { useTable } from '../useTable'
import useTableFilters from '../useTableFilters'
import useTranslation from '../useTranslation'
import { useViewport } from '../useViewport'
import { IBreakpoint } from '../useViewport/useViewport'
import useAuditApi from '@/hooks/api/report/useAuditApi/useAuditApi'
import { AuditSignerActionAccountItem } from '@/hooks/api/report/useAuditApi/useAuditApi.types'
import { Badge, Button } from '@/ui/atoms/shadcn'

const useAuditsData = (): UseAuditsDataResponse => {
  const { t } = useTranslation('audits')
  const { isAdmin, userContext } = useAuth()
  const { pageNumber, setPageNumber, pageSize, setPageSize } = useTable()
  const { breakpoint } = useViewport()
  const { lang } = useLocale()
  const {
    filters,
    handleApplyFilters,
    setPreparedFilters,
    preparedFilters,
    isReady
  } = useTableFilters<FiltersAuditsType>({})
  const {
    useAuditsSignerActionsOwners,
    useAuditsSignerActionAllAccounts,
    useAuditsSignerActionUserAccounts,
    useAuditDetails
  } = useAuditApi()
  const [dataModal, setDataModal] = useState<DataModalType>({
    isOpen: false,
    id: null
  })

  const fetchAll = useMemo(
    () => isAdmin && filters?.user === 'all',
    [isAdmin, filters?.user]
  )
  const fetchMe = useMemo(
    () =>
      filters?.user === userContext?.userAccountInformation?.id ||
      !filters?.user,
    [filters?.user, userContext]
  )

  const { data: dataSignerActionsOwners } =
    useAuditsSignerActionsOwners(!!isAdmin)
  const {
    data: dataSignerActionAccounts,
    isSuccess: isSuccessSignerActionAccounts,
    isLoading: isLoadingSignerActionAccounts
  } = useAuditsSignerActionAllAccounts(
    (fetchAll && isReady) ?? false,
    pageNumber,
    pageSize
  )
  const {
    data: dataAuditsSignerActionUserAccounts,
    isLoading: isLoadingAuditsSignerActionUserAccounts,
    isSuccess: isSuccessAuditsSignerActionUserAccounts,
    isFetching: isFetchingAuditsSignerActionUserAccounts
  } = useAuditsSignerActionUserAccounts(
    fetchMe ? null : (filters?.user as string),
    !fetchAll && isReady,
    pageNumber,
    pageSize
  )
  const { data: dataAuditDetails, isLoading: isLoadingAuditDetails } =
    useAuditDetails(dataModal.isOpen && dataModal.id ? dataModal.id : null)

  const currentDataTable = useMemo(() => {
    if (filters?.user === 'all') {
      return {
        data: dataSignerActionAccounts,
        isLoading: isLoadingSignerActionAccounts,
        isSuccess: isSuccessSignerActionAccounts
      }
    }
    return {
      data: dataAuditsSignerActionUserAccounts,
      isLoading:
        isLoadingAuditsSignerActionUserAccounts ||
        isFetchingAuditsSignerActionUserAccounts,
      isSuccess: isSuccessAuditsSignerActionUserAccounts
    }
  }, [
    dataAuditsSignerActionUserAccounts,
    dataSignerActionAccounts,
    isFetchingAuditsSignerActionUserAccounts,
    isLoadingAuditsSignerActionUserAccounts,
    isLoadingSignerActionAccounts,
    isSuccessAuditsSignerActionUserAccounts,
    isSuccessSignerActionAccounts,
    filters?.user
  ])

  const optionsUsers = useCreateOptionsUser({
    users: dataSignerActionsOwners?.items as any[]
  })

  const generateDynamicColumnWidths = (breakpoint: IBreakpoint) => {
    const baseConfig = {
      create: { width: '25%', minWidth: '100px' },
      onBehalfOfName: { width: '25%', minWidth: '100px' },
      operation: { width: '25%', minWidth: '100px' },
      id: { width: '15%', minWidth: '80px' },
      action: { width: '10%', minWidth: '50px' }
    }

    const scaleFactor = {
      '3xl': 2,
      '2xl': 1.3,
      xl: 1.2,
      lg: 1.5,
      md: 1.5,
      sm: 1.5,
      xs: 1.5
    }

    const scale = scaleFactor[breakpoint] || 1

    const scaledConfig = Object.fromEntries(
      Object.entries(baseConfig).map(([key, value]) => [
        key,
        {
          width: value.width,
          minWidth: `${parseInt(value.minWidth, 10) * scale}px`
        }
      ])
    )

    return scaledConfig
  }

  const tableColumnWidth = useMemo(() => {
    return generateDynamicColumnWidths(breakpoint)
  }, [breakpoint])

  const columns: CustomColumn<AuditSignerActionAccountItem>[] = useMemo(() => {
    return [
      {
        Header: t?.tableInformation?.create || '',
        accessor: 'createdByName',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          if (!valuesOriginal.createdByName) {
            return (
              <Typography variant="text-xs-regular">
                {formatData(valuesOriginal.createdAtUtc, lang)}
              </Typography>
            )
          }
          return (
            <div className="flex items-center gap-1 lg:gap-2">
              <Avatar
                imgSrc={valuesOriginal.createdByAvatar}
                name={valuesOriginal.createdByName}
              />
              <div className="flex flex-col flex-1">
                <Typography variant="text-sm-medium">
                  {valuesOriginal.createdByName}
                </Typography>
                <Typography variant="text-xs-regular">
                  {formatData(valuesOriginal.createdAtUtc, lang)}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidth.create
      },
      {
        Header: t?.tableInformation?.onBehalfOfName || '',
        accessor: 'onBehalfOfName',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          return (
            <div className="flex items-center gap-1 lg:gap-2">
              <Avatar
                imgSrc={valuesOriginal.onBehalfOfAvatar}
                name={valuesOriginal.onBehalfOfName}
              />

              <div className="flex flex-col flex-1">
                <Typography variant="text-sm-medium">
                  {valuesOriginal.onBehalfOfName}
                </Typography>
                <Typography variant="text-sm-medium">
                  {valuesOriginal.onBehalfOfEmail}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidth.onBehalfOfName
      },
      {
        Header: t?.tableInformation?.operation || '',
        accessor: 'operation',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          const operation = t?.mappingOperations?.[
            valuesOriginal?.operation
          ] || {
            label: '',
            color: '',
            tooltip: ''
          }

          return (
            <div className="flex-1">
              <Badge
                size="sm"
                icon={InformationCircleIcon}
                className={operation?.color}
                tooltip={operation?.label}
              >
                {operation?.label}
              </Badge>
            </div>
          )
        },
        ...tableColumnWidth.operation
      },
      {
        Header: t?.tableInformation?.id || '',
        accessor: 'id',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          return (
            <div className="flex flex-col flex-1 gap-y-1">
              <div className="flex-1">
                <Badge
                  size="sm"
                  icon={InformationCircleIcon}
                  className="text-gray-600 bg-gray-200"
                  tooltip={valuesOriginal.id}
                >
                  {t?.register}
                </Badge>
              </div>
              <div className="flex-1">
                <Badge
                  size="sm"
                  icon={InformationCircleIcon}
                  className="text-gray-600 bg-gray-200"
                  tooltip={valuesOriginal.aggregateRootId}
                >
                  {t?.aggregationRoot}
                </Badge>
              </div>
            </div>
          )
        },
        ...tableColumnWidth.id
      },
      // {
      //   Header: t?.tableInformation?.location || '',
      //   accessor: 'location',
      //   Cell: (value) => {
      //     const valuesOriginal = value?.row?.original

      //     return (
      //       <div className="flex flex-col flex-1">
      //         <Typography variant="text-sm-medium">
      //           {t?.ip}
      //           <span className="font-normal">{valuesOriginal.ip}</span>
      //         </Typography>
      //         {valuesOriginal.location && (
      //           <Typography variant="text-sm-medium">
      //             {t?.nexTo}
      //             {valuesOriginal.location}
      //           </Typography>
      //         )}
      //         {valuesOriginal.userAgent && (
      //           <Typography variant="text-sm-medium">
      //             {t?.userAgent}
      //             {valuesOriginal.userAgent}
      //           </Typography>
      //         )}
      //       </div>
      //     )
      //   },
      //   ...tableColumnWidth.location
      // },
      {
        Header: t?.tableInformation?.action || '',
        accessor: 'id',
        Cell: ({ getValue }) => (
          <Button
            variant="outlineSecondary"
            onClick={() => {
              setDataModal({
                isOpen: true,
                id: getValue('id')
              })
            }}
          >
            {t?.tableInformation?.view}
          </Button>
        ),
        ...tableColumnWidth.action
      }
    ]
  }, [
    lang,
    t?.aggregationRoot,
    t?.mappingOperations,
    t?.register,
    t?.tableInformation?.action,
    t?.tableInformation?.create,
    t?.tableInformation?.id,
    t?.tableInformation?.onBehalfOfName,
    t?.tableInformation?.operation,
    t?.tableInformation?.view,
    tableColumnWidth.action,
    tableColumnWidth.create,
    tableColumnWidth.id,
    tableColumnWidth.onBehalfOfName,
    tableColumnWidth.operation
  ])

  const cardsDetails = useMemo(() => {
    if (!dataAuditDetails) {
      return null
    }
    return [
      {
        title: t?.tableInformation?.create,
        avatar: {
          imgSrc: dataAuditDetails?.createdByAvatar,
          name: dataAuditDetails?.createdByName
        },
        name: dataAuditDetails?.createdByName,
        email: dataAuditDetails?.createdByEmail,
        date: formatData(dataAuditDetails?.createdAtUtc, lang)
      },
      {
        title: t?.tableInformation?.onBehalfOfName,
        avatar: {
          imgSrc: dataAuditDetails?.onBehalfOfAvatar,
          name: dataAuditDetails?.onBehalfOfName
        },
        name: dataAuditDetails?.onBehalfOfName,
        email: dataAuditDetails?.onBehalfOfEmail
      }
    ]
  }, [
    dataAuditDetails,
    lang,
    t?.tableInformation?.create,
    t?.tableInformation?.onBehalfOfName
  ])

  const listDetails = useMemo(() => {
    if (!dataAuditDetails) {
      return null
    }
    return [
      {
        title: t?.modalViewDetails?.label.ip,
        value: dataAuditDetails?.ip
      },
      {
        title: t?.modalViewDetails?.label.location,
        value: dataAuditDetails?.location
      },
      {
        title: t?.modalViewDetails?.label.userAgent,
        value: dataAuditDetails?.userAgent
      },
      {
        title: t?.modalViewDetails?.label.aggregateRootId,
        value: dataAuditDetails?.aggregateRootId
      },
      {
        title: t?.tableInformation?.operation,
        value: t?.mappingOperations?.[dataAuditDetails?.operation]?.label
      }
    ]
  }, [
    dataAuditDetails,
    t?.mappingOperations,
    t?.modalViewDetails?.label.aggregateRootId,
    t?.modalViewDetails?.label.ip,
    t?.modalViewDetails?.label.location,
    t?.modalViewDetails?.label.userAgent,
    t?.tableInformation?.operation
  ])

  return {
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    currentDataTable,
    columns,
    optionsUsers,
    dataModal,
    setDataModal,
    isLoadingAuditDetails,
    cardsDetails,
    listDetails,
    filters,
    preparedFilters,
    setPreparedFilters,
    handleApplyFilters
  }
}

export default useAuditsData
