import { CustomColumn } from '@/types/react-table-config'
import {
  RecycleBinEnvelopeAccountItem,
  RecycleBinEnvelopesListAccountsResponse,
  RecycleBinTemplateAccountItem,
  RecycleBinTemplateListAccountsResponse
} from '@/hooks/api/ecm/useRecycleBinApi/useRecycleBinApi.types'
import { SelectGroupOption, SelectOption } from '@/ui/atoms'

export type StatusType = RecycleBinEnvelopeAccountItem['status']

export type MappingStatusType = {
  label: string
  color: string
}

export type FiltersRecycleBinType = {
  user?: string | null
  entity: 'envelopes' | 'templates'
}

export enum FiltersKeyRecycleBin {
  USER = 'user',
  ENTITY = 'entity'
}

export type UseRecycleBinDataResponse = {
  pageNumber: number
  setPageNumber: React.Dispatch<React.SetStateAction<number>>
  pageSize: number
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  currentDataTable: {
    data:
      | RecycleBinEnvelopesListAccountsResponse
      | RecycleBinTemplateListAccountsResponse
      | null
      | undefined
    isLoading: boolean
    isSuccess: boolean
  }
  columns: CustomColumn<
    RecycleBinEnvelopeAccountItem | RecycleBinTemplateAccountItem
  >[]
  optionsUser: SelectGroupOption[] | SelectOption[]
  optionsEntity: SelectOption[]
  filters: FiltersRecycleBinType | null
  preparedFilters: FiltersRecycleBinType | null
  setPreparedFilters: (filters: FiltersRecycleBinType) => void
  handleApplyFilters: (filters: Partial<FiltersRecycleBinType>) => void
  restoreModalData: RestoreModalDataType
  setRestoreModalData: React.Dispatch<
    React.SetStateAction<RestoreModalDataType>
  >
  handleVaultRestore: ({ vaultId }: { vaultId: string }) => void
  isLoadingRestore: boolean
}

export type RestoreModalDataType = {
  id: string | null
  open: boolean
}
