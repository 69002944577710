import React from 'react'
import { useRouter } from 'next/router'

const BillingErrorPage: React.FC = () => {
  const { query } = useRouter()

  return (
    <div>
      <h1>BillingErrorPage {query.page}</h1>
    </div>
  )
}

export default BillingErrorPage
