import React, { useMemo } from 'react'
import { useReviewEnvelope } from '@/providers/ReviewEnvelope'
import {
  SectionReviewFeedback,
  SectionReviewSignDocument,
  SectionReviewValidationMfa
} from '@/organisms/index'

const ReviewEnvelopePage: React.FC = () => {
  const { currentSection } = useReviewEnvelope()

  const sectionsReview = useMemo(() => {
    return {
      signDocument: <SectionReviewSignDocument />,
      validationMFA: <SectionReviewValidationMfa />,
      feedbackSigned: <SectionReviewFeedback status="signed" />,
      feedbackRejected: <SectionReviewFeedback status="rejected" />,
      feedbackFailed: <SectionReviewFeedback status="failed" />,
      feedbackEditing: <SectionReviewFeedback status="editing" />
    }
  }, [])

  return sectionsReview[currentSection]
}

export default ReviewEnvelopePage
