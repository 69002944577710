import useTranslation from '@/hooks/useTranslation'
import { useAuth } from '@/providers/Auth'
import { DataTableTemplate } from '@/templates/index'
import { Table } from '@/organisms/index'
import {
  EmptyStateTable,
  FilterDropdown,
  ModalViewTableData
} from '@/molecules/index'
import { Typography } from '@/atoms/index'
import { SignerActionAccountItem } from '@/hooks/api/ecm/useHistoryApi/useHistoryApi.types'
import useHistoryData from '@/hooks/useHistoryData/useHistoryData'
import { FiltersKeyHistory } from '@/hooks/useHistoryData/useHistoryData.types'

const HistoryPage = () => {
  const { t, isReady } = useTranslation('history')
  const { isAdmin, userContext } = useAuth()

  const {
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    currentDataTable,
    columns,
    optionsUsers,
    dataModal,
    isLoadingSignerActionDetails,
    cardsDetails,
    listDetails,
    setDataModal,
    filters,
    handleApplyFilters,
    preparedFilters,
    setPreparedFilters
  } = useHistoryData()

  const breadcrumbs = [
    { name: t?.breadcrumbs?.reports, href: null, current: false },
    { name: t?.breadcrumbs?.history, href: '/history', current: true }
  ]

  return (
    <DataTableTemplate
      header={
        <div className="flex flex-col gap-4 sm:flex-row sm:gap-2 sm:justify-between sm:items-center">
          <div className="flex flex-col w-full gap-1">
            <Typography
              variant="title-2xl-medium"
              className="text-gray-700 dark:text-gray-700"
              classNameSkeleton="w-40"
              showSkeleton={!isReady}
              type="h1"
            >
              {t?.title}
            </Typography>
            <Typography
              variant="text-sm-regular"
              className="text-gray-700 dark:text-gray-700"
              classNameSkeleton="w-60"
              showSkeleton={!isReady}
              type="p"
            >
              {t?.description}
            </Typography>
          </div>
          {isAdmin && (
            <div className="flex items-center justify-end w-full sm:w-fit">
              <FilterDropdown
                filtersSchema={[
                  {
                    id: FiltersKeyHistory.USER,
                    type: 'select',
                    label: t?.selectLabelUser,
                    initialValue: userContext?.userAccountInformation?.id,
                    options: optionsUsers,
                    showSkeleton: !isReady,
                    hidden: !isAdmin,
                    isActive: Boolean(
                      isAdmin &&
                        !!filters?.user &&
                        filters?.user !==
                          userContext?.userAccountInformation?.id
                    )
                  }
                ]}
                values={{
                  user:
                    (preparedFilters?.user as string) ||
                    userContext?.userAccountInformation?.id
                }}
                setPreparedValues={setPreparedFilters}
                onFilter={async (filters) => {
                  handleApplyFilters({
                    user:
                      filters?.user === userContext?.userAccountInformation?.id
                        ? null
                        : filters?.user
                  })
                }}
                showSkeleton={!isReady}
              />
            </div>
          )}
        </div>
      }
      breadcrumbs={breadcrumbs}
      showSkeleton={!isReady}
    >
      {currentDataTable?.isLoading ||
      (currentDataTable?.isSuccess &&
        currentDataTable?.data &&
        currentDataTable?.data?.items?.length > 0) ? (
        <div className="flex items-center justify-center">
          <Table<SignerActionAccountItem>
            columns={columns}
            data={currentDataTable?.data?.items || []}
            fetchData={setPageNumber}
            canPreviousPage={pageNumber > 0}
            canNextPage={
              (currentDataTable?.data?.pagination &&
                currentDataTable?.data?.pagination?.totalItems /
                  currentDataTable?.data?.pagination?.pageSize >
                  pageNumber + 1) ||
              false
            }
            pagination={currentDataTable?.data?.pagination}
            isLoading={currentDataTable?.isLoading}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageSize={setPageSize}
            setPageNumber={setPageNumber}
          />
        </div>
      ) : (
        <EmptyStateTable type="history" />
      )}
      <ModalViewTableData
        isOpen={dataModal.isOpen}
        onClose={() => setDataModal({ isOpen: false, id: null })}
        title={t?.modalViewDetails?.title}
        dataCards={cardsDetails}
        dataList={listDetails}
        isLoading={isLoadingSignerActionDetails || !isReady}
      />
    </DataTableTemplate>
  )
}

export default HistoryPage
