import { useMemo } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'
import useTranslation from '@/hooks/useTranslation'
import usePasswordApi from '@/hooks/api/administration/profile/user/password/usePasswordApi'
import { showToast } from '@/ui/atoms/index'
import { Button } from '@/ui/atoms/shadcn'
import { Form, PasswordRequirements, RenderFormField } from '@/ui/molecules'
import { SettingsTemplate } from '@/ui/templates'

type ChangeUserPassword = {
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
}

const ProfileChangePasswordPage = () => {
  const { t, isReady } = useTranslation(
    ['profileChangePassword', 'validations'],
    true
  )
  const { useChangeUserPassword } = usePasswordApi()
  const {
    mutateAsync: mutateAsyncChangeUserPassword,
    isLoading: isLoadingChangeUserPassword
  } = useChangeUserPassword()

  const formSchema = z.object({
    currentPassword: z
      .string()
      .min(1, { message: t?.requiredField })
      .min(8, { message: t?.errorMinLength?.(8) })
      .max(100, { message: t?.errorMaxLength?.(100) }),
    newPassword: z
      .string()
      .min(1, { message: t?.requiredField })
      .min(8, { message: t?.errorMinLength?.(8) })
      .max(100, { message: t?.errorMaxLength?.(100) })
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
        {
          message: t?.errorPasswordValidation
        }
      )
  })

  const form = useForm<ChangeUserPassword>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      currentPassword: '',
      newPassword: ''
    }
  })
  const valueNewPassword = useWatch({
    control: form.control,
    name: 'newPassword'
  })

  const feedbackPassword = useMemo(
    () => ({
      minLength: valueNewPassword.length >= 8,
      oneUpperCase: /^(?=.*[A-Z])/.test(valueNewPassword),
      oneLowerCase: /^(?=.*[a-z])/.test(valueNewPassword),
      oneNumber: /^(?=.*\d)/.test(valueNewPassword),
      oneSpecialCharacter: /^(?=.*[@$!%*?&])/.test(valueNewPassword)
    }),
    [valueNewPassword]
  )

  return (
    <SettingsTemplate
      title={t?.title}
      description={t?.description}
      showSkeleton={!isReady}
    >
      <Form<ChangeUserPassword>
        {...form}
        onSubmit={async (values) => {
          try {
            await mutateAsyncChangeUserPassword({
              currentPassword: values.currentPassword,
              newPassword: values.newPassword
            })
            form.reset()
            showToast.success(t?.toasts?.successUpdate)
          } catch {
            showToast.error(t?.toasts?.errorUpdate)
          }
        }}
      >
        <RenderFormField
          control={form.control}
          name="currentPassword"
          type="password"
          disabled={false}
          showSkeleton={!isReady}
          {...form.formState.errors.currentPassword}
          {...t?.form?.currentPassword}
        />
        <RenderFormField
          control={form.control}
          name="newPassword"
          type="password"
          disabled={false}
          showSkeleton={!isReady}
          {...form.formState.errors.newPassword}
          {...t?.form?.newPassword}
        />
        <PasswordRequirements {...feedbackPassword} />
        <div className="flex justify-end w-full">
          <Button
            className="w-fit"
            type="submit"
            showSkeleton={!isReady}
            loading={isLoadingChangeUserPassword}
          >
            {t?.form?.buttonSubmitSave?.label}
          </Button>
        </div>
      </Form>
    </SettingsTemplate>
  )
}

export default ProfileChangePasswordPage
