import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import useTranslation from '@/hooks/useTranslation'
import { ForgotPasswordInsertEmail, RecoveryPassword } from '@/organisms/index'
import AuthTemplate from '@/templates/AuthTemplate/AuthTemplate'

const ForgotPasswordPage = () => {
  const { query } = useRouter()
  const { t } = useTranslation('seo')

  const isSectionInsertEmail = !query?.email

  return (
    <AuthTemplate>
      {isSectionInsertEmail ? (
        <ForgotPasswordInsertEmail />
      ) : (
        <RecoveryPassword email={query?.email as string} />
      )}
      <NextSeo title={t?.titles?.forgotPassword} />
    </AuthTemplate>
  )
}

export default ForgotPasswordPage
