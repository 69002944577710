import { Dispatch, SetStateAction } from 'react'
import { CustomColumn } from '@/types/react-table-config'
import {
  AuditSignerActionAccountItem,
  AuditsSignerActionAccounts
} from '@/hooks/api/report/useAuditApi/useAuditApi.types'
import { SelectGroupOption, SelectOption } from '@/ui/atoms'
import { DataCardType, DataListType } from '@/ui/molecules'

export type FiltersAuditsType = {
  user?: string | null
}

export enum FiltersKeyAudits {
  USER = 'user'
}

export type UseAuditsDataResponse = {
  pageNumber: number
  setPageNumber: React.Dispatch<React.SetStateAction<number>>
  pageSize: number
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  currentDataTable: {
    data: AuditsSignerActionAccounts | null | undefined
    isLoading: boolean
    isSuccess: boolean
  }
  columns: CustomColumn<AuditSignerActionAccountItem>[]
  optionsUsers: SelectGroupOption[] | SelectOption[]
  dataModal: DataModalType
  setDataModal: Dispatch<SetStateAction<DataModalType>>
  isLoadingAuditDetails: boolean
  cardsDetails: DataCardType[] | null
  listDetails: DataListType[] | null
  filters: FiltersAuditsType | null
  preparedFilters: FiltersAuditsType | null
  setPreparedFilters: (filters: FiltersAuditsType) => void
  handleApplyFilters: (filters: FiltersAuditsType) => void
}

export type DataModalType = {
  isOpen: boolean
  id: string | null
}
