import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useAuth } from '@/providers'

const BillingSuccessPage: React.FC = () => {
  const { query } = useRouter()
  const { refreshProfile } = useAuth()

  useEffect(() => {
    refreshProfile()
  }, [refreshProfile])

  return (
    <div>
      <h1>BillingSuccessPage {query.page}</h1>
    </div>
  )
}

export default BillingSuccessPage
