import React from 'react'
import { useRouter } from 'next/router'
import useTranslation from '@/hooks/useTranslation'
import { Link, Typography } from '@/ui/atoms'
import { Button } from '@/ui/atoms/shadcn'

const UnauthorizedPage: React.FC = () => {
  const { t, isReady } = useTranslation('unauthorized')
  const { push } = useRouter()

  return (
    <div className="flex flex-col items-center justify-center gap-5 h-containerFull">
      <img
        src="/assets/icons/unauthorized.svg"
        alt="Folder Dashboard"
        className="h-20"
      />
      <div className="flex flex-col items-center justify-center gap-1">
        <Typography
          variant="title-lg-medium"
          className="text-center"
          showSkeleton={!isReady}
        >
          {t?.title}
        </Typography>
        <Typography
          variant="text-sm-regular"
          className="text-center"
          showSkeleton={!isReady}
        >
          {t?.description}
        </Typography>
      </div>
      <Link href="/">
        <Button type="button" showSkeleton={!isReady}>
          {t?.button}
        </Button>
      </Link>
    </div>
  )
}

export default UnauthorizedPage
