import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import { useAuth } from '@/providers/Auth'
import {
  IInboxData,
  IInboxSignatureLink
} from '@/hooks/api/ecm/useInboxApi/useInboxApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'

const useInboxApi = () => {
  const { handleApi, queryConfig } = useApi()
  const { authMetadata } = useAuth()

  const useInboxData = () => {
    return useQuery({
      queryKey: [QueryKeys.InboxData],
      queryFn: () => {
        return handleApi<void, IInboxData>('/ecm/inbox', 'GET')
      },
      ...queryConfig
    })
  }

  const useInboxSignatureLink = (id: string, enabled = false) => {
    const [selectedId, setSelectedId] = useState(id)
    const [isEnabled, setIsEnabled] = useState(enabled)

    const { data, error, isLoading, isSuccess, isError } = useQuery({
      queryKey: [QueryKeys.InboxSignatureLink, selectedId],
      queryFn: () =>
        handleApi<void, IInboxSignatureLink>(
          `/ecm/inbox/${selectedId}/signature-link`,
          'GET'
        ),
      ...queryConfig,
      enabled: isEnabled && !!authMetadata?.accessToken
    })

    useEffect(() => {
      if (isSuccess || isError) {
        setIsEnabled(false)
      }
    }, [isSuccess, isError])

    useEffect(() => {
      setSelectedId(id)
      setIsEnabled(enabled)
    }, [id, enabled])

    return {
      data,
      error,
      isLoading,
      isSuccess,
      isError,
      setSelectedId,
      setIsEnabled
    }
  }

  return {
    useInboxData,
    useInboxSignatureLink
  }
}

export default useInboxApi
